/* eslint-disable no-underscore-dangle, @typescript-eslint/no-unused-vars */
import React, { useState, useEffect, Fragment } from 'react';
import { Row, Col } from 'reactstrap';
import { ReactSortable } from 'react-sortablejs';
import { capitalize } from 'lodash';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronRight } from '@fortawesome/free-solid-svg-icons';
import { toast } from 'react-toastify';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import defaultComponentCover from '../../../assets/images/default-component.png';
import prototypeApi from '../../../api/prototypeApi';
import { get as getLsValue } from '../../../helpers/local-storage';
import { getSanitizedS3BucketUrl } from '../../../helpers';
import FloatBtnGroup from '../../../components/common/FloatBtnGroup';
import FloatBtn from '../../../components/common/FloatBtn';
import PageLoading from '../../../components/common/pageLoad';
import MoodboardOptions from '../components/moodboardOptions';
import {
  MOODBOARD_EMAIL_HEADING,
  MOODBOARD_EMAIL_MESSAGE,
} from '../../../constants';

const SweetAlert = withReactContent(Swal);
export interface ItemType {
  id: string;
  wireFrameImage: string;
  wire_frame_image: string;
  designImage: string;
  design_image: string;
  page?: string;
  project?: string;
  isInRealm: boolean;
  componentId: string;
  pageComponentId: string;
}

const EditPageMoodboard = (props: {
  match: {
    params: { prototypeId: string; pageId: string };
  };
  history: any;
  location: {
    prototypeName: any;
    pageName: any;
    state: {
      prototypeName: string;
      pageName: string;
      checkedValue: any;
    };
  };
}) => {
  const {
    match: {
      params: { prototypeId, pageId: prototypePageId },
    },
    location,
    history,
  } = props;

  const [items, setItems] = useState<ItemType[]>([]);
  const [prototypeName, setPrototypeName] = useState<string>('');
  const [pageName, setPageName] = useState<string>('');
  const [responseCount, setResponseCount] = useState<number>(1);

  const getPageComponent = async (prototype: string, pageToGet: string) => {
    try {
      const results = await prototypeApi.getPageComponent(prototype, pageToGet);
      setResponseCount(results.data.data.length);
      if (results.data.data && results.data.data.length > 0) {
        const newPageComponent = results.data.data.map((item: any) => {
          const {
            component_id: componentId,
            wireFrameImage,
            designImage,
            page,
            project,
            is_in_realm: isInRealm,
            page_component_id: pageComponentId,
            project_id: projectId,
          } = item;
          return {
            id: pageComponentId,
            componentId,
            pageComponentId,
            wireFrameImage,
            designImage,
            page,
            project,
            isInRealm,
            projectId,
          };
        });
        setItems(newPageComponent);
      }
    } catch (e) {
      if (e.response && e.response.status) {
        // eslint-disable-next-line no-console
        console.error('component get error', e.response);
      }
    }
  };

  useEffect(() => {
    if (location && location.state) {
      if (Array.isArray(location.state.checkedValue)) {
        setItems(location.state.checkedValue);
      } else {
        getPageComponent(prototypeId, prototypePageId);
      }
      if (location.state.prototypeName) {
        setPrototypeName(location.state.prototypeName);
      }
      if (location && location.state.pageName) {
        setPageName(location.state.pageName);
      }
    }
    if (location && location.prototypeName) {
      setPrototypeName(location.prototypeName);
    }
    if (location && location.pageName) {
      setPageName(location.pageName);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleCancel = () => {
    history.push({
      pathname: `/moodgen/create-page/${prototypeId}/${prototypeName}`,
    });
  };

  const s3BucketUrl = getSanitizedS3BucketUrl();
  const formatImageUrl = (url: string) =>
    url && url[0] === '/' ? url.substring(1, url.length) : url;

  const presentationModeIsDesign = getLsValue(
    'theme-setting-show-designs',
    false,
  );

  const [pageMoodboardIsGenerating, setPageMoodboardIsGenerating] = useState<
    string | null
  >(null);

  const handleMoodboardGenerate = async (pageId: string) => {
    setPageMoodboardIsGenerating(pageId);
    try {
      await prototypeApi.generatePageMoodboard(prototypeId, pageId);
      setPageMoodboardIsGenerating(null);
      SweetAlert.fire({
        title: MOODBOARD_EMAIL_HEADING,
        text: MOODBOARD_EMAIL_MESSAGE,
        icon: 'info',
        showConfirmButton: true,
        showCancelButton: false,
        confirmButtonText: 'Ok',
      });
    } catch (error) {
      if (process.env.NODE_ENV === 'development') {
        // eslint-disable-next-line no-console
        console.error(error);
      }
      toast(error.response.data.message, {
        type: 'error',
        autoClose: 5000,
      });
      setPageMoodboardIsGenerating(null);
    }
  };

  return (
    <div className="ar-common-content">
      <div className="ar-edit-page mw-100">
        <Row xs="1" sm="1" md="1">
          <Col className="sticky-top">
            <div className="ar-common-breadcrumb">
              <Link
                to={{
                  pathname: '/moodgen',
                }}
              >
                <button type="button" className="ar-common-breadcrumb__link">
                  Project
                </button>
              </Link>
              <FontAwesomeIcon icon={faChevronRight} />
              <Link
                to={{
                  pathname: `/moodgen/create-page/${prototypeId}/${location.state.prototypeName}`,
                }}
              >
                <button type="button" className="ar-common-breadcrumb__link">
                  View Pages({location.state.prototypeName})
                </button>
              </Link>
              <FontAwesomeIcon icon={faChevronRight} />
              <span className="ar-common-breadcrumb__page">
                Moodgen Builder
              </span>
            </div>
          </Col>
          <Col>
            <div className="ar-common-title">
              {' '}
              Moodgen Builder : {capitalize(pageName)}
              {pageMoodboardIsGenerating ? (
                <PageLoading loadingText="Generating moodboard. Please wait..." />
              ) : null}
            </div>
            <div>
              <FloatBtnGroup>
                {/* <FloatBtn
                  type="submit"
                  variant="update"
                  onClickHandler={handleSave}
                  title="Save"
                /> */}
                <FloatBtn
                  variant="cancel"
                  type="button"
                  onClickHandler={handleCancel}
                  title="Back"
                />
                <FloatBtn
                  variant="figma"
                  type="button"
                  title="Generate Figma"
                  onClickHandler={() =>
                    handleMoodboardGenerate(prototypePageId)
                  }
                  disabled={pageMoodboardIsGenerating}
                />
              </FloatBtnGroup>
              <div className="ar-edit-page__body border-0">
                {responseCount === 0 && (
                  <div className="pt-4">
                    <div className="ar-edit-page__wrapper ar-edit-page__options">
                      <p className="h2 w-100">
                        <strong>Your Page Is Currently Empty!</strong>
                      </p>
                      <p className="mt-2">
                        To edit the page with moodgen builder, first add
                        sections to your page in prototype.
                      </p>
                    </div>
                  </div>
                )}
                <ReactSortable list={items} setList={setItems}>
                  {items.map((item) => (
                    <Fragment key={item.pageComponentId}>
                      <div
                        className="ar-edit-page__wrapper ar-edit-page__options"
                        key={item.pageComponentId}
                      >
                        {item.isInRealm ? (
                          <span className="badge badge-pill badge-success realm-badge">
                            Realm
                          </span>
                        ) : null}
                        <div className="ar-edit-page__image">
                          <div className="ar-edit-page__details">
                            <div className="ar-edit-page__project">
                              <span>{item.project}</span>
                              <span>{item.page}</span>
                            </div>
                            {/* <div className="ar-edit-page__actions text-right">
                              <button
                                type="button"
                                className="btn btn-dark"
                                title="Configure moodboard"
                                onClick={() => handleShow(item.pageComponentId)}
                              >
                                <FontAwesomeIcon icon={faFigma} />
                              </button>
                            </div> */}
                          </div>
                          {(presentationModeIsDesign &&
                            (item.designImage || item.design_image)) ||
                          (!presentationModeIsDesign &&
                            (item.wireFrameImage || item.wire_frame_image)) ? (
                            <img
                              src={`${s3BucketUrl}/${
                                presentationModeIsDesign
                                  ? formatImageUrl(item.designImage) ||
                                    formatImageUrl(item.design_image)
                                  : formatImageUrl(item.wireFrameImage) ||
                                    formatImageUrl(item.wire_frame_image)
                              }`}
                              alt="default component"
                              className="cover"
                            />
                          ) : (
                            <img
                              src={defaultComponentCover}
                              alt="default component"
                              className="cover"
                            />
                          )}
                        </div>
                        <MoodboardOptions
                          key={item.pageComponentId}
                          item={item}
                          prototypeId={prototypeId}
                          formatImageUrl={formatImageUrl}
                          presentationModeIsDesign={presentationModeIsDesign}
                          s3BucketUrl={s3BucketUrl}
                          defaultComponentCover={defaultComponentCover}
                        />
                      </div>

                      {/* Moodboard references modal */}
                      {/* {selectedItem === item.pageComponentId && (
                        <div>
                          <MoodboardPopupConfig
                            key={item.pageComponentId}
                            item={item}
                            prototypeId={prototypeId}
                            setPopupClosed={setPopupClosed}
                            setSelectedItem={setSelectedItem}
                            formatImageUrl={formatImageUrl}
                            presentationModeIsDesign={presentationModeIsDesign}
                            s3BucketUrl={s3BucketUrl}
                            defaultComponentCover={defaultComponentCover}
                          />
                        </div>
                      )} */}
                    </Fragment>
                  ))}
                </ReactSortable>
              </div>
            </div>
            {/* <div
              className="ar-edit-page__add-section"
              role="link"
              tabIndex={0}
              onKeyDown={() => handleAdd()}
              onClick={() => handleAdd()}
            >
              {' '}
              Add Sections
            </div> */}
          </Col>
        </Row>
      </div>
    </div>
  );
};
export default EditPageMoodboard;
