import React, { useState, useMemo } from 'react';
import {
  ButtonDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from 'reactstrap';

const DropDownButton: React.FC<any> = (props) => {
  const { label, selectedOption, options, disabled = false, children } = props;
  const [dropdownOpen, setOpen] = useState(false);
  const toggle = () => setOpen(!dropdownOpen);

  const dropDownOptions = useMemo(() => {
    const optList = Object.keys(options).map((option, key) => {
      const itemName = options[option];
      return (
        // eslint-disable-next-line react/no-array-index-key
        <DropdownItem onClick={() => selectedOption(itemName)} key={key}>
          {itemName}
        </DropdownItem>
      );
    });

    return optList;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="btn-group">
      <ButtonDropdown isOpen={dropdownOpen} toggle={toggle} disabled={disabled}>
        <DropdownToggle outline color="info" size="sm" tabIndex={0} caret>
          {children || label}
        </DropdownToggle>
        <DropdownMenu>{dropDownOptions}</DropdownMenu>
      </ButtonDropdown>
    </div>
  );
};

export default DropDownButton;
