import React from 'react';
import { Button } from 'reactstrap';

const FloatBtn = (props: any) => {
  const {
    type,
    variant,
    onClickHandler,
    title,
    disabled = null,
    name = null,
    id = null,
  } = props;

  let classVariant = '';
  let colorVariant = '';

  switch (variant) {
    case 'update':
      classVariant = 'btn-update';
      colorVariant = 'ar-btn ar-btn--primary';
      break;
    case 'cancel':
      classVariant = 'btn-cancel';
      colorVariant = 'ar-btn ar-btn--light';
      break;
    case 'figma':
      classVariant = 'btn-figma';
      colorVariant = 'ar-btn ar-btn--primary';
      break;
    case 'back':
      classVariant = 'btn-back';
      colorVariant = 'ar-btn ar-btn--light';
      break;
    case 'add':
      classVariant = 'btn-add';
      colorVariant = 'ar-btn ar-btn--primary';
      break;
    case 'create':
      classVariant = 'btn-create';
      colorVariant = 'ar-btn ar-btn--primary ml-3';
      break;
    default:
      classVariant = '';
  }

  return (
    <Button
      id={id}
      type={type}
      className={`ar-common-float-button__btn ${classVariant} ${colorVariant}`}
      onClick={onClickHandler}
      title={title}
      disabled={disabled}
      name={name}
    >
      {' '}
      {title}{' '}
    </Button>
  );
};

export default FloatBtn;
