import React from 'react';
import { ErrorMessage } from 'formik';
import TextError from '../common/TextError';

const File: React.FC<any> = (props) => {
  const { label, name, handleUploadFile, ...rest } = props;

  return (
    <div className="form-group">
      <label htmlFor={name}>{label}</label>
      <input
        id={name}
        name={name}
        type="file"
        onChange={handleUploadFile}
        className="form-control"
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...rest}
      />
      <ErrorMessage name={name} component={TextError} />
    </div>
  );
};

export default File;
