/* eslint-disable camelcase */
import axiosApi from './axiosApi';

const getMoodboardOptionsByPageComponent = (id: string): Promise<any> => {
  return axiosApi.get(`page-component-moodboards/${id}`);
};

const savePageComponentMoodboardOptions = async (params: {}) => {
  return axiosApi.post('page-component-moodboards', { ...params });
};

export default {
  getMoodboardOptionsByPageComponent,
  savePageComponentMoodboardOptions,
};
