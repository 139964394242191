import React, { useState } from 'react';
import { FileUploader } from 'react-drag-drop-files';
import { Button } from 'reactstrap';

const DragAndDropField: React.FC<any> = (props) => {
  const {
    label,
    name,
    fileType,
    handleUploadFile,
    validFileTypes,
    currentFileName = '',
    uploading = false,
  } = props;
  const [currentUpload, setCurrentUpload] = useState(false);
  const [isEmpty, setIsEmpty] = useState(false);
  const [isUploaded, setIsUploaded] = useState(false);
  const [file, setFile] = useState<{
    name: string;
  }>({
    name: '',
  });

  const handleChangeFileUpload = (uploadedFile: { name: string }) => {
    setIsUploaded(false);
    setFile(uploadedFile);
  };

  const handleButton = async () => {
    if (file.name) {
      setCurrentUpload(true);
      await handleUploadFile(file, fileType);
      setCurrentUpload(false);
      setIsUploaded(true);
    } else {
      setIsEmpty(true);
    }
  };

  const onSelect = () => {
    setIsUploaded(false);
    setIsEmpty(false);
  };

  const onDrop = () => {
    setIsUploaded(false);
    setIsEmpty(false);
  };

  let fieldLabel = label;
  if (uploading && currentUpload) {
    fieldLabel = `${label} is uploading...`;
  }
  if (file.name !== '' && !uploading && !isUploaded) {
    fieldLabel = `${label} is ready to upload. Click "Upload" to proceed`;
  }

  return (
    <div className="form-group">
      <label htmlFor={name}>{fieldLabel}</label>
      <FileUploader
        handleChange={handleChangeFileUpload}
        name="file"
        types={validFileTypes}
        disabled={uploading && currentUpload}
        onSelect={onSelect}
        onDrop={onDrop}
      />
      {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
      <label className="form-group__hint">
        {file.name
          ? `${file.name}`
          : currentFileName || 'no files uploaded yet'}
      </label>
      {isEmpty && <p className="error">Please select or drop a file first</p>}
      {file.name !== '' && !isUploaded && (
        <Button
          className="btn-sm float-right mr-3"
          disabled={uploading && currentUpload}
          onClick={handleButton}
        >
          Upload
        </Button>
      )}
    </div>
  );
};

export default DragAndDropField;
