import axiosApi from './axiosApi';
import baseAPI from './baseAPI';

const auth = async (params: {}) => {
  return baseAPI('post', 'auth/google', { ...params });
};

const authCheck = async () => {
  return axiosApi.get('auth-check');
};

const authApi = {
  auth,
  authCheck,
};

export default authApi;
