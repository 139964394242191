/* eslint-disable react/jsx-no-comment-textnodes */
import React, { useState } from 'react';
import { Modal, ModalHeader, ModalBody } from 'reactstrap';
import Plyr from 'plyr-react';
import { getSanitizedS3BucketUrl } from '../../helpers';
import defaultComponentCover from '../../assets/images/default-component.png';

const VideoPopup: React.FC<any> = (props: {
  videoPath: string;
  posterPath: string;
}) => {
  const { videoPath, posterPath } = props;
  const [isOpen, setIsOpen] = useState(false);

  const toggle = () => setIsOpen(!isOpen);

  const s3BucketUrl = getSanitizedS3BucketUrl();
  const formatImageUrl = (url: string) =>
    url && url[0] === '/' ? url.substring(1, url.length) : url;

  const posterUrl = `${s3BucketUrl}/${formatImageUrl(posterPath)}`;
  const videoUrl = `${s3BucketUrl}/${formatImageUrl(videoPath)}`;

  return (
    <div className="image-container">
      {posterUrl ? (
        <>
          <img
            src={posterUrl}
            alt="resource video"
            className="cover"
            onClick={toggle}
          />
          <span className="play-button" onClick={toggle} />
        </>
      ) : (
        <img
          src={defaultComponentCover}
          alt="default component"
          className="cover"
        />
      )}
      <Modal isOpen={isOpen} toggle={toggle} size="lg">
        <ModalHeader toggle={toggle} />
        <ModalBody>
          <Plyr
            source={{
              type: 'video',
              sources: [
                {
                  src: videoUrl,
                  type: 'video/webm',
                  size: 480,
                },
              ],
              poster: posterUrl,
            }}
            options={{
              controls: [
                'play-large',
                'play',
                'progress',
                'current-time',
                'pip',
                'airplay',
                'fullscreen',
              ],
              autoplay: true,
              invertTime: false,
            }}
          />
        </ModalBody>
      </Modal>
    </div>
  );
};

export default VideoPopup;
