/* eslint-disable camelcase */
import axiosApi from './axiosApi';

const requestPrototypes = async (params: {}) => {
  return axiosApi.post('prototypes/list', { ...params });
};

const requestAllPrototypesList = async () => {
  return axiosApi.get('prototypes/list-all');
};

const save = async (params: {}) => {
  return axiosApi.post('prototypes/create', { ...params });
};

const requestPages = async (params: { prototypeId: string }) => {
  return axiosApi.get(`prototype/pages/${params.prototypeId}`);
};
const removePage = async (params: {
  prototype_id: string;
  page_id: string;
}) => {
  return axiosApi.delete('prototype/pages/delete', { data: { ...params } });
};

const savePage = async (params: {}) => {
  return axiosApi.post('prototype/pages/create', { ...params });
};

const removePrototype = async (params: { prototypeId: string }) => {
  return axiosApi.delete('prototypes/delete', { data: { ...params } });
};

const getComponents = async (params: {}) => {
  return axiosApi.post('prototypes/search/components', { ...params });
};

const savePageComponent = async (params: {}) => {
  return axiosApi.post('prototypes/save/page/components', { ...params });
};

const getPageComponent = async (prototypeId: string, pageId: string) => {
  return axiosApi.get(`prototypes/${prototypeId}/pages/${pageId}/components`);
};

const removeComponent = async (params: { componentId: string }) => {
  return axiosApi.delete('components/delete', { data: { ...params } });
};

const generateResources = async (id: string, type: string) => {
  const profile = localStorage.getItem('profile');
  const userEmail = profile ? JSON.parse(profile).email : '';
  const response = axiosApi.post(
    `prototypes/generate/resources/${id}/${type}`,
    { email: userEmail },
  );
  return response;
  // return axiosApi.post(`prototypes/generate/resources/${id}/${type}`, { email: userEmail});
};

const upload = async (params: {} | undefined, fileType: string) => {
  return axiosApi.post(`prototypes/upload?fileType=${fileType}`, params);
};

const requestPrototypeById = (id: string): Promise<any> => {
  return axiosApi.get(`prototypes/${id}`);
};

const generatePageMoodboard = (prototypeId: string, pageId: string) => {
  return axiosApi.post(`prototype/pages/${pageId}/generate-moodboard`, {
    prototypeId,
  });
};

const prototypeApi = {
  removePrototype,
  requestPrototypes,
  requestAllPrototypesList,
  requestPages,
  save,
  removePage,
  savePage,
  getComponents,
  savePageComponent,
  getPageComponent,
  generateResources,
  removeComponent,
  upload,
  requestPrototypeById,
  generatePageMoodboard,
};

export default prototypeApi;
