import React from 'react';
import { Field, ErrorMessage } from 'formik';
import TextError from '../common/TextError';

const Select: React.FC<any> = (props) => {
  const { label, name, options, ...rest } = props;

  return (
    <div className="form-group">
      <label htmlFor={name}>{label}</label>
      <Field
        as="select"
        className="form-control formik-select"
        name={name}
        id={name}
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...rest}
      >
        {options.map(
          (option: {
            _id: string | number | string[];
            name: string;
            disabled: boolean;
            selected: boolean;
          }) => {
            const { _id: id, name: optionName, disabled = false } = option;
            return (
              <option
                key={Math.random()}
                value={id}
                className="option"
                disabled={disabled}
              >
                {optionName}
              </option>
            );
          },
        )}
      </Field>
      <ErrorMessage name={name} component={TextError} />
    </div>
  );
};

export default Select;
