import React from 'react';
import { Field, ErrorMessage } from 'formik';
import TextError from '../common/TextError';

const CheckBox: React.FC<any> = (props) => {
  const { label, name, type, ...rest } = props;

  return (
    <div className="form-group form-check">
      <Field
        className="form-check-input"
        name={name}
        id={name}
        type={type}
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...rest}
      />
      <label htmlFor={name}>{label}</label>
      <ErrorMessage name={name} component={TextError} />
    </div>
  );
};

export default CheckBox;
