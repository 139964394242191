/* eslint-disable no-underscore-dangle */
import React, { useState, useEffect, useCallback, useRef } from 'react';
import {
  Row,
  Col,
  Input,
  Label,
  Nav,
  NavItem,
  NavLink,
  FormGroup,
} from 'reactstrap';
import Select from 'react-select';
import { throttle, DebouncedFunc, capitalize } from 'lodash';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronRight } from '@fortawesome/free-solid-svg-icons';
import projectApi from '../../../api/projectApi';
import pageApi from '../../../api/pageApi';
import defaultComponentCover from '../../../assets/images/default-component.png';
import { PAGE_LIMIT } from '../../../constants';
import Pagination from '../../../components/common/Pagination';
import FloatBtnGroup from '../../../components/common/FloatBtnGroup';
import FloatBtn from '../../../components/common/FloatBtn';

const SearchPages = (props: {
  match: {
    params: { prototypeId: string; pageId: string };
  };
  history: any;
  location: any;
}) => {
  let pageList: any = null;
  let totalCount = 1;
  const {
    match: {
      params: { prototypeId, pageId: prototypePageId },
    },
    location,
    history,
  } = props;

  let projectOptions: any = [{ value: 'all', label: 'All' }];
  const getPagesThrottled = useRef<DebouncedFunc<any> | null>(null);
  const [requestDataComplete, setRequestDataComplete] = useState(false);
  const [data, setData] = useState({ pages: [{}], count: 1 });
  const [requestProjectListsComplete, setRequestProjectListsComplete] =
    useState(false);
  const [projectLists, setProjectLists] = useState([]);
  const [params, setParams] = useState<{
    project: string | number | null;
    search: string | null;
    page: number | 0;
    limit: number | 0;
  }>({
    project: null,
    search: null,
    page: 1,
    limit: PAGE_LIMIT,
  });

  const [state, setState] = useState<{
    pageId?: string;
    prototypeId: string;
    projectId?: string;
    projectName?: string;
    checkedValue: {
      id: string;
      _id: string;
      url: string;
      page: string;
      project: string;
    }[];
    prototypeName: string;
    pageName?: string;
    prototypePageId?: string;
    prototypePageName?: string;
  }>({
    pageId: '',
    prototypeId,
    projectId: '',
    projectName: '',
    checkedValue: [{ id: '', _id: '', url: '', page: '', project: '' }],
    prototypeName: '',
    pageName: '',
    prototypePageId,
    prototypePageName: '',
  });

  const getPages = (args: any) => {
    if (getPagesThrottled.current) getPagesThrottled.current.cancel();
    getPagesThrottled.current = throttle(
      async (filterValues: any) => {
        const { project, search, page, limit } = filterValues;
        const submitData = {
          project: project === 'all' ? null : project,
          search,
          page,
          limit,
        };

        try {
          setRequestDataComplete(false);
          const results = await pageApi.getPages(submitData);
          setData(results.data);
          setRequestDataComplete(true);
        } catch (e) {
          if (e.response && e.response.status) {
            setRequestDataComplete(false);
          }
        }
      },
      600,
      { leading: false, trailing: true },
    );
    getPagesThrottled.current(args);
  };

  const getProjectList = async () => {
    try {
      setRequestProjectListsComplete(false);
      const results = await projectApi.requestProjectLists();
      setProjectLists(results.data.projectData);
      setRequestProjectListsComplete(true);
    } catch (e) {
      if (e.response && e.response.status) {
        setRequestProjectListsComplete(false);
      }
    }
  };

  useEffect(() => {
    getPages(params);
    getProjectList();
    if (location && location.state) {
      setState(location.state);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    params.page = 1;
    getPages(params);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params.project, params.search]);

  const handleOpenPage = (
    pageId: string,
    projectId: string,
    pageName: string,
    projectName: string,
  ) => {
    history.push({
      pathname: `/prototype/component/search/${prototypeId}/${pageId}`,
      state: {
        pageId,
        prototypeId,
        projectId,
        projectName,
        checkedValue: state.checkedValue,
        prototypeName: state.prototypeName,
        pageName,
        prototypePageId,
        prototypePageName: state.prototypePageName,
      },
    });
  };

  if (requestDataComplete && data.pages && data.pages.length > 0) {
    totalCount = data.count;
    pageList = data.pages.map((item: any) => {
      const {
        project,
        page,
        page_id: pageId,
        project_id: projectId,
      } = item._id;

      return (
        <div
          key={Math.random()}
          className="ar-common-post page"
          onClick={() => handleOpenPage(pageId, projectId, page, project)}
        >
          <div className="ar-common-post__checkbox-wrapper" />
          <div className="ar-common-post__image image-container">
            <img
              src={defaultComponentCover}
              alt="default component"
              className="cover"
            />
          </div>
          <div className="ar-common-post__container">
            <div className="ar-common-post__title">
              Project: <span>{project}</span>
            </div>
            <div className="ar-common-post__cat">
              Page: <span>{page}</span>
            </div>
          </div>
        </div>
      );
    });
  } else if (
    requestDataComplete &&
    data.pages &&
    data.pages.length === 0 &&
    params.search
  ) {
    pageList = (
      <span className="mx-auto py-3">
        Sorry, Your filter produced no results!
      </span>
    );
  }

  if (requestProjectListsComplete && projectLists && projectLists.length > 0) {
    projectOptions = [
      ...projectOptions,
      ...projectLists.map(
        ({ _id: id, name }: { _id: string; name: string }) => ({
          value: id,
          label: name,
        }),
      ),
    ];
  }
  const selectedProjectOption = projectOptions.find(
    ({ value }: { value: string }) => value === (params.project || 'all'),
  );

  const pageCount = totalCount / params.limit;

  const onPageChange = useCallback(
    ({ selected }) => {
      const newParams = { ...params, page: selected + 1 };

      setParams(newParams);
      getPages(newParams);
    },
    [params],
  );

  const onPageSelect = useCallback(
    ({ target }) => {
      const newParams = { ...params, page: 1, limit: target.value };
      setParams(newParams);
      getPages(newParams);
    },
    [params],
  );

  const handleSave = () => {
    const {
      prototypeName,
      checkedValue,
      prototypePageId: idOfPrototypePage,
      prototypePageName,
    } = state;
    history.push({
      pathname: `/prototype/edit-page/${prototypeId}/${idOfPrototypePage}`,
      state: {
        checkedValue,
        prototypeName,
        idOfPrototypePage,
        pageName: location.state.prototypePageName,
        prototypePageName,
      },
    });
  };

  const handleComponentLoad = () => {
    history.push({
      pathname: `/prototype/page/search/component/${prototypeId}/${prototypePageId}`,
      state: {
        prototypeId,
        checkedValue: state.checkedValue,
        prototypeName: state.prototypeName,
        prototypePageId,
        prototypePageName: state.prototypePageName,
      },
    });
  };

  return location.state ? (
    <div className="ar-common-content">
      <div className="ar-common-search-panel-wrapper">
        <Row xs="1" sm="1" md="1">
          <Col>
            <div className="ar-common-breadcrumb">
              <Link
                to={{
                  pathname: '/prototypes',
                }}
              >
                <button type="button" className="ar-common-breadcrumb__link">
                  Prototype
                </button>
              </Link>
              <FontAwesomeIcon icon={faChevronRight} />
              <Link
                to={{
                  pathname: `/prototype/create-page/${prototypeId}/${location.state.prototypeName}`,
                }}
              >
                <button type="button" className="ar-common-breadcrumb__link">
                  Edit pages({location.state.prototypeName})
                </button>
              </Link>
              <FontAwesomeIcon icon={faChevronRight} />
              <Link
                to={{
                  pathname: `/prototype/edit-page/${prototypeId}/${location.state.prototypePageId}`,
                  state: {
                    prototypeName: location.state.prototypeName,
                    pageName: location.state.prototypePageName,
                    checkedValue: location.state.checkedValue,
                  },
                }}
              >
                <button type="button" className="ar-common-breadcrumb__link">
                  Edit page({capitalize(location.state.prototypePageName)})
                </button>
              </Link>
              <FontAwesomeIcon icon={faChevronRight} />
              <span className="ar-common-breadcrumb__page">Select page</span>
            </div>
          </Col>
        </Row>
        <Row>
          <Nav tabs className="ar-common-tab">
            <NavItem className="ar-common-tab__item">
              <NavLink
                href="#"
                role="button"
                active
                className="ar-common-tab__link"
              >
                Select Page
              </NavLink>
            </NavItem>
            <NavItem className="ar-common-tab__item">
              <NavLink
                className="ar-common-tab__link"
                href="#"
                role="button"
                onClick={() => handleComponentLoad()}
              >
                Select Component
              </NavLink>
            </NavItem>
          </Nav>
        </Row>
        <Row>
          <Col lg="8">
            <div className="form ar-common-form ar-common-search-panel ar-common-search-panel--sticky">
              <div className="ar-common-form__group">
                <FormGroup className="ar-common-form-search">
                  <Input
                    type="text"
                    className="search form-control"
                    id="search"
                    placeholder="Search by page "
                    onChange={(e: React.ChangeEvent<HTMLInputElement>): void =>
                      setParams({ ...params, page: 1, search: e.target.value })
                    }
                  />
                </FormGroup>
              </div>
              <div className="ar-common-form__group">
                <FormGroup>
                  <Label>Projects</Label>
                  <Select
                    className="w-100"
                    classNamePrefix="rctslct"
                    id="project"
                    options={projectOptions}
                    value={selectedProjectOption}
                    onChange={({ value: project }: { value: string }) => {
                      setParams({
                        ...params,
                        page: 1,
                        project,
                      });
                    }}
                    isLoading={!requestProjectListsComplete}
                    isDisabled={!requestProjectListsComplete}
                  />
                </FormGroup>
              </div>
            </div>
          </Col>
        </Row>
      </div>
      <Row>
        <Col>
          <FloatBtnGroup>
            <FloatBtn
              id="save-btn"
              type="button"
              name="save"
              variant="back"
              onClickHandler={handleSave}
              title="Back"
            />
          </FloatBtnGroup>
        </Col>
      </Row>
      <Row>
        <Col className="px-5">
          <div
            className={`ar-common-posts__list ${
              data.pages.length === 0 ? 'd-block' : ''
            }`}
          >
            {pageList}
          </div>
        </Col>
      </Row>
      <Row>
        <Col className="main-title">
          {data.pages.length > 0 && (
            <Pagination
              count={totalCount}
              pageCount={pageCount}
              onPageChange={onPageChange}
              onPageSelect={onPageSelect}
              currentPage={params.page}
              pageLimit={params.limit}
            />
          )}
        </Col>
      </Row>
    </div>
  ) : (
    'Sorry, Required data not found in the page'
  );
};
export default SearchPages;
