/* eslint-disable @typescript-eslint/no-explicit-any, @typescript-eslint/no-unused-vars */
import React, { useCallback, useEffect, useState } from 'react';
import {
  Row,
  Col,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Card,
  CardText,
  CardBody,
  CardTitle,
  Container,
  Label,
  Input,
} from 'reactstrap';

import CreatableSelect from 'react-select/creatable';
import Select from 'react-select';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import { toast } from 'react-toastify';
import { throttle, DebouncedFunc } from 'lodash';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faEye,
  faEdit,
  faRemove,
  faCheck,
  faPlus,
} from '@fortawesome/free-solid-svg-icons';

import moodboardOptionKeywordApi from '../../../api/moodboardOptionKeyword';
import pageComponentMoodboardOptionApi from '../../../api/pageComponentMoodboardOptionApi';
import moodboardReferencesApi from '../../../api/moodboardReferencesApi';
import moodboardComponentTypeApi from '../../../api/moodboardComponentTypeApi';
import moodboardTagApi from '../../../api/moodboardTagApi';
import prototypeApi from '../../../api/prototypeApi';
import ImagePopup from '../../../components/common/imagePopup';
import Pagination from '../../../components/common/Pagination';

import {
  MAX_NUMBER_OF_OPTIONS,
  MAX_NUMBER_OF_SCREENS_PER_OPTION,
} from '../../../constants';

export interface ItemType {
  _id: string;
  image_path: string;
}

const MoodboardPopup = (props: any) => {
  const {
    item,
    setSelectedItem,
    prototypeId,
    formatImageUrl,
    presentationModeIsDesign,
    s3BucketUrl,
    defaultComponentCover,
  } = props;

  const getEmptyOptionObj = (orderId = 1) => ({
    // eslint-disable-next-line @typescript-eslint/camelcase, no-underscore-dangle
    page_component_id: item.pageComponentId,
    // eslint-disable-next-line @typescript-eslint/camelcase, no-underscore-dangle
    moodboard_reference_ids: [],
    // eslint-disable-next-line @typescript-eslint/camelcase, no-underscore-dangle
    moodboard_reference_url_status: [],
    // eslint-disable-next-line @typescript-eslint/camelcase, no-underscore-dangle
    moodboard_option_keyword_ids: [],
    // eslint-disable-next-line @typescript-eslint/camelcase, no-underscore-dangle
    order_id: orderId,
    keywords: [],
    references: [],
    components: [],
  });

  // Moodboard keywords
  const [moodboardKeywords, setMoodboardKeywords] = useState([]);

  // Asigning moodboard keywords as dropdown values
  const options = moodboardKeywords.map((keyword: any) => {
    const { _id: id, name } = keyword;
    return { value: id, label: name };
  });

  // All moodboard options available for the current page component ( Max: 2 allowed)
  const [moodboardOptions, setMoodboardOptions] = useState([
    getEmptyOptionObj(),
  ]);

  // If first or second option is selected
  const [selectedOptionIndex, setSelectedOptionIndex] = useState('');

  // Currently selected moodboard reference index for a particular option
  const [selectedMoodboardReferenceIndex, setSelectedMoodboardReferenceIndex] =
    useState('');

  // Currently selected moodboard reference screen for a particular option
  const [selectedMoodboardReference, setSelectedMoodboardReference] =
    useState<any>([]);

  // Moodboard references list available for the current prototype
  const [moodboardReferencesList, setMoodboardReferencesList] = useState([]);

  // To show/hide inner window of Moodboard gallery
  const [showMoodboardGallery, setShowMoodboardGallery] = useState(false);

  // To open/hide Image popup component
  const [showImagePopup, setShowImagePopup] = useState<any[] | null>(null);

  // To display preloader icon when the request is in progress
  const [dataRequestIsInProgress, setDataRequestIsInProgress] = useState(true);

  const openMoodboardGallery = (
    optionIndex: any,
    referenceScreenIndex: any,
    referenceScreen: any,
  ) => {
    setSelectedOptionIndex(optionIndex);
    setSelectedMoodboardReferenceIndex(referenceScreenIndex);
    setSelectedMoodboardReference(referenceScreen);
    setDataRequestIsInProgress(true);
    setShowMoodboardGallery(true);
  };

  const closeMoodboardGallery = () => {
    setSelectedMoodboardReference([]);
    setSelectedMoodboardReferenceIndex('');
    setDataRequestIsInProgress(false);
    setShowMoodboardGallery(false);
  };

  const getMoodboardOptionKeywords = async () => {
    try {
      const results =
        await moodboardOptionKeywordApi.getMoodboardOptionKeywords();
      if (results.data.keywordData && results.data.keywordData.length > 0) {
        setMoodboardKeywords(results.data.keywordData);
      }
    } catch (e) {
      if (e.response && e.response.status) {
        // eslint-disable-next-line no-console
        console.error('component get error', e.response);
      }
    }
  };

  const openImagePopup = (moodboardOptionIndex: any, referenceIndex: any) => {
    setShowImagePopup([moodboardOptionIndex, referenceIndex]);
  };

  const handleClose = () => setSelectedItem('');

  let filterThrottle: DebouncedFunc<any>;

  const getMoodboardOptionsByPageComponent = async (id: string) => {
    try {
      const results =
        await pageComponentMoodboardOptionApi.getMoodboardOptionsByPageComponent(
          id,
        );
      if (results.data.data && results.data.data.length > 0) {
        setMoodboardOptions(results.data.data);
      }
    } catch (e) {
      if (e.response && e.response.status) {
        // eslint-disable-next-line no-console
        console.error('component get error', e.response);
      }
    }
  };

  const updateKeywords = async (id: number, keywords: any) => {
    const updatedMoodboardOptions: any = [...moodboardOptions];

    if (keywords) {
      const updatedKeywords: any = keywords.map(
        ({ value: _id, label: name }: any) => ({ _id, name }),
      );
      // eslint-disable-next-line @typescript-eslint/camelcase,
      updatedMoodboardOptions[id].moodboard_option_keyword_ids = // eslint-disable-next-line no-underscore-dangle
        updatedKeywords.map((keyword: any) => keyword._id);
      updatedMoodboardOptions[id].keywords = updatedKeywords;
      setMoodboardOptions(updatedMoodboardOptions);
    } else {
      // eslint-disable-next-line @typescript-eslint/camelcase,
      updatedMoodboardOptions[id].moodboard_option_keyword_ids = [];
      updatedMoodboardOptions[id].keywords = [];
      setMoodboardOptions(updatedMoodboardOptions);
    }
  };

  const updateSelectedReferenceScreens = async (
    moodboardOptionIndex: any,
    referenceScreenIndex: any,
    selectedReferenceScreen: any,
  ) => {
    const updatedMoodboardOptions: any = [...moodboardOptions];
    updatedMoodboardOptions[moodboardOptionIndex].moodboard_reference_ids[
      referenceScreenIndex // eslint-disable-next-line no-underscore-dangle
    ] = selectedReferenceScreen._id;
    updatedMoodboardOptions[moodboardOptionIndex].references[
      referenceScreenIndex
    ] = selectedReferenceScreen;

    const referenceUrlStatus =
      updatedMoodboardOptions[moodboardOptionIndex]
        .moodboard_reference_url_status; // eslint-disable-next-line @typescript-eslint/camelcase, no-underscore-dangle
    referenceUrlStatus[referenceScreenIndex] = {
      // eslint-disable-next-line @typescript-eslint/camelcase, no-underscore-dangle
      moodboard_reference_id: selectedReferenceScreen._id,
      showURL: false,
    };

    updatedMoodboardOptions[moodboardOptionIndex] = {
      ...updatedMoodboardOptions[moodboardOptionIndex], // eslint-disable-next-line @typescript-eslint/camelcase
      moodboard_reference_url_status: referenceUrlStatus,
    };
    setMoodboardOptions(updatedMoodboardOptions);
    closeMoodboardGallery();
  };

  const removeReferenceScreen = async (
    moodboardOptionIndex: any,
    referenceScreenIndex: any,
  ) => {
    const updatedMoodboardOptions: any = [...moodboardOptions];
    updatedMoodboardOptions[
      moodboardOptionIndex
    ].moodboard_reference_ids.splice(referenceScreenIndex, 1);
    updatedMoodboardOptions[moodboardOptionIndex].references.splice(
      referenceScreenIndex,
      1,
    );
    updatedMoodboardOptions[
      moodboardOptionIndex
    ].moodboard_reference_url_status.splice(referenceScreenIndex, 1);

    setMoodboardOptions(updatedMoodboardOptions);
  };

  const addNewOption = async () => {
    const updatedMoodboardOptions: any = [...moodboardOptions];
    const optionObj = getEmptyOptionObj(moodboardOptions.length + 1);
    updatedMoodboardOptions.push(optionObj);
    setMoodboardOptions(updatedMoodboardOptions);
  };

  const SweetAlert = withReactContent(Swal);

  const handleRemoveOption = (index: number) => {
    SweetAlert.fire({
      title: 'Confirm Action',
      text: `Are you sure you want to remove option ${
        index + 1
      } from this section?`,
      icon: 'question',
      showConfirmButton: true,
      showCancelButton: true,
      confirmButtonText: 'Yes, remove it!',
    }).then((result) => {
      if (result.isConfirmed) {
        let list: any = [...moodboardOptions];
        list = list.filter((i: any, x: any) => {
          return x !== index;
        });
        setMoodboardOptions(list);
      }
    });
  };

  const handleSaveOption = async () => {
    const response =
      await pageComponentMoodboardOptionApi.savePageComponentMoodboardOptions({
        options: moodboardOptions,
        pageComponentId: item.pageComponentId,
      });

    toast('Your changes have been saved successfully', {
      type: 'success',
      autoClose: 3,
    });

    if (response.data.status) {
      handleClose();
    }
  };

  // Moodboard Gallery
  const [totalNumberOfScreens, setTotalNumberOfScreens] = useState(0);

  const [dataQuery, setDataQuery] = useState<{
    limit: number;
    page: number;
    componentTypeId: string | null;
    prototypeId: string | null;
    userId: string | null;
    type: string | null;
    tagId: string[] | null;
    searchKey: string | null;
  }>({
    limit: 24,
    page: 1,
    componentTypeId: null,
    prototypeId: null,
    userId: null,
    type: null,
    tagId: [],
    searchKey: null,
  });

  const getMoodboardReferenceScreens = useCallback(async () => {
    if (filterThrottle) filterThrottle.cancel(); // eslint-disable-next-line react-hooks/exhaustive-deps
    filterThrottle = throttle(
      async () => {
        try {
          const response = (
            await moodboardReferencesApi.getMoodboardReferencesList(
              dataQuery.limit,
              (dataQuery.page - 1) * dataQuery.limit,
              dataQuery.componentTypeId,
              dataQuery.prototypeId,
              dataQuery.userId,
              dataQuery.type,
              dataQuery.tagId,
              dataQuery.searchKey,
            )
          )?.data;
          const { data, count } = response;
          const filteredData = data.filter((listItem: any) => {
            return (
              // eslint-disable-next-line no-underscore-dangle
              listItem.id !== selectedMoodboardReference._id &&
              // eslint-disable-next-line no-underscore-dangle
              listItem._id !== selectedMoodboardReference._id
            );
          });
          if (filteredData) {
            setMoodboardReferencesList(filteredData);
            setTotalNumberOfScreens(count);
          }
        } catch (error) {
          toast(
            'Error occurred while fetching data. Please reload to try again.',
            {
              type: 'error',
              autoClose: 10000,
            },
          );
        } finally {
          setDataRequestIsInProgress(false);
        }
      },
      600,
      { leading: false, trailing: true },
    );
    filterThrottle();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataQuery, selectedMoodboardReference, setDataRequestIsInProgress]);

  const [componentTypeLists, setComponentTypeLists] = useState<
    {
      _id: string;
      name: string;
    }[]
  >([]);

  const getComponentTypeList = async () => {
    try {
      const results =
        await moodboardComponentTypeApi.requestComponentTypeLists();
      setComponentTypeLists(results.data.componentData);
    } catch (e) {
      if (e.response && e.response.status) {
        // eslint-disable-next-line no-console
        console.error('components get error', e.response);
      }
    }
  };

  const componentTypeOptions: { value: string | null; label: string }[] = [
    { value: null, label: 'All' },
    ...componentTypeLists.map((component) => ({
      // eslint-disable-next-line no-underscore-dangle
      value: component._id,
      label: component.name,
    })),
  ];

  const selectedComponentTypeOption = componentTypeOptions.find(
    ({ value }) => value === dataQuery.componentTypeId,
  );

  const [prototypeLists, setPrototypeLists] = useState<
    {
      _id: string;
      name: string;
    }[]
  >([]);

  const getPrototypeList = async () => {
    try {
      const results = await prototypeApi.requestAllPrototypesList();
      setPrototypeLists(results.data.prototypeData);
    } catch (e) {
      if (e.response && e.response.status) {
        // eslint-disable-next-line no-console
        console.error('prototypes get error', e.response);
      }
    }
  };

  const prototypeOptions: { value: string | null; label: string }[] = [
    { value: null, label: 'All' },
    { value: '0', label: 'Not Specified' },
    ...prototypeLists.map((prototype) => ({
      // eslint-disable-next-line no-underscore-dangle
      value: prototype._id,
      label: prototype.name,
    })),
  ];

  const selectedPrototypeOption = prototypeOptions.find(
    ({ value }) => value === dataQuery.prototypeId,
  );

  const [tagLists, setTagLists] = useState<
    {
      _id: string;
      name: string;
    }[]
  >([]);

  const getTagList = async () => {
    try {
      const results = await moodboardTagApi.requestTagLists();
      setTagLists(results.data.tags);
    } catch (e) {
      toast('Error occurred while fetching data. Please reload to try again.', {
        type: 'error',
        autoClose: 10000,
      });
    }
  };

  const tagOptions: { value: string | null; label: string }[] = [
    { value: null, label: 'All' },
    ...tagLists.map((component) => ({
      // eslint-disable-next-line no-underscore-dangle
      value: component._id,
      label: component.name,
    })),
  ];

  const selectedTagOption = tagOptions.find(
    ({ value }) => value === dataQuery.tagId,
  );

  const onPageChange = ({ selected: selectedPage }: { selected: number }) => {
    setDataQuery({ ...dataQuery, page: selectedPage + 1 });
  };

  const onLimitChange = ({ target }: { target: HTMLSelectElement }) => {
    setDataQuery({ ...dataQuery, page: 1, limit: parseInt(target.value, 10) });
  };

  const onShowURLChange = (
    moodboardOptionIndex: any,
    referenceScreenIndex: any,
    option: any,
  ) => {
    const updatedMoodboardOptions: any = [...moodboardOptions];

    updatedMoodboardOptions[
      moodboardOptionIndex
    ].moodboard_reference_url_status[referenceScreenIndex].showURL = option;

    setMoodboardOptions(updatedMoodboardOptions);
  };

  useEffect(() => {
    getMoodboardOptionsByPageComponent(item.pageComponentId);
    getMoodboardOptionKeywords();
    getComponentTypeList();
    getPrototypeList();
    getTagList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    getMoodboardReferenceScreens();
  }, [dataQuery, getMoodboardReferenceScreens]);

  return (
    <>
      <Modal
        className="ar-edit-page__moodboard-popup"
        isOpen
        size="xl"
        // backdrop="static"
      >
        <ModalHeader className="ar-edit-page__moodboard-popup-title">
          {!showMoodboardGallery && (
            <>
              <div className="container-fluid">
                <Row>
                  <Col>Moodgen References</Col>
                </Row>
              </div>
            </>
          )}
          {showMoodboardGallery && (
            <>
              <div className="container-fluid">
                <Row>
                  <Col>
                    <CardTitle className="ar-edit-page__moodboard-gallery-title">
                      Moodgen Gallery
                    </CardTitle>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <CardText tag="div">
                      <div className="ar-edit-page__filter-panel">
                        <div className="ar-edit-page__filter-panel-column">
                          <Label>Filter by prototype</Label>
                          <Select
                            classNamePrefix="rctslct"
                            value={selectedPrototypeOption}
                            options={prototypeOptions}
                            onChange={(selectedPrototype) =>
                              setDataQuery({
                                ...dataQuery,
                                page: 1,
                                prototypeId: selectedPrototype?.value || null,
                              })
                            }
                          />
                        </div>
                        <div className="ar-edit-page__filter-panel-column">
                          <Label>Filter by section</Label>
                          <Select
                            classNamePrefix="rctslct"
                            value={selectedComponentTypeOption}
                            options={componentTypeOptions}
                            onChange={(selectedComponentType) => {
                              setDataQuery({
                                ...dataQuery,
                                page: 1,
                                componentTypeId:
                                  selectedComponentType?.value || null,
                              });
                            }}
                          />
                        </div>
                        <div className="ar-edit-page__filter-panel-column">
                          <Label>Filter by tag</Label>
                          <Select
                            isMulti
                            classNamePrefix="rctslct"
                            options={tagOptions}
                            onChange={(selectedTag) => {
                              const selectedOptions: any = [];
                              // eslint-disable-next-line no-unused-expressions
                              selectedTag?.forEach((selectedTagitem) => {
                                selectedOptions.push(selectedTagitem.value);
                              });
                              setDataQuery({
                                ...dataQuery,
                                page: 1,
                                tagId: selectedOptions || null,
                              });
                            }}
                          />
                        </div>
                        <div className="ar-edit-page__filter-panel-column">
                          <Label>Filter by keyword</Label>
                          <Input
                            type="text"
                            className="search form-control ar-common-form__input"
                            id="search-moodref"
                            placeholder="Search"
                            onChange={(e) =>
                              setDataQuery({
                                ...dataQuery,
                                page: 1,
                                searchKey: e.target.value.trim(),
                              })
                            }
                          />
                        </div>
                      </div>
                    </CardText>
                  </Col>
                </Row>
              </div>
            </>
          )}
        </ModalHeader>
        <ModalBody>
          <div className=" ar-edit-page__moodboard-popup-prv">
            {(presentationModeIsDesign &&
              (item.designImage || item.design_image)) ||
            (!presentationModeIsDesign &&
              (item.wireFrameImage || item.wire_frame_image)) ? (
              <img
                src={`${s3BucketUrl}/${
                  presentationModeIsDesign
                    ? formatImageUrl(item.designImage) ||
                      formatImageUrl(item.design_image)
                    : formatImageUrl(item.wireFrameImage) ||
                      formatImageUrl(item.wire_frame_image)
                }`}
                alt="default component"
                className="cover"
              />
            ) : (
              <img
                src={defaultComponentCover}
                alt="default component"
                className="cover"
              />
            )}
          </div>
          {!showMoodboardGallery && (
            <div>
              {moodboardOptions.map(
                (moodboardOption: any, moodboardOptionIndex) => (
                  // eslint-disable-next-line no-underscore-dangle
                  <Card key={moodboardOption._id}>
                    <CardBody>
                      <div className="container">
                        <Row>
                          <Col xs="10" className="offset-sm-1">
                            <CardTitle className="ar-edit-page__moodboard-option-title">
                              Option {moodboardOptionIndex + 1}
                              {moodboardOptions.length > 1 && (
                                <Button
                                  color="btn-default"
                                  className="float-right btn-close"
                                  onClick={() =>
                                    handleRemoveOption(moodboardOptionIndex)
                                  }
                                >
                                  <FontAwesomeIcon
                                    icon={faRemove}
                                    title="Remove Option"
                                    className="btn-close"
                                  />
                                </Button>
                              )}
                            </CardTitle>
                            <CardText>
                              <CreatableSelect
                                options={options}
                                classNamePrefix="rctslct"
                                isMulti
                                isClearable
                                value={moodboardOption.keywords.map(
                                  (keyword: any) => {
                                    const { _id: id, name } = keyword;
                                    return { value: id, label: name };
                                  },
                                )}
                                onChange={(selectedKeywords: any) =>
                                  updateKeywords(
                                    moodboardOptionIndex,
                                    selectedKeywords,
                                  )
                                }
                                placeholder="Keywords"
                              />
                            </CardText>
                            <Container className="ar-edit-page__moodboard-popup-body">
                              <Row>
                                {moodboardOption.references.map(
                                  (reference: any, referenceIndex: number) => (
                                    <Col
                                      className="col bg-light border"
                                      sm="4"
                                      // eslint-disable-next-line no-underscore-dangle
                                      key={reference._id}
                                    >
                                      <div>
                                        <ImagePopup
                                          // eslint-disable-next-line no-underscore-dangle
                                          key={reference._id}
                                          image={reference.image_path}
                                          mobileImage={undefined}
                                          show={
                                            Array.isArray(showImagePopup) &&
                                            showImagePopup[0] ===
                                              moodboardOptionIndex &&
                                            showImagePopup[1] === referenceIndex
                                          }
                                          onClose={() =>
                                            setShowImagePopup(null)
                                          }
                                        />
                                        <div className="overlay">
                                          <div className="overlay__buttons">
                                            <Button
                                              color="secondary"
                                              onClick={() =>
                                                openImagePopup(
                                                  moodboardOptionIndex,
                                                  referenceIndex,
                                                )
                                              }
                                            >
                                              <FontAwesomeIcon
                                                icon={faEye}
                                                title="View"
                                              />
                                            </Button>

                                            <Button
                                              color="secondary"
                                              onClick={() =>
                                                openMoodboardGallery(
                                                  moodboardOptionIndex,
                                                  referenceIndex,
                                                  reference,
                                                )
                                              }
                                            >
                                              <FontAwesomeIcon
                                                icon={faEdit}
                                                title="Update"
                                              />
                                            </Button>

                                            <Button
                                              color="secondary"
                                              onClick={() =>
                                                removeReferenceScreen(
                                                  moodboardOptionIndex,
                                                  referenceIndex,
                                                )
                                              }
                                            >
                                              <FontAwesomeIcon
                                                icon={faRemove}
                                                title="Remove"
                                              />
                                            </Button>
                                          </div>
                                          <Label
                                            check
                                            className="overlay__checkbok"
                                          >
                                            <Input
                                              type="checkbox"
                                              checked={
                                                moodboardOptions[
                                                  moodboardOptionIndex
                                                ]
                                                  .moodboard_reference_url_status[
                                                  referenceIndex // eslint-disable-next-line dot-notation
                                                ]['showURL']
                                              }
                                              onChange={(e) =>
                                                onShowURLChange(
                                                  moodboardOptionIndex,
                                                  referenceIndex,
                                                  e.target.checked,
                                                )
                                              }
                                            />
                                            <strong>Show URL</strong>
                                          </Label>
                                        </div>
                                      </div>
                                    </Col>
                                  ),
                                )}
                                {moodboardOption.references.length <
                                  MAX_NUMBER_OF_SCREENS_PER_OPTION && (
                                  <Col className="col bg-light border" sm="4">
                                    <Button
                                      color="primary"
                                      className="btn btn-plus"
                                      onClick={() =>
                                        openMoodboardGallery(
                                          moodboardOptionIndex,
                                          moodboardOption
                                            .moodboard_reference_ids.length,
                                          { _id: null },
                                        )
                                      }
                                    >
                                      <FontAwesomeIcon
                                        icon={faPlus}
                                        title="Add New Reference"
                                      />
                                    </Button>
                                  </Col>
                                )}
                              </Row>
                            </Container>
                          </Col>
                        </Row>
                      </div>
                    </CardBody>
                  </Card>
                ),
              )}
              {moodboardOptions.length < MAX_NUMBER_OF_OPTIONS && (
                <div className="container">
                  <Row>
                    <Col xs="10" className="offset-sm-1">
                      <div
                        className="ar-edit-page__add-section"
                        onClick={addNewOption}
                      >
                        {' '}
                        Add a new Option
                      </div>
                    </Col>
                  </Row>
                </div>
              )}
            </div>
          )}
          {showMoodboardGallery && (
            <div>
              <Card className="ar-edit-page__moodboard-gallery">
                <CardBody>
                  <div>
                    <div className="ar-edit-page__moodboard-gallery-selections container-fluid">
                      {Object.prototype.hasOwnProperty.call(
                        selectedMoodboardReference,
                        '_id',
                      ) && // eslint-disable-next-line no-underscore-dangle
                        selectedMoodboardReference._id && (
                          <>
                            <p>Current Selection</p>
                            <Row>
                              <Col className="col bg-light border" sm="3">
                                <div>
                                  <ImagePopup
                                    image={
                                      selectedMoodboardReference.image_path
                                    }
                                    mobileImage={undefined}
                                  />
                                </div>
                              </Col>
                            </Row>
                            <Row>
                              <Col sm="12" className="ar-edit-page__divider">
                                <hr />
                              </Col>
                            </Row>
                          </>
                        )}
                      <Row>
                        {dataRequestIsInProgress && (
                          <Col sm="12">
                            <div style={{ minHeight: 250 }}>
                              <div className="loading-indicator" />
                            </div>
                          </Col>
                        )}
                        {!dataRequestIsInProgress &&
                          (moodboardReferencesList.length > 0
                            ? moodboardReferencesList.map(
                                (reference: any, referenceIndex: any) => (
                                  <Col
                                    className="col bg-light border"
                                    sm="3"
                                    // eslint-disable-next-line no-underscore-dangle
                                    key={reference._id}
                                  >
                                    <div>
                                      <ImagePopup
                                        // eslint-disable-next-line no-underscore-dangle
                                        key={reference._id}
                                        image={reference.image_path}
                                        mobileImage={undefined}
                                        show={
                                          Array.isArray(showImagePopup) &&
                                          showImagePopup[0] ===
                                            selectedOptionIndex &&
                                          showImagePopup[1] === referenceIndex
                                        }
                                        onClose={() => setShowImagePopup(null)}
                                      />

                                      <div className="overlay">
                                        <div className="overlay__buttons">
                                          <Button
                                            color="secondary"
                                            onClick={() =>
                                              openImagePopup(
                                                selectedOptionIndex,
                                                referenceIndex,
                                              )
                                            }
                                          >
                                            <FontAwesomeIcon
                                              icon={faEye}
                                              title="View"
                                            />
                                          </Button>
                                          <Button
                                            color="secondary"
                                            onClick={() =>
                                              updateSelectedReferenceScreens(
                                                selectedOptionIndex,
                                                selectedMoodboardReferenceIndex,
                                                reference,
                                              )
                                            }
                                          >
                                            <FontAwesomeIcon
                                              icon={faCheck}
                                              title="Select"
                                            />
                                          </Button>
                                        </div>
                                      </div>
                                    </div>
                                  </Col>
                                ),
                              )
                            : 'Sorry, Your filter produced no results!')}
                      </Row>
                    </div>
                  </div>
                </CardBody>
              </Card>
            </div>
          )}
        </ModalBody>
        <ModalFooter>
          <Row className="w-100">
            <Col xs="6" className="d-flex">
              {moodboardReferencesList.length > 0 && (
                <Pagination
                  count={totalNumberOfScreens}
                  pageCount={Math.ceil(totalNumberOfScreens / dataQuery.limit)}
                  onPageChange={onPageChange}
                  onPageSelect={onLimitChange}
                  currentPage={dataQuery.page}
                  pageLimit={dataQuery.limit}
                />
              )}
            </Col>
            <Col xs="6" className="d-flex justify-content-end">
              {showMoodboardGallery ? (
                <Button
                  type="button"
                  className="ar-btn ar-btn--ternary "
                  onClick={closeMoodboardGallery}
                >
                  Go Back
                </Button>
              ) : (
                <>
                  <Button
                    type="button"
                    className="ar-btn ar-btn--ternary mr-3"
                    onClick={handleClose}
                  >
                    Cancel
                  </Button>
                  <Button
                    type="button"
                    className="ar-btn ar-btn--ternary"
                    onClick={handleSaveOption}
                  >
                    Save
                  </Button>
                </>
              )}
            </Col>
          </Row>
        </ModalFooter>
      </Modal>
    </>
  );
};

export default MoodboardPopup;
