import React, { useEffect } from 'react';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';

const Alert = (props: any) => {
  const { onCancel, onConfirm, open } = props;

  const SweetAlert = withReactContent(Swal);
  useEffect(() => {
    SweetAlert.fire({
      title: 'Confirm Action',
      text: 'Do you want to leave the page without save?',
      icon: 'question',
      showConfirmButton: true,
      showCancelButton: true,
      confirmButtonText: 'Ok',
    }).then((result) => {
      if (result.isConfirmed) {
        onConfirm();
      } else {
        onCancel();
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);

  return <></>;
};

export default Alert;
