import React from 'react';

const FloatBtnGroup = ({ children }: any) => {
  return (
    <div className="ar-common-float-button ar-common-float-button--offset">
      <div className="ar-common-float-button__outer">
        <div className="ar-common-float-button__inner">{children}</div>
      </div>
    </div>
  );
};

export default FloatBtnGroup;
