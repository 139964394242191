/* eslint-disable camelcase */
import axiosApi from './axiosApi';

const getReferenceScreensList = async (
  limit = 10,
  skip = 0,
  /* eslint-disable @typescript-eslint/camelcase */
  user_id: string | null = null,
  media_type: string | null = null,
  /* eslint-enable */
) => {
  return axiosApi.get(`reference-screens`, {
    // eslint-disable-next-line @typescript-eslint/camelcase
    params: { limit, skip, user_id, media_type },
  });
};

const deleteById = async (id: string) => {
  return axiosApi.delete(`reference-screens/${id}`);
};

export default {
  getReferenceScreensList,
  deleteById,
};
