import { DOWNLOAD_TYPES } from './downloadTypes';

export const wpApiPrefix = 'cms/index.php/wp-json';
export const apiPrefix = 'argo/v1';

// ********************
// Page url's
// ********************

export const home = '/';

export const ASC = 'ASC';
export const DESC = 'DESC';
export const PAGE_LIMIT = 24;
export const PAGE_LIMIT_4K = 48;
// eslint-disable-next-line @typescript-eslint/camelcase
export const BREAK_POINT_4k = 3500;
export { DOWNLOAD_TYPES };

// Moodboard options popup
export const MAX_NUMBER_OF_OPTIONS = 4;
export const MAX_NUMBER_OF_SCREENS_PER_OPTION = 3;
export const MOODBOARD_EMAIL_HEADING = 'Generate Moodboard';
export const MOODBOARD_EMAIL_MESSAGE =
  'This may take a few minutes to generate. The file will be emailed to you once it is ready!';
