import axios from 'axios';
import config from '../config';

// TODO : USE this BASE API insted of axioxApi after handleing the error
const profile = localStorage.getItem('profile');
let authToken = '';
if (profile) {
  const jsnProfile = JSON.parse(profile);
  authToken = jsnProfile.authToken
    ? `Bearer ${jsnProfile.authToken}`
    : 'Bearer -';
}

const baseAPI = async (
  method: string,
  urlSegment: string,
  params: {} | undefined,
) => {
  const axiosApi = axios.create({
    baseURL: `${config.api.BASE_URL}${config.api.API_PREFIX}`,
    headers: {
      'Content-Type': 'application/json',
      Authorization: authToken,
    },
  });

  // let response: any;
  try {
    return axiosApi.post(urlSegment, params);
  } catch (error) {
    return false;
  }

  // if (method === 'POST') {
  //   response = axiosApi.post(urlSegment, params);
  // } else if (method === 'DELETE') {
  //   response = axiosApi.delete(urlSegment, params);
  // } else {
  //   response = axiosApi.get(urlSegment);
  // }
  // return response;
};

export default baseAPI;
