import React from 'react';
import { Row, Col, Button, Label } from 'reactstrap';

const SKUGenerator = () => {
  return (
    <div className="ar-common-content">
      <Row xs="1" sm="1" md="1" className="title-wrapper">
        <Col className="main-title">Generate SKU</Col>
      </Row>

      <Row xs="1" sm="1" md="1" lg="8">
        <Col xs="3" sm="3" md="1">
          <Label />
          <Button
            className="search-btn  wf-button right form-control"
            id="search-btn"
          >
            Generate
          </Button>
        </Col>
      </Row>
    </div>
  );
};

export default SKUGenerator;
