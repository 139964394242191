import React, { useRef, useState, useEffect } from 'react';
import { Button, UncontrolledTooltip } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import defaultPrototypeCover from '../../../assets/images/default-component.png';
import { DOWNLOAD_TYPES } from '../../../constants';
import DropDownButton from '../../../components/common/DropDownButton';
import { getSanitizedS3BucketUrl } from '../../../helpers';

const PrototypeGridItem = (props: {
  item: {
    _id: string;
    image: string;
    name: string;
    generated: number;
    frd_doc_link: string;
    sketch_file_link: string;
    user_guide_link: string;
    test_case_link: string;
  };
  onDelete: Function;
  onEdit: Function;
  onPageEdit: Function;
  onDocumentGenerate: Function;
  itemKey: string;
}) => {
  const {
    item,
    item: {
      _id: id,
      image,
      name,
      generated,
      frd_doc_link: frdDocLink,
      sketch_file_link: sketchFileUrl,
      user_guide_link: userGuideLink,
      test_case_link: testCaseLink,
    },
    onDelete,
    onEdit,
    onPageEdit,
    onDocumentGenerate,
    itemKey,
  } = props;

  const [isGenerating, setIsGenerating] = useState(false);
  const isMounted = useRef(false);

  useEffect(() => {
    isMounted.current = true;
    return () => {
      isMounted.current = false;
    };
  }, []);

  const handleDownloadOption = (option: string, prototypeId: string) => {
    if (!isMounted.current) return;
    let downloadLink = '';
    let key = '';

    switch (option) {
      case DOWNLOAD_TYPES.WF_SKETCH:
        downloadLink = sketchFileUrl;
        key = DOWNLOAD_TYPES.WF_SKETCH_KEY;
        break;
      case DOWNLOAD_TYPES.FRD:
        downloadLink = frdDocLink;
        key = DOWNLOAD_TYPES.FRD_KEY;
        break;
      case DOWNLOAD_TYPES.USER_GUIDE:
        downloadLink = userGuideLink;
        key = DOWNLOAD_TYPES.USER_GUIDE_KEY;
        break;
      case DOWNLOAD_TYPES.TEST_CASE_DOC:
        downloadLink = testCaseLink;
        key = DOWNLOAD_TYPES.TEST_CASE_DOC_KEY;
        break;

      default:
        break;
    }

    if (downloadLink && generated) {
      // eslint-disable-next-line no-unused-expressions
      option === DOWNLOAD_TYPES.WF_SKETCH
        ? (window.location.href = downloadLink)
        : window.open(downloadLink, '_blank');
    } else {
      setIsGenerating(true);
      onDocumentGenerate(prototypeId, key).then(() => {
        if (isMounted.current) {
          setIsGenerating(false);
        }
      });
    }
  };

  const availableDownloadOptions: any = {};

  availableDownloadOptions.WF_SKETCH = `${DOWNLOAD_TYPES.WF_SKETCH} - ${
    sketchFileUrl && generated ? 'Download' : 'Generate'
  }`;

  if (process.env.REACT_APP_SHOW_FRD_GENERATE === 'true') {
    availableDownloadOptions.FRD = `${DOWNLOAD_TYPES.FRD} - ${
      frdDocLink && generated ? 'Open' : 'Generate'
    }`;
  }
  // 22 feb 2023, Tharindu Rupasinghe , Removed the option upon chathura's request until google app script issues beign fixed.
  // availableDownloadOptions.USER_GUIDE = `${DOWNLOAD_TYPES.USER_GUIDE} - ${
  //   userGuideLink && generated ? 'Download' : 'Generate'
  // }`;

  if (process.env.REACT_APP_SHOW_TEST_CASE_GENERATE === 'true') {
    availableDownloadOptions.TEST_CASE_DOC = `${
      DOWNLOAD_TYPES.TEST_CASE_DOC
    } - ${testCaseLink && generated ? 'Open' : 'Generate'}`;
  }

  return (
    <div className="ar-common-post ar-common-post--download">
      <div className="ar-common-post__image image-container">
        {image ? (
          <img
            src={`${getSanitizedS3BucketUrl()}/${image}`}
            alt="default component"
            className="cover"
          />
        ) : (
          <img
            src={defaultPrototypeCover}
            alt="default component"
            className="cover"
          />
        )}
      </div>
      <div className="ar-common-post__container">
        <div className="ar-common-post__title">{name}</div>
        <div className="ar-common-post__action">
          <DropDownButton
            selectedOption={(option: string) =>
              handleDownloadOption(option.split('-')[0].trim(), id)
            }
            options={availableDownloadOptions}
            disabled={isGenerating}
          >
            {isGenerating ? (
              <>
                <span className="mr-1">Processing</span>
                <FontAwesomeIcon icon={faSpinner} spin />
              </>
            ) : (
              'Download'
            )}
          </DropDownButton>
          <Button
            id={`edit-page-button-${itemKey}`}
            className="ar-btn ar-btn--primary-page-edit"
            color="link"
            tabIndex={0}
            onKeyDown={() => onPageEdit(item)}
            onClick={() => onPageEdit(item)}
          />
          <UncontrolledTooltip
            placement="bottom"
            target={`edit-page-button-${itemKey}`}
          >
            Edit Pages
          </UncontrolledTooltip>
          <Button
            id={`edit-details-button-${itemKey}`}
            className="ar-btn ar-btn--primary-edit"
            color="link"
            tabIndex={0}
            onKeyDown={() => onEdit(id, name)}
            onClick={() => onEdit(id, name)}
          />
          <UncontrolledTooltip
            placement="bottom"
            target={`edit-details-button-${itemKey}`}
          >
            Edit Details
          </UncontrolledTooltip>
          <Button
            id={`delete-button-${itemKey}`}
            className="ar-btn ar-btn--primary-delete"
            color="link"
            tabIndex={0}
            onKeyDown={() => onDelete(id)}
            onClick={() => onDelete(id)}
          />
          <UncontrolledTooltip
            placement="bottom"
            target={`delete-button-${itemKey}`}
          >
            Delete
          </UncontrolledTooltip>
        </div>
      </div>
    </div>
  );
};

export default PrototypeGridItem;
