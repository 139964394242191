/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import { toast } from 'react-toastify';
import { Col, Row, Button, UncontrolledTooltip } from 'reactstrap';
import moment from 'moment';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faEdit,
  faTrash,
  faChevronRight,
} from '@fortawesome/free-solid-svg-icons';
import { uniqueId } from 'lodash';
import prototypeApi from '../../../api/prototypeApi';

const SweetAlert = withReactContent(Swal);
const CreatePage = (props: {
  match: {
    params: { prototypeId: string; prototypeName: string };
  };
  history: {
    goBack: () => void;
    push: Function;
  };
  location: {
    state: {
      created_at: Date | null;
      prototypeName: string;
    };
  };
}) => {
  const [pages, setPages] = useState<{ _id: string; name: string }[]>([
    { _id: '0', name: 'Home' },
  ]);
  const [requestDataComplete, setRequestDataComplete] =
    useState<boolean>(false);
  const [savedPages, setSavedPages] = useState<{ _id: string; name: string }[]>(
    [],
  );
  const [requestDataError, setRequestDataError] = useState({
    errCode: null,
    errMessage: '',
  });
  const {
    match: {
      params: { prototypeId, prototypeName },
    },
    history,
    location: { state: locationState },
  } = props;
  const [prototypeCreatedDate, setPrototypeCreatedDate] = useState(
    locationState?.created_at,
  );

  const getPages = async () => {
    try {
      setRequestDataComplete(false);
      const results = await prototypeApi.requestPages({
        prototypeId,
      });
      if (results.data.data && results.data.data.length > 0) {
        const pagesList = results.data.data.map(
          (page: { page_component_count: any }) => {
            // eslint-disable-next-line no-param-reassign
            delete page.page_component_count;
            return page;
          },
        );
        setSavedPages(pagesList);
        setPages(pagesList);
      }
      setRequestDataComplete(true);
    } catch (e) {
      if (e.response && e.response.status) {
        setRequestDataError({
          errCode: e.response,
          errMessage: 'Something went wrong when getting pages.',
        });
      }
    }
  };

  const getPrototype = async () => {
    const results = await prototypeApi.requestPrototypeById(prototypeId);
    if (results.data?.prototype?.createdAt) {
      setPrototypeCreatedDate(results.data.prototype.createdAt);
    }
  };

  useEffect(() => {
    getPages();
    if (!prototypeCreatedDate) {
      getPrototype();
    }
  }, []);

  const handleSubmit = async () => {
    const saveResponse = await prototypeApi.savePage({
      // eslint-disable-next-line @typescript-eslint/camelcase
      prototype_id: prototypeId,
      pages,
    });
    toast('Your changes have been saved successfully', {
      type: 'success',
      autoClose: 2000,
    });
    if (saveResponse.data.data && saveResponse.data.data.length > 0) {
      setSavedPages(saveResponse.data.data);
      setPages(saveResponse.data.data);
    }
  };

  const handleInputChange = (
    e: { target: { value: string; name: string; id: string } },
    index: number,
  ) => {
    const { value } = e.target;
    pages[index].name = value;
  };

  const deletePageWithConfirmation = (asyncCallback: CallableFunction) => {
    SweetAlert.fire({
      title: 'Confirm Action',
      text: 'Are you sure your want to delete this entire page?',
      icon: 'question',
      showConfirmButton: true,
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!',
    }).then(async (result) => {
      if (result.isConfirmed) {
        await asyncCallback();
      }
    });
  };

  const handleRemoveClick = (index: number) => {
    deletePageWithConfirmation(async () => {
      const list = [...pages];
      list.splice(index, 1);
      setPages(list);
      toast('Page has been removed successfully', {
        type: 'success',
        autoClose: 2000,
      });
    });
  };

  const handleRemoveAPIClick = async (index: number, pageId: string) => {
    deletePageWithConfirmation(async () => {
      const list = [...pages];
      list.splice(index, 1);
      setPages(list);
      /* eslint-disable @typescript-eslint/camelcase */
      await prototypeApi.removePage({
        prototype_id: prototypeId,
        page_id: pageId,
      });
      /* eslint-enable */
      toast('Page has been removed successfully', {
        type: 'success',
        autoClose: 2000,
      });
    });
  };

  const handleAddClick = () => {
    setPages([...pages, { _id: '0', name: `page ${uniqueId('pgno-')}` }]);
  };

  const handleEditClick = (prototype: string, page: string, name: string) => {
    history.push({
      pathname: `/prototype/edit-page/${prototype}/${page}`,
      state: {
        prototypeName,
        pageName: name,
      },
    });
  };

  const getRemoveButton = (id: string, index: number) => {
    const isASavedPage = savedPages.some(
      // eslint-disable-next-line no-underscore-dangle
      (singlePage: { _id: string; name: string }) => singlePage._id === id,
    );
    // eslint-disable-next-line no-underscore-dangle
    const savedPageIds = savedPages.flatMap((page) => page._id);
    const savedPagesInPages = pages.filter((page) =>
      // eslint-disable-next-line no-underscore-dangle
      savedPageIds.find((savedPageId) => savedPageId === page._id),
    );
    let onClickHandler: Function | undefined;
    if (savedPagesInPages.length !== 1 && isASavedPage) {
      onClickHandler = handleRemoveAPIClick;
    } else if (!isASavedPage && pages.length !== 1) {
      onClickHandler = handleRemoveClick;
    }
    return onClickHandler ? (
      <>
        <Button
          id={`remove-page-button-${index}`}
          type="button"
          className="ar-btn ar-btn--ternary ar-btn--icon-rounded"
          style={{ padding: '10px 15px' }}
          onClick={() => (onClickHandler ? onClickHandler(index, id) : null)}
        >
          <FontAwesomeIcon icon={faTrash} />
        </Button>
        <UncontrolledTooltip
          placement="bottom"
          target={`remove-page-button-${index}`}
        >
          Remove page
        </UncontrolledTooltip>
      </>
    ) : null;
  };

  return (
    <div className="ar-common-page__content prototype">
      <Row>
        <Col>
          <div className="ar-common-breadcrumb">
            <button
              className="ar-common-breadcrumb__link"
              type="button"
              onClick={() => {
                history.push('/prototypes');
              }}
            >
              Prototype
            </button>
            <FontAwesomeIcon icon={faChevronRight} />
            <span className="ar-common-breadcrumb__page">
              Edit Pages({prototypeName || locationState.prototypeName})
            </span>
          </div>
        </Col>
      </Row>
      <Row>
        <Col>
          <div className="ar-common-title">Prototype: {prototypeName} </div>
        </Col>
      </Row>
      <div className="ar-common-content--view-pages">
        <Row>
          <Col lg="9">
            {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
            <label>Pages</label>
            {requestDataComplete && pages ? (
              pages.map((page, index) => {
                const { _id: id, name } = page;
                return (
                  <Row key={Math.random()}>
                    <Col xs="12" md="8">
                      <div className="form-group">
                        <input
                          type="text"
                          className="form-control"
                          name={name}
                          id={id}
                          defaultValue={page.name}
                          onChange={(e) => handleInputChange(e, index)}
                        />
                      </div>
                    </Col>
                    <Col xs="3" md="4" className="mb-2">
                      {savedPages.some(
                        (singlePage: { _id: string; name: string }) =>
                          // eslint-disable-next-line no-underscore-dangle
                          singlePage._id === id,
                      ) && (
                        <>
                          <Button
                            id={`edit-button-${index}`}
                            type="button"
                            className="ar-btn ar-btn--ternary ar-btn--icon-rounded mb-2"
                            onClick={() =>
                              handleEditClick(prototypeId, id, name)
                            }
                          >
                            <FontAwesomeIcon icon={faEdit} />
                          </Button>
                          <UncontrolledTooltip
                            placement="bottom"
                            target={`edit-button-${index}`}
                          >
                            Edit
                          </UncontrolledTooltip>
                        </>
                      )}
                      <div className="prototype__remove">
                        {getRemoveButton(id, index)}
                      </div>
                    </Col>
                    <Col xs="12" />
                  </Row>
                );
              })
            ) : (
              <div>
                {requestDataError.errCode
                  ? requestDataError.errMessage
                  : 'Loading...'}
              </div>
            )}
            <Row>
              <Col>
                <div className="form-group">
                  <Button
                    type="button"
                    className="ar-btn ar-btn--ternary"
                    onClick={() => handleAddClick()}
                  >
                    + Add
                  </Button>
                </div>
              </Col>
              <Col />
            </Row>
          </Col>
        </Row>
        <Row className="mt-3">
          <Col>
            <span className="d-block my-3">
              <strong>Created on:</strong>&nbsp;
              {prototypeCreatedDate
                ? moment(prototypeCreatedDate).format('MM/DD/yyyy')
                : ''}
            </span>
            <div className="ar-common-page__button-group">
              <Button
                type="button"
                onClick={() => handleSubmit()}
                className="ar-btn ar-btn--ternary"
              >
                Save
              </Button>{' '}
              <Button
                type="button"
                onClick={() => {
                  history.push(`/prototypes/`);
                }}
                className="ar-btn ar-btn--ternary"
              >
                Cancel
              </Button>{' '}
            </div>
          </Col>
        </Row>{' '}
      </div>
    </div>
  );
};

export default CreatePage;
