import React from 'react';
import { Field, ErrorMessage } from 'formik';
import TextError from './TextError';

const ToggleButton: React.FC<any> = (props) => {
  const { label, name, type, ...rest } = props;

  return (
    <div className="form-group form-check ar-common-toggle">
      {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
      <label htmlFor={name} className="switch ar-common-toggle__swich">
        <Field
          className="form-check-input ar-common-toggle__field"
          name={name}
          id={name}
          type={type}
          // eslint-disable-next-line react/jsx-props-no-spreading
          {...rest}
        />
        <span className="slider ar-common-toggle__slider" />
      </label>
      <span className="label-text ar-common-toggle__label-text">{label}</span>
      <ErrorMessage name={name} component={TextError} />
    </div>
  );
};

export default ToggleButton;
