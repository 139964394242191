import React, { useState, useEffect } from 'react';
import { Row, Col, Input } from 'reactstrap';
import { Link } from 'react-router-dom';
import { capitalize } from 'lodash';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronRight } from '@fortawesome/free-solid-svg-icons';
import { toast } from 'react-toastify';
import projectApi from '../../../api/projectApi';
import defaultComponentCover from '../../../assets/images/default-component.png';
import { get as getLsValue } from '../../../helpers/local-storage';
import { getSanitizedS3BucketUrl } from '../../../helpers';
import FloatBtnGroup from '../../../components/common/FloatBtnGroup';
import FloatBtn from '../../../components/common/FloatBtn';

const SearchComponent = (props: {
  match: {
    params: { prototypeId: string; pageId: string };
  };
  history: any;
  location: any;
}) => {
  const {
    match: {
      params: { prototypeId, pageId },
    },
    location,
    history,
  } = props;

  const [state, setState] = useState<{
    pageId: string;
    prototypeId: string;
    projectId: string;
    projectName: string;
    checkedValue: {
      id: string;
      _id: string;
      url: string;
      page: string;
      project: string;
    }[];
    prototypeName: string;
    pageName: string;
    prototypePageId: string;
    prototypePageName: string;
  }>({
    pageId,
    prototypeId,
    projectId: '',
    projectName: '',
    checkedValue: [{ id: '', _id: '', url: '', page: '', project: '' }],
    prototypeName: '',
    pageName: '',
    prototypePageId: '',
    prototypePageName: '',
  });
  const [data, setData] = useState({ componentData: [{}], count: 1 });

  const getComponents = async (projectId: string) => {
    try {
      const results = await projectApi.getProjectComponents(projectId, pageId);
      setData({
        componentData: results.data?.data || [],
        count: results.data?.data.length || 0,
      });
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error(e);
    }
  };

  useEffect(() => {
    if (location && location.state) {
      setState(location.state);
      getComponents(location.state.projectId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleSelectCheckbox = (event: any, item: any) => {
    const { pageName, projectName } = state;
    const list = [...state.checkedValue];
    const stateList = { ...state };
    if (event.target.checked) {
      list.push({ ...item, pageName, project: projectName });
      stateList.checkedValue = list;
      setState(stateList);
    } else {
      const newList = list.filter((listItem) => {
        // eslint-disable-next-line no-underscore-dangle
        return listItem.id !== item._id && listItem._id !== item._id;
      });
      stateList.checkedValue = newList;
      setState({
        ...stateList,
        checkedValue: newList,
      });
    }
  };

  const handleAdd = () => {
    // eslint-disable-next-line no-shadow
    const { prototypeId, prototypePageId } = state;
    history.push({
      pathname: `/prototype/edit-page/${prototypeId}/${prototypePageId}`,
      state,
    });
    toast('Your changes have been saved successfully', {
      type: 'success',
      autoClose: 3,
    });
  };

  const handleCancel = () => {
    history.goBack();
  };

  const presentationModeIsDesign = getLsValue(
    'theme-setting-show-designs',
    false,
  );

  const s3BucketUrl = getSanitizedS3BucketUrl();
  const getFullImageUrl = (path: string | undefined) =>
    `${s3BucketUrl}/${
      path && path[0] === '/' ? path.substring(1, path.length) : path
    }`;

  return (
    <div className="ar-common-content">
      <Row xs="1" sm="1" md="1">
        <Col>
          <div className="ar-common-breadcrumb">
            <Link
              to={{
                pathname: '/prototypes',
              }}
            >
              <button type="button" className="ar-common-breadcrumb__link">
                Prototype
              </button>
            </Link>
            <FontAwesomeIcon icon={faChevronRight} />
            <Link
              to={{
                pathname: `/prototype/create-page/${prototypeId}/${location.state.prototypeName}`,
              }}
            >
              <button type="button" className="ar-common-breadcrumb__link">
                Edit page({location.state.prototypeName})
              </button>
            </Link>
            <FontAwesomeIcon icon={faChevronRight} />
            <Link
              to={{
                pathname: `/prototype/edit-page/${prototypeId}/${location.state.prototypePageId}`,
                state: {
                  prototypeName: location.state.prototypeName,
                  pageName: location.state.prototypePageName,
                  checkedValue: location.state.checkedValue,
                },
              }}
            >
              <button type="button" className="ar-common-breadcrumb__link">
                Edit page({capitalize(location.state.prototypePageName)})
              </button>
            </Link>
            <FontAwesomeIcon icon={faChevronRight} />
            <Link
              to={{
                pathname: `/prototype/page/search/${prototypeId}/${location.state.prototypePageId}`,
                state: {
                  prototypeName: location.state.prototypeName,
                  prototypePageName: location.state.prototypePageName,
                  checkedValue: location.state.checkedValue,
                },
              }}
            >
              <button type="button" className="ar-common-breadcrumb__link">
                Select page
              </button>
            </Link>
            <FontAwesomeIcon icon={faChevronRight} />
            <span className="ar-common-breadcrumb__page">
              {`${state.projectName || 'n/a'}(${state.pageName || 'n/a'})`}
            </span>
          </div>
        </Col>
      </Row>
      <Row>
        <Col>
          <div className="ar-edit-page">
            <FloatBtnGroup>
              <FloatBtn
                id="cancel-btn"
                type="submit"
                variant="cancel"
                onClickHandler={handleCancel}
                title="Back"
              />
              <FloatBtn
                type="button"
                name="save"
                variant="add"
                onClickHandler={handleAdd}
                title="Add"
              />
            </FloatBtnGroup>
            {data.componentData.map((item: any) => (
              <div
                className="ar-edit-page__single-sort-wrapper single"
                key={item.id}
              >
                <div className="ar-edit-page__checkbox-wrapper">
                  <div className="ar-common-checkbox">
                    <Input
                      type="checkbox"
                      className="form-control select-check"
                      checked={
                        !!state.checkedValue.some(
                          (singleSelect: { _id: string; url: string }) =>
                            // eslint-disable-next-line no-underscore-dangle, eqeqeq
                            singleSelect._id == item._id,
                        )
                      }
                      onChange={(e) => handleSelectCheckbox(e, item)}
                    />
                    <span />
                  </div>
                </div>
                {(presentationModeIsDesign && item.design_image) ||
                (!presentationModeIsDesign && item.wire_frame_image) ? (
                  <img
                    src={
                      presentationModeIsDesign
                        ? getFullImageUrl(item.design_image)
                        : getFullImageUrl(item.wire_frame_image)
                    }
                    alt="default component"
                    className="cover"
                  />
                ) : (
                  <img
                    src={defaultComponentCover}
                    alt="default component"
                    className="cover"
                  />
                )}
              </div>
            ))}
          </div>
        </Col>
      </Row>
    </div>
  );
};
export default SearchComponent;
