import React from 'react';
import { Field, ErrorMessage } from 'formik';
import TextError from '../common/TextError';

const Input: React.FC<any> = (props) => {
  const { label, name, type, url, ...rest } = props;
  const isURL = (val: string) => {
    const pattern = val.match(
      /(http(s)?:\/\/.){1}(www\.)?[-a-zA-Z0-9@:%._+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_+.~#?&//=]*)/g,
    );
    return pattern !== null;
  };
  return (
    <div className="form-group url-input">
      <label htmlFor={name}>{label}</label>
      <Field
        className="form-control"
        name={name}
        id={name}
        type={type}
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...rest}
      />

      {type === 'url' && url === '' && isURL(url) && (
        <div className="input-group-append link-button">
          <a href={url} target="_blank" rel="noopener noreferrer">
            {' '}
          </a>
        </div>
      )}
      <ErrorMessage name={name} component={TextError} />
    </div>
  );
};

export default Input;
