import React, { useState, useEffect, useCallback, useRef } from 'react';
import { Row, Col, Input, Label, FormGroup } from 'reactstrap';
import DatePicker from 'react-datepicker';
import moment, { Moment } from 'moment';
import { throttle, DebouncedFunc } from 'lodash';
import 'react-datepicker/dist/react-datepicker.css';
import prototypeApi from '../../../api/prototypeApi';
// eslint-disable-next-line @typescript-eslint/camelcase
import { PAGE_LIMIT, PAGE_LIMIT_4K, BREAK_POINT_4k } from '../../../constants';
import Pagination from '../../../components/common/Pagination';
import PrototypeGridItem from '../components/prototypeGridItem';
import useWindowSize from '../../../helpers/useWindowSizeHook';

const MoodboardConfig = (props: any) => {
  const { history } = props;
  let prototypeList: any = null;
  let totalCount = 1;
  const currentDate = moment();

  const isMounted = useRef(false);
  const getPrototypesThrottled = useRef<DebouncedFunc<any> | null>(null);
  const [requestDataComplete, setRequestDataComplete] = useState(false);
  const [data, setData] = useState({ data: [{}], count: 1 });
  const { deviceWidth } = useWindowSize();
  const [params, setParams] = useState<{
    dates: {
      from: Moment | null;
      to: Moment | null;
    };
    search: string | null;
    page: number | 0;
    limit: number | 0;
  }>({
    dates: {
      from: currentDate.clone().subtract(1, 'year'),
      to: currentDate,
    },
    search: '',
    page: 1,
    // eslint-disable-next-line @typescript-eslint/camelcase
    limit: deviceWidth > BREAK_POINT_4k ? PAGE_LIMIT_4K : PAGE_LIMIT,
  });

  const getPrototypes = (args: any) => {
    if (deviceWidth > 0) {
      if (getPrototypesThrottled.current)
        getPrototypesThrottled.current.cancel();
      getPrototypesThrottled.current = throttle(
        async (filterValues: any) => {
          try {
            const {
              search,
              page,
              dates: { from, to },
              limit,
            } = filterValues;
            const paramData: any = {
              // date filter should applied either when both from and to available
              // or both are empty
              dates: {
                from: from ? from.format('YYYY-MM-DD') : null,
                to: to ? to.format('YYYY-MM-DD') : null,
              },
              search,
              page,
              limit,
            };
            setRequestDataComplete(false);
            const results = await prototypeApi.requestPrototypes(paramData);
            setData(results.data);
            setRequestDataComplete(true);
          } catch (e) {
            if (e.response && e.response.status) {
              setRequestDataComplete(false);
            }
          }
        },
        600,
        { leading: false, trailing: true },
      );
      getPrototypesThrottled.current(args);
    }
  };

  useEffect(() => {
    getPrototypes(params);
    isMounted.current = true;
    return () => {
      isMounted.current = false;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params]);

  useEffect(() => {
    params.page = 1;
    getPrototypes(params);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params.dates, params.search]);

  /* eslint-disable @typescript-eslint/camelcase */
  const handlePageEdit = ({
    _id,
    name,
    created_at,
  }: {
    _id: number;
    name: string;
    created_at: Date;
  }) => {
    history.push(`/moodgen/create-page/${_id}/${name}`, {
      created_at,
    });
  };
  /* eslint-enable */

  if (requestDataComplete && data.data.length > 0) {
    totalCount = data.count;
    prototypeList = data.data.map((item: any) => {
      return (
        <PrototypeGridItem
          itemKey={item._id} // eslint-disable-line no-underscore-dangle
          item={item}
          onPageEdit={handlePageEdit}
        />
      );
    });
  } else if (
    ((params.search && params.search.length > 0) ||
      params.dates.to ||
      params.dates.from) &&
    requestDataComplete &&
    data.data.length === 0
  ) {
    prototypeList = (
      <span className="mx-auto py-3">
        Sorry, Your filter produced no results!
      </span>
    );
  }

  const pageCount = totalCount / params.limit;

  const onPageChange = useCallback(
    ({ selected }) => {
      const newParams = { ...params, page: selected + 1 };

      setParams(newParams);
      getPrototypes(newParams);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [params],
  );

  const onPageSelect = useCallback(
    ({ target }) => {
      const newParams = { ...params, page: 1, limit: target.value };
      setParams(newParams);
      getPrototypes(newParams);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [params],
  );

  const handleDatePicker = (
    value: [Date | null | undefined, Date | null | undefined],
  ) => {
    const [from, to] = value;
    setParams({
      ...params,
      dates: {
        from: from ? moment(from) : null,
        to: to ? moment(to) : null,
      },
    });
  };

  useEffect(() => {
    if (deviceWidth > 0) {
      setParams({
        ...params,
        // eslint-disable-next-line @typescript-eslint/camelcase
        limit: deviceWidth > BREAK_POINT_4k ? PAGE_LIMIT_4K : PAGE_LIMIT,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deviceWidth]);

  return (
    <div className="ar-common-content">
      <div className="ar-common-search-panel-wrapper">
        <Row>
          <Col>
            <div className="form ar-common-form ar-common-search-panel">
              <div className="ar-common-form__group">
                <div className="mr-4">
                  <FormGroup className="ar-common-form-search">
                    <Input
                      type="text"
                      className="search form-control ar-common-form__input"
                      id="search"
                      placeholder="Search project"
                      onChange={(
                        e: React.ChangeEvent<HTMLInputElement>,
                      ): void =>
                        setParams({
                          ...params,
                          page: 1,
                          search: e.target.value,
                        })
                      }
                    />
                  </FormGroup>
                </div>
              </div>
              <div className="mr-4">
                <FormGroup>
                  <Label>From</Label>
                  <DatePicker
                    className="form-control"
                    showYearDropdown
                    selected={params.dates.from?.toDate()}
                    maxDate={params.dates.to?.toDate()}
                    onKeyDown={(e) => e.preventDefault()}
                    dateFormat="yyyy-MM-dd"
                    onChange={(val) =>
                      handleDatePicker([val as Date, params.dates.to?.toDate()])
                    }
                    name="fromDatePicker"
                    isClearable
                    placeholderText="From date"
                    popperPlacement="bottom"
                  />
                </FormGroup>
              </div>
              <div className="mr-2">
                <FormGroup>
                  <Label>To</Label>
                  <DatePicker
                    className="form-control"
                    showYearDropdown
                    selected={params.dates.to?.toDate()}
                    minDate={params.dates.from?.toDate()}
                    onKeyDown={(e) => e.preventDefault()}
                    dateFormat="yyyy-MM-dd"
                    onChange={(val) =>
                      handleDatePicker([
                        params.dates.from?.toDate(),
                        val as Date,
                      ])
                    }
                    name="toDatePicker"
                    isClearable
                    placeholderText="To date"
                    popperPlacement="bottom"
                  />
                </FormGroup>
              </div>
            </div>
          </Col>
        </Row>
      </div>
      <Row>
        <Col>
          {requestDataComplete === false ? (
            <div style={{ minHeight: 250 }}>
              <div className="loading-indicator" />
            </div>
          ) : (
            <div className="ar-common-content__inner">
              <div className="ar-common-posts">
                <div
                  className={`ar-common-posts__list ${
                    data.data.length === 0 ? 'd-block' : ''
                  }`}
                >
                  {prototypeList}
                </div>
              </div>
            </div>
          )}
        </Col>
      </Row>
      <Row xs="1" sm="1" md="1" className="title-wrapper">
        <Col className="main-title">
          {requestDataComplete && deviceWidth > 0 && (
            <Pagination
              count={totalCount}
              pageCount={pageCount}
              onPageChange={onPageChange}
              onPageSelect={onPageSelect}
              pageLimit={params.limit}
              currentPage={params.page}
            />
          )}
        </Col>
      </Row>
    </div>
  );
};
export default MoodboardConfig;
