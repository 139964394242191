/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';

import Input from '../FormFields/Input';
import Select from '../FormFields/Select';
import File from '../FormFields/File';
import DatePicker from './DatePicker';
import CheckBox from '../FormFields/CheckBox';
import ToggleButton from './ToggleButton';
import Textarea from '../FormFields/Textarea';

const FormikControl: React.FC<any> = (props) => {
  const { control, ...rest } = props;
  switch (control) {
    case 'Input':
      return <Input {...rest} />;
    case 'Select':
      return <Select {...rest} />;
    case 'File':
      return <File {...rest} />;
    case 'DatePicker':
      return <DatePicker {...rest} />;
    case 'CheckBox':
      return <CheckBox {...rest} />;
    case 'ToggleButton':
      return <ToggleButton {...rest} />;
    case 'Textarea':
      return <Textarea {...rest} />;
    default:
      return null;
  }
};

export default FormikControl;
