import React, { useRef, useEffect } from 'react';
import { Button, UncontrolledTooltip } from 'reactstrap';
import defaultPrototypeCover from '../../../assets/images/default-component.png';
import { DOWNLOAD_TYPES } from '../../../constants';
import { getSanitizedS3BucketUrl } from '../../../helpers';

const PrototypeGridItem = (props: {
  item: {
    image: string;
    name: string;
    generated: number;
    frd_doc_link: string;
    sketch_file_link: string;
    user_guide_link: string;
    test_case_link: string;
  };
  onPageEdit: Function;
  itemKey: string;
  // isGenerating: boolean
}) => {
  const {
    item,
    item: {
      image,
      name,
      generated,
      frd_doc_link: frdDocLink,
      sketch_file_link: sketchFileUrl,
      test_case_link: testCaseLink,
    },
    onPageEdit,
    itemKey,
  } = props;

  const isMounted = useRef(false);

  useEffect(() => {
    isMounted.current = true;
    return () => {
      isMounted.current = false;
    };
  }, []);

  const availableDownloadOptions: any = {};

  availableDownloadOptions.WF_SKETCH = `${DOWNLOAD_TYPES.WF_SKETCH} - ${
    sketchFileUrl && generated ? 'Download' : 'Generate'
  }`;
  availableDownloadOptions.FRD = `${DOWNLOAD_TYPES.FRD} - ${
    frdDocLink && generated ? 'Download' : 'Generate'
  }`;
  // 22 feb 2023, Tharindu Rupasinghe , Removed the option upon chathura's request until google app script issues beign fixed.
  // availableDownloadOptions.USER_GUIDE = `${DOWNLOAD_TYPES.USER_GUIDE} - ${
  //   userGuideLink && generated ? 'Download' : 'Generate'
  // }`;
  availableDownloadOptions.TEST_CASE_DOC = `${DOWNLOAD_TYPES.TEST_CASE_DOC} - ${
    testCaseLink && generated ? 'Download' : 'Generate'
  }`;

  return (
    <div className="ar-common-post ar-common-post--download">
      <div className="ar-common-post__image image-container">
        {image ? (
          <img
            src={`${getSanitizedS3BucketUrl()}/${image}`}
            alt="default component"
            className="cover"
          />
        ) : (
          <img
            src={defaultPrototypeCover}
            alt="default component"
            className="cover"
          />
        )}
      </div>
      <div className="ar-common-post__container">
        <div className="ar-common-post__title">{name}</div>
        <div className="ar-common-post__action">
          <Button
            id={`view-pages-button-${itemKey}`}
            className="ar-btn ar-btn--primary-page-edit"
            color="link"
            tabIndex={0}
            onKeyDown={() => onPageEdit(item)}
            onClick={() => onPageEdit(item)}
          />
          <UncontrolledTooltip
            placement="bottom"
            target={`view-pages-button-${itemKey}`}
          >
            View Pages
          </UncontrolledTooltip>
        </div>
      </div>
    </div>
  );
};

export default PrototypeGridItem;
