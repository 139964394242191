import axiosApi from './axiosApi';

const requestTagLists = async () => {
  return axiosApi.get('components/moodboard-tags');
};
const moodboardTagApi = {
  requestTagLists,
};

export default moodboardTagApi;
