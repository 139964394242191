import React from 'react';
import PropTypes from 'prop-types';
import ReactPaginate from 'react-paginate';

const Pagination: React.FC<any> = ({
  pageCount,
  onPageChange,
  count,
  currentPage,
  pageLimit,
  onPageSelect,
}) => {
  const forcePageObj: { forcePage: number } = {
    forcePage: currentPage - 1,
  };
  if (currentPage === 1) {
    forcePageObj.forcePage = 0;
  }

  return (
    <div className={`ar-common-pagination ${count || 'hide'}`}>
      <div className="ar-common-pagination__page">
        <div className="ar-common-pagination__title">Items Per Page </div>
        <div className="ar-common-pagination__select-page">
          <select
            onChange={onPageSelect}
            className="form-control floating-label-input "
            defaultValue={pageLimit || 12}
          >
            <option value="12">12</option>
            <option value="24">24</option>
            <option value="48">48</option>
            <option value="96">96</option>
          </select>
        </div>
      </div>
      <ReactPaginate
        breakClassName="ar-common-pagination__page-item"
        breakLinkClassName="ar-common-pagination__page-link"
        containerClassName="ar-common-pagination__wrapper"
        pageClassName="ar-common-pagination__page-item"
        pageLinkClassName="ar-common-pagination__page-link"
        previousClassName="ar-common-pagination__page-item"
        previousLinkClassName="ar-common-pagination__page-link"
        nextClassName="ar-common-pagination__page-item"
        nextLinkClassName="ar-common-pagination__page-link"
        activeClassName="ar-common-pagination_page-item--active"
        nextLabel={<span className="next" />}
        previousLabel={<span className="prev" />}
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...forcePageObj}
        pageCount={Math.ceil(pageCount)}
        onPageChange={onPageChange}
        disabledClassName="btn-disabled"
        pageRangeDisplayed={2}
        marginPagesDisplayed={2}
        breakLabel="..."
        initialPage={0}
        disableInitialCallback
        activeLinkClassName="active"
        hrefBuilder={() => null}
      />
    </div>
  );
};

Pagination.propTypes = {
  onPageSelect: PropTypes.func.isRequired,
};

export default Pagination;
