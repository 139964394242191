/* eslint-disable camelcase */
import axiosApi from './axiosApi';

const getUserMeta = async () => {
  return axiosApi.get(`users/get-user-meta`);
};

const regenerateApiToken = async () => {
  return axiosApi.patch('users/regenerate-api-token');
};

const getUsersWithReferenceScreens = async () => {
  return axiosApi.get('users/with-reference-screens');
};

const getUsersWithMoodboardReferences = async () => {
  return axiosApi.get('users/with-moodboard-references');
};

export default {
  getUserMeta,
  regenerateApiToken,
  getUsersWithReferenceScreens,
  getUsersWithMoodboardReferences,
};
