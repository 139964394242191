import React, { useEffect, useState } from 'react';
import { Row, Col } from 'reactstrap';
import { useGoogleLogin, TokenResponse } from '@react-oauth/google';
import { toast } from 'react-toastify';
import authApi from '../../../api/authApi';
import mainBrand from '../../../assets/images/eight25_logo_white.svg';

const Login = () => {
  const [isLoading, setIsLoading] = useState(false);
  useEffect(() => {
    authApi
      .authCheck()
      .then(() => {
        window.location.href = '/components';
      })
      .catch(() => {
        setIsLoading(false);
      });
  }, []);

  const loginSuccess = async (response: TokenResponse) => {
    const results = await authApi.auth({ token: response.access_token });
    if (!results || !results.data?.payload) {
      toast('Authentication failed', { type: 'error' });
      return;
    }
    const {
      email,
      sub: googleId,
      picture: imageUrl,
      name,
    } = results.data.payload;

    const profileObj = {
      email,
      googleId,
      imageUrl,
      name,
      authToken: results.data.token,
      refreshToken: results.data.refreshToken,
    };

    localStorage.setItem('profile', JSON.stringify(profileObj));
    window.location.href = '/components';
  };

  const loginFailed = () => {
    toast('Authentication failed', { type: 'error' });
  };

  const login = useGoogleLogin({
    onSuccess: (codeResponse) => loginSuccess(codeResponse),
    onError: () => loginFailed(),
  });

  return (
    <div className="login-background">
      {isLoading ? (
        <div className="loading-indicator" />
      ) : (
        <div className="login-body-wrapper">
          <Row xs="1" sm="1" md="1" className="main-brand">
            <img src={mainBrand} alt="default component" className="cover" />
          </Row>
          <Row xs="1" sm="1" md="1" className="title">
            <Col className="d-flex align-items-center justify-content-center">
              <div className="main-title">Welcome to ARGO</div>
              <div className="google-login">
                <button
                  type="button"
                  className="google-login__button"
                  onClick={() => login()}
                >
                  Login with Google
                </button>
              </div>
            </Col>
          </Row>
        </div>
      )}
    </div>
  );
};

export default Login;
