import React, { useState } from 'react';
import Sidebar from './Sidebar';
import SettingsPanel from './SettingsPanel';

const Layout = (props: { children: any | null | undefined }) => {
  const { children } = props;
  const profile = localStorage.getItem('profile');

  const [isHovered, setIsHovered] = useState(false);
  const [isMenuWide, setIsMenuWide] = useState(false);

  const menuHover = () => {
    setIsHovered(true);
  };

  const menuLeave = () => {
    setIsHovered(false);
  };

  const toggleMenu = () => {
    setIsMenuWide(!isMenuWide);
    setIsHovered(false);
  };

  const navSize = isHovered ? 'navbar-wide' : '';
  const navStick = isMenuWide ? 'navbar-wide-sticky' : '';

  return (
    <div className="ar-common-page">
      <div className="container-fluid">
        <div className="row">
          <div
            onMouseEnter={menuHover}
            onMouseLeave={menuLeave}
            className={`col-xl-auto col-sidebar p-0 ${navSize} ${navStick}`}
          >
            {profile ? <Sidebar toggleMenu={toggleMenu} /> : null}
          </div>
          <div className="col p-0">
            <div className="ar-common-page__inner" id="main-content-block">
              {children}
            </div>
          </div>
        </div>
      </div>
      <SettingsPanel />
    </div>
  );
};

export default Layout;
