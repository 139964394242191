import React from 'react';
import { Row, Col } from 'reactstrap';

const Forbidden = () => {
  return (
    <div className="forbidden-page">
      <Row className="row d-flex justify-content-center">
        <Col md="6">
          <div className="ar-common-title">Error - 403 Forbidden.</div>
          <p>
            You don&apos;t have permission to access this page. Please{' '}
            <a href="/">login</a> first.
          </p>
        </Col>
      </Row>
    </div>
  );
};

export default Forbidden;
