const SelectLists = {
  tierList: [
    { value: '1', label: '1' },
    { value: '2', label: '2' },
    { value: '3', label: '3' },
  ],
  stageList: [
    { value: 'Verbal', label: 'Verbal' },
    { value: 'Contract Out', label: 'Contract Out' },
    { value: 'Closed Won', label: 'Closed Won' },
    { value: 'Deposit Rcvd', label: 'Deposit Rcvd' },
    { value: 'Disco', label: 'HP WF' },
    { value: 'IP WF', label: 'IP WF' },
    { value: 'HP Design', label: 'HP Design' },
    { value: 'IP Design', label: 'IP Design' },
    { value: 'Dev', label: 'Dev' },
    { value: 'QA', label: 'QA' },
    { value: 'Client Review,', label: 'Client Review,' },
    { value: 'Complete', label: 'Complete' },
  ],
  cmsList: [
    { value: 'Berg', label: 'Berg' },
    { value: 'Realm', label: 'Realm' },
    { value: 'Base Product', label: 'Base Product' },
    { value: 'Default WP', label: 'Default WP' },
    { value: 'App', label: 'App' },
  ],
  uspmList: [
    { value: 'TC', label: 'TC' },
    { value: 'GB', label: 'GB' },
    { value: 'BC', label: 'BC' },
  ],
  slpmList: [
    { value: 'NS', label: 'NS' },
    { value: 'YT', label: 'YT' },
    { value: 'CH', label: 'CH' },
    { value: 'HA', label: 'HA' },
    { value: 'KR', label: 'KR' },
    { value: 'SW', label: 'SW' },
    { value: 'KT', label: 'KT' },
  ],
  baList: [
    { value: 'CR', label: 'CR' },
    { value: 'SS', label: 'SS' },
    { value: 'HW', label: 'HW' },
  ],
};
export default SelectLists;
