/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect, useState } from 'react';
import {
  Row,
  Col,
  Card,
  CardBody,
  Label,
  InputGroup,
  Input,
  Button,
  UncontrolledTooltip,
} from 'reactstrap';
import { toast } from 'react-toastify';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowsRotate, faCopy } from '@fortawesome/free-solid-svg-icons';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import moment from 'moment';
import userApi from '../../../api/userApi';

const SweetAlert = withReactContent(Swal);
const Settings = () => {
  const [userMeta, setUserMeta] = useState<{
    apiToken: {
      token: string;
      validUntil: number;
    };
  }>({
    apiToken: {
      token: '',
      validUntil: 0,
    },
  });
  const [regeneratingToken, setRegeneratingToken] = useState(false);

  const getUserMeta = async () => {
    const userMetaData: any = (await userApi.getUserMeta())?.data;
    setUserMeta({
      ...userMeta,
      ...userMetaData,
    });
  };

  const regenerateToken = async () => {
    SweetAlert.fire({
      title: 'Confirm Action',
      text: 'Are you sure you want to re-generate the API token? This invalidates the existing token and you will lose access to all services that you have used this token with.',
      icon: 'question',
      showConfirmButton: true,
      showCancelButton: true,
      confirmButtonText: 'Yes, Re-generate!',
    }).then(async (result) => {
      if (result.isConfirmed) {
        setRegeneratingToken(true);
        const res = await userApi.regenerateApiToken();
        if (res.data) {
          const userMetaData = res.data;
          setUserMeta({
            ...userMeta,
            ...userMetaData,
          });
        }
        setRegeneratingToken(false);
      }
    });
  };

  const handleTokenCopyToCb = async () => {
    navigator.clipboard.writeText(userMeta.apiToken.token);
    toast('API Token copied to clipboard', {
      type: 'success',
    });
  };

  useEffect(() => {
    getUserMeta();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="ar-common-content">
      <div className="ar-common-search-panel-wrapper">
        <Row xs="1" sm="1" md="1">
          <Col>
            <div className="ar-common-breadcrumb">
              <button type="button" className="ar-common-breadcrumb__link">
                Settings
              </button>
            </div>
          </Col>
        </Row>
      </div>
      <Row>
        <Col xs="12">
          <Card className="ar-common-form ar-common-form--setting">
            <CardBody>
              <h2 className="mb-5">API Settings</h2>
              <h3 className="mr-3 align-self-center">API Token</h3>
              <InputGroup>
                <Input
                  disabled
                  type="text"
                  defaultValue={userMeta?.apiToken?.token}
                  aria-describedby="token-addon"
                  style={{ fontSize: '100%' }}
                />
                <div className="input-group-append" id="token-addon">
                  <Button
                    id="copy-item"
                    type="button"
                    onClick={handleTokenCopyToCb}
                  >
                    <FontAwesomeIcon icon={faCopy} />
                  </Button>
                  <UncontrolledTooltip placement="bottom" target="copy-item">
                    Copy to Clipboard
                  </UncontrolledTooltip>
                  <Button
                    id="regenerate"
                    type="button"
                    color="danger"
                    onClick={regenerateToken}
                    disabled={regeneratingToken}
                  >
                    <FontAwesomeIcon
                      icon={faArrowsRotate}
                      spin={regeneratingToken}
                      title="Regenerate"
                    />
                  </Button>
                  <UncontrolledTooltip placement="bottom" target="regenerate">
                    Regenerate
                  </UncontrolledTooltip>
                </div>
              </InputGroup>
              {userMeta?.apiToken?.validUntil && (
                <small className="text-muted">
                  Valid until:{' '}
                  {moment
                    .unix(userMeta?.apiToken?.validUntil)
                    .format('MM/DD/YYYY HH:mm:ss')}
                </small>
              )}
            </CardBody>
          </Card>
        </Col>
      </Row>
    </div>
  );
};

export default Settings;
