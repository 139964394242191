import React from 'react';
import { Field, ErrorMessage } from 'formik';
import 'react-datepicker/dist/react-datepicker.css';
import DateView from 'reactstrap-date-picker2';
import TextError from './TextError';

const DatePicker: React.FC<any> = (props) => {
  const { label, name, value, ...rest } = props;
  return (
    <div className="form-group ar-common-form__datepicker">
      <label htmlFor={name}>{label}</label>
      <Field className="form-control" name={name}>
        {() => {
          // eslint-disable-next-line react/jsx-props-no-spreading
          return <DateView id={name} value={value} {...rest} />;
        }}
      </Field>
      <ErrorMessage name={name} component={TextError} />
    </div>
  );
};

export default DatePicker;
