import { useState } from 'react';

import { useEventListener, useIsomorphicLayoutEffect } from 'usehooks-ts';

interface WindowSize {
  deviceWidth: number;
  deviceHeight: number;
}

function useWindowSize(): WindowSize {
  const [windowSize, setWindowSize] = useState<WindowSize>({
    deviceWidth: 0,
    deviceHeight: 0,
  });

  const handleSize = () => {
    setWindowSize({
      deviceWidth: window.innerWidth,
      deviceHeight: window.innerHeight,
    });
  };

  useEventListener('resize', handleSize);

  // Set size at the first client-side load
  useIsomorphicLayoutEffect(() => {
    handleSize();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return windowSize;
}

export default useWindowSize;
