import axiosApi from './axiosApi';

const getMoodboardReferencesList = async (
  limit = 10,
  skip = 0,
  /* eslint-disable @typescript-eslint/camelcase */
  component_type_id: string | null = null,
  prototype_id: string | null = null,
  user_id: string | null = null,
  media_type: string | null = null,
  tag_id: string[] | null = null,
  searchKey: string | null = null,
  /* eslint-enable */
) => {
  return axiosApi.get(`moodboard-references`, {
    params: {
      limit,
      skip,
      /* eslint-disable @typescript-eslint/camelcase */
      component_type_id,
      prototype_id,
      user_id,
      media_type,
      tag_id,
      searchKey,
      /* eslint-enable */
    },
  });
};

const deleteById = async (id: string) => {
  return axiosApi.delete(`moodboard-references/${id}`);
};

export default {
  getMoodboardReferencesList,
  deleteById,
};
