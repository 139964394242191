import axiosApi from './axiosApi';

const requestComponentTypeLists = async () => {
  return axiosApi.get('components/moodboard-types');
};
const moodboardComponentTypeApi = {
  requestComponentTypeLists,
};

export default moodboardComponentTypeApi;
