import React, { useState } from 'react';
import { Row, Col, Label } from 'reactstrap';
import Switch from 'react-switch';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faImage } from '@fortawesome/free-solid-svg-icons';
import { faSketch } from '@fortawesome/free-brands-svg-icons';
import {
  get as getLsValue,
  set as setLsValue,
} from '../../helpers/local-storage';
import { setPreferredTheme } from '../../helpers';

const SettingsPanel = () => {
  const [theme, setTheme] = useState(getLsValue('preferred-theme', 'auto'));
  const [showDesigns, setShowDesigns] = useState(
    getLsValue('theme-setting-show-designs', false),
  );

  const handlePreferredThemeChange = (event: any, themeSelection: string) => {
    if (event.target.checked) {
      setTheme(themeSelection);
      setLsValue('preferred-theme', themeSelection);
      setPreferredTheme(themeSelection);
    }
  };

  const handleComponentPresentationChange = (isDesignViewSelected: boolean) => {
    setShowDesigns(isDesignViewSelected);
    setLsValue('theme-setting-show-designs', isDesignViewSelected);
    window.location.reload();
  };

  const themeOptions = [
    { value: 'auto', label: 'System Default' },
    { value: 'light', label: 'Light' },
    { value: 'dark', label: 'Dark' },
  ];

  return (
    <div className="ar-common-settings-panel">
      <div className="ar-common-settings-panel__handle-default">
        Quick Settings
      </div>
      <div className="ar-common-settings-panel__container-default">
        <Row noGutters>
          <Col xs="12" md="12">
            <Label className="d-flex lh-1">
              <span className="align-self-center lh-1">
                Component Presentation
              </span>
              <Switch
                onChange={handleComponentPresentationChange}
                checked={showDesigns}
                onColor="#345B8B"
                width={40}
                height={18}
                uncheckedIcon={
                  <FontAwesomeIcon
                    icon={faSketch}
                    className="ml-2 text-light align-middle"
                    title="Wireframe"
                  />
                }
                checkedIcon={
                  <FontAwesomeIcon
                    icon={faImage}
                    className="ml-2 text-light align-middle"
                    title="Design"
                  />
                }
              />
            </Label>
          </Col>
        </Row>
        <Row noGutters>
          <Col xs="12" md="12" className="border rounded p-2">
            <Label className="mb-0">Theme</Label>
            {themeOptions.map((themeOption, index) => (
              <div
                className="ar-common-settings-panel__form-check"
                key={themeOption.value}
              >
                <input
                  className="form-check-input"
                  type="radio"
                  name="themeOption"
                  id={`theme-option-${index + 1}`}
                  value={themeOption.value}
                  onClick={(event) =>
                    handlePreferredThemeChange(event, themeOption.value)
                  }
                  defaultChecked={theme === themeOption.value}
                />
                <label
                  className="ar-common-settings-panel__form-check-label"
                  htmlFor={`theme-option-${index + 1}`}
                >
                  {themeOption.label}
                </label>
              </div>
            ))}
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default SettingsPanel;
