import React from 'react';

const PageLoading = (props: any) => {
  const { loadingText } = props;
  return (
    <div className="ar-page-load">
      <span className="ar-page-load__text">{loadingText}</span>
      <div className="loading-indicator" />
    </div>
  );
};

export default PageLoading;
