/* eslint-disable no-underscore-dangle */

import React, { useState, useEffect, Fragment, useCallback } from 'react';
import {
  Row,
  Col,
  Modal,
  ModalBody,
  UncontrolledTooltip,
  Button,
} from 'reactstrap';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import { ReactSortable } from 'react-sortablejs';
import { toast } from 'react-toastify';
import { capitalize } from 'lodash';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faChevronRight,
  faArrowUp,
  faArrowDown,
  faTrash,
  faPlus,
} from '@fortawesome/free-solid-svg-icons';
import NavigationPrompt from 'react-router-navigation-prompt';
import defaultComponentCover from '../../../assets/images/default-component.png';
import prototypeApi from '../../../api/prototypeApi';
import { get as getLsValue } from '../../../helpers/local-storage';
import { getSanitizedS3BucketUrl } from '../../../helpers';
import MoodboardPopup from '../components/moodboardPopup';
import FloatBtnGroup from '../../../components/common/FloatBtnGroup';
import FloatBtn from '../../../components/common/FloatBtn';
import AddSectionsPopup from '../components/addSectionsPopup';
import PrototypePreview from '../components/prototypePreview';
import Alert from '../../../components/common/Alert';

const SweetAlert = withReactContent(Swal);
export interface ItemType {
  id: string;
  _id: string;
  wireFrameImage: string;
  wire_frame_image: string;
  designImage: string;
  design_image: string;
  page?: string;
  project?: string;
  isInRealm: boolean;
  pageComponentId: string;
}

const EditPage = (props: {
  match: {
    params: { prototypeId: string; pageId: string };
  };
  history: any;
  location: {
    prototypeName: any;
    pageName: any;
    state: {
      prototypeName: string;
      pageName: string;
      checkedValue: any;
    };
  };
}) => {
  const {
    match: {
      params: { prototypeId, pageId: prototypePageId },
    },
    location,
    history,
  } = props;

  const [items, setItems] = useState<ItemType[]>([]);
  const [addedComponentList, setAddedComponentList] = useState<ItemType[]>([]);
  const [prototypeName, setPrototypeName] = useState<string>('');
  const [pageName, setPageName] = useState<string>('');
  const [selectedItem, setSelectedItem] = useState<string>('');
  const [isOpen, setIsOpen] = useState(false);
  const [emptyPage, setEmptyPage] = useState<boolean>(false);
  const [isFormUpdated, setIsFormUpdated] = useState<boolean>(false);
  const [isLandingPageUpdated, setIsLandingPageUpdated] =
    useState<boolean>(false);
  const [scrollIndex, setScrollIndex] = useState<number>(-1);
  const [addSectionIndex, setAddSectionIndex] = useState<number>(-1);

  const toggle = () => {
    setIsOpen(!isOpen);
    setIsFormUpdated(false);
    setAddedComponentList(items);
    setScrollIndex(-1);
  };

  const getPageComponent = async (prototype: string, pageToGet: string) => {
    try {
      const results = await prototypeApi.getPageComponent(prototype, pageToGet);

      if (results.data.data && results.data.data.length > 0) {
        const newPageComponent = results.data.data.map((item: any) => {
          const {
            component_id: componentId,
            wireFrameImage,
            designImage,
            page,
            project,
            is_in_realm: isInRealm,
            page_component_id: pageComponentId,
            project_id: projectId,
          } = item;
          return {
            _id: componentId,
            id: componentId,
            wireFrameImage,
            designImage,
            page,
            project,
            isInRealm,
            pageComponentId,
            projectId,
          };
        });
        setItems(newPageComponent);
        setAddedComponentList(newPageComponent);
      }
    } catch (e) {
      if (e.response && e.response.status) {
        // eslint-disable-next-line no-console
        console.error('component get error', e.response);
      }
    } finally {
      setEmptyPage(true);
    }
  };

  useEffect(() => {
    if (location && location.state) {
      if (Array.isArray(location.state.checkedValue)) {
        setItems(location.state.checkedValue);
      } else {
        getPageComponent(prototypeId, prototypePageId);
      }
      if (location.state.prototypeName) {
        setPrototypeName(location.state.prototypeName);
      }
      if (location && location.state.pageName) {
        setPageName(location.state.pageName);
      }
    }
    if (location && location.prototypeName) {
      setPrototypeName(location.prototypeName);
    }
    if (location && location.pageName) {
      setPageName(location.pageName);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleCancel = () => {
    history.push({
      pathname: `/prototype/create-page/${prototypeId}/${prototypeName}`,
    });
  };

  const saveFunc = async (savedItems: any) => {
    const components = savedItems.map(
      (item: { id: string; _id: string; pageComponentId: string }) => {
        // eslint-disable-next-line @typescript-eslint/camelcase, no-underscore-dangle
        return {
          // eslint-disable-next-line no-underscore-dangle
          componentId: item.id || item._id,
          pageComponentId: item.pageComponentId,
          status: 1,
        };
      },
    );

    const saveResponse = await prototypeApi.savePageComponent({
      /* eslint-disable @typescript-eslint/camelcase */
      page_id: prototypePageId,
      prototype_id: prototypeId,
      components,
      /* eslint-enable */
    });
    return saveResponse;
  };

  const handleSave = async () => {
    const saveResponse = await saveFunc(items);
    setIsLandingPageUpdated(false);

    toast('Your changes have been saved successfully', {
      type: 'success',
      autoClose: 5000,
    });
    if (saveResponse.data.status) {
      history.push({
        pathname: `/prototype/create-page/${prototypeId}/${prototypeName}`,
      });
    }
  };

  const handleRemove = (itemIndex: number) => {
    SweetAlert.fire({
      title: 'Confirm Action',
      text: 'Are you sure you want to remove this section from page?',
      icon: 'question',
      showConfirmButton: true,
      showCancelButton: true,
      confirmButtonText: 'Yes, remove it!',
    }).then((result) => {
      if (result.isConfirmed) {
        const itemsCopy = [...items];
        itemsCopy.splice(itemIndex, 1);
        setItems(itemsCopy);
        setIsLandingPageUpdated(true);
      }
    });
  };

  const handleDuplicate = (item: any) => {
    const {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      pageComponentId,
      ...itemWithoutPageComponentID
    } = item;
    const existingItems = [...items];
    const index = existingItems.indexOf(item);
    existingItems.splice(index + 1, 0, itemWithoutPageComponentID);
    setItems(existingItems);
    setIsLandingPageUpdated(true);
    toast('The section has been duplicated', {
      type: 'success',
      autoClose: 5000,
    });
  };

  const handleMoveUp = (item: any) => {
    const index = items.indexOf(item);
    if (index > 0) {
      const existingItems = [...items];
      existingItems.splice(index, 1);
      existingItems.splice(index - 1, 0, item);
      setItems(existingItems);
      setIsLandingPageUpdated(true);
      return true;
    }
    return false;
  };

  const handleMoveDown = (item: any) => {
    const index = items.indexOf(item);
    if (index < items.length - 1) {
      const existingItems = [...items];
      existingItems.splice(index, 1);
      existingItems.splice(index + 1, 0, item);
      setItems(existingItems);
      setIsLandingPageUpdated(true);
      return true;
    }
    return false;
  };

  const s3BucketUrl = getSanitizedS3BucketUrl();
  const formatImageUrl = (url: string) =>
    url && url[0] === '/' ? url.substring(1, url.length) : url;

  const presentationModeIsDesign = getLsValue(
    'theme-setting-show-designs',
    false,
  );

  const saveData = async () => {
    // eslint-disable-next-line no-console
    setItems(addedComponentList);
    setIsFormUpdated(false);
    await saveFunc(addedComponentList);

    toast('Your changes have been saved successfully', {
      type: 'success',
      autoClose: 5000,
    });
  };

  const onItemSelect = useCallback(
    ({ selectedComponent, event }) => {
      if (event.target.checked) {
        const existingItems = [...addedComponentList];

        if (addSectionIndex === -1) {
          setScrollIndex(existingItems.length - 1);
          existingItems.splice(existingItems.length + 1, 0, selectedComponent);
        } else {
          setScrollIndex(addSectionIndex);
          existingItems.splice(addSectionIndex + 1, 0, selectedComponent);
        }
        setAddedComponentList(existingItems);
      } else {
        const currentIndex = addedComponentList.findIndex(
          // eslint-disable-next-line no-underscore-dangle
          (x) => x.id === selectedComponent._id,
        );

        const newList = addedComponentList.filter(
          (listItem: { id: any; _id: any }) => {
            return (
              // eslint-disable-next-line no-underscore-dangle
              listItem.id !== selectedComponent._id &&
              // eslint-disable-next-line no-underscore-dangle
              listItem._id !== selectedComponent._id
            );
          },
        );
        setAddedComponentList(newList);
        setScrollIndex(currentIndex - 2);
      }
      setIsFormUpdated(true);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [setAddedComponentList, addedComponentList],
  );

  return (
    <div className="ar-common-content">
      <div className="ar-edit-page">
        <Row xs="1" sm="1" md="1">
          <Col className="sticky-top">
            <div className="ar-common-breadcrumb">
              <Link
                to={{
                  pathname: '/prototypes',
                }}
              >
                <button type="button" className="ar-common-breadcrumb__link">
                  Prototype
                </button>
              </Link>

              <FontAwesomeIcon icon={faChevronRight} />
              <Link
                to={{
                  pathname: `/prototype/create-page/${prototypeId}/${location.state.prototypeName}`,
                }}
              >
                <button type="button" className="ar-common-breadcrumb__link">
                  Edit page ({location.state.prototypeName})
                </button>
              </Link>
              <FontAwesomeIcon icon={faChevronRight} />
              <span className="ar-common-breadcrumb__page">
                Page Builder {capitalize(pageName)}
              </span>
            </div>
          </Col>
          <Col>
            <div className="ar-common-title">
              {' '}
              Page Builder : {capitalize(pageName)}
            </div>
            <div>
              <FloatBtnGroup>
                <FloatBtn
                  variant="cancel"
                  onClickHandler={handleCancel}
                  title="Back"
                  type="button"
                />
                <FloatBtn
                  type="submit"
                  variant="update"
                  onClickHandler={handleSave}
                  title="Save"
                  disabled={!isLandingPageUpdated}
                />
                <FloatBtn
                  variant="create"
                  type="button"
                  onClickHandler={() => {
                    setAddSectionIndex(-1);
                    toggle();
                  }}
                  title="Manage Section"
                />
              </FloatBtnGroup>
              {items.length > 0 && (
                <div className="ar-edit-page__body">
                  <ReactSortable
                    animation={600}
                    ghostClass="ar-sortable__drag-area"
                    list={items}
                    setList={setItems}
                    onChange={() => setIsLandingPageUpdated(true)}
                  >
                    {items.map((item, itemIndex) => (
                      <Fragment key={item.pageComponentId}>
                        <div
                          className="ar-edit-page__wrapper"
                          key={item.pageComponentId}
                        >
                          {item.isInRealm ? (
                            <span className="badge badge-pill badge-success realm-badge">
                              Realm
                            </span>
                          ) : null}
                          <div className="ar-edit-page__details">
                            <div className="ar-edit-page__project">
                              <span>{item.project}</span>
                              <span>{item.page}</span>
                            </div>
                            <div className="ar-edit-page__actions text-right">
                              <button
                                id={`duplicate-button-${item.id}`}
                                type="button"
                                className="ar-btn ar-btn--secondary ar-btn--secondary--duplicate ar-btn--icon-rounded"
                                // eslint-disable-next-line no-underscore-dangle
                                onClick={() => handleDuplicate(item)}
                              >
                                {/* <FontAwesomeIcon icon={faClone} /> */}
                              </button>
                              <UncontrolledTooltip
                                placement="bottom"
                                target={`duplicate-button-${item.id}`}
                              >
                                Duplicate Component
                              </UncontrolledTooltip>
                              <button
                                id={`remove-button-${item.id}`}
                                type="button"
                                className="btn btn-danger rounded-circle"
                                // eslint-disable-next-line no-underscore-dangle
                                onClick={() => handleRemove(itemIndex)}
                              >
                                <FontAwesomeIcon icon={faTrash} />
                              </button>
                              <UncontrolledTooltip
                                placement="bottom"
                                target={`remove-button-${item.id}`}
                              >
                                Remove Component
                              </UncontrolledTooltip>

                              <div className="ar-edit-page__actions__move d-flex ml-2">
                                <button
                                  id={`move-up-button-${item.id}`}
                                  type="button"
                                  className="ar-btn ar-btn--primary rounded-circle"
                                  // eslint-disable-next-line no-underscore-dangle
                                  onClick={() => handleMoveUp(item)}
                                  disabled={items.indexOf(item) === 0}
                                >
                                  <FontAwesomeIcon icon={faArrowUp} />
                                </button>
                                <UncontrolledTooltip
                                  placement="bottom"
                                  target={`move-up-button-${item.id}`}
                                >
                                  Move Up
                                </UncontrolledTooltip>

                                <button
                                  id={`move-down-button-${item.id}`}
                                  type="button"
                                  className="ar-btn ar-btn--primary rounded-circle"
                                  // eslint-disable-next-line no-underscore-dangle
                                  onClick={() => handleMoveDown(item)}
                                  disabled={
                                    items.indexOf(item) === items.length - 1
                                  }
                                >
                                  <FontAwesomeIcon icon={faArrowDown} />
                                </button>
                                <UncontrolledTooltip
                                  placement="bottom"
                                  target={`move-down-button-${item.id}`}
                                >
                                  Move Down
                                </UncontrolledTooltip>

                                <button
                                  id={`drag-screen-button-${item.id}`}
                                  type="button"
                                  className="ar-btn ar-btn--light ar-btn--light-drag rounded-circle"
                                  aria-label="Drag"
                                />
                                <UncontrolledTooltip
                                  placement="bottom"
                                  target={`drag-screen-button-${item.id}`}
                                >
                                  Drag and Drop
                                </UncontrolledTooltip>
                              </div>
                            </div>
                          </div>
                          {(presentationModeIsDesign &&
                            (item.designImage || item.design_image)) ||
                          (!presentationModeIsDesign &&
                            (item.wireFrameImage || item.wire_frame_image)) ? (
                            <img
                              src={`${s3BucketUrl}/${
                                presentationModeIsDesign
                                  ? formatImageUrl(item.designImage) ||
                                    formatImageUrl(item.design_image)
                                  : formatImageUrl(item.wireFrameImage) ||
                                    formatImageUrl(item.wire_frame_image)
                              }`}
                              alt="default component"
                              className="cover"
                            />
                          ) : (
                            <img
                              src={defaultComponentCover}
                              alt="default component"
                              className="cover"
                            />
                          )}

                          <div className="ar-edit-page__add-after">
                            <Button
                              id={`add-after-button-${item.id}`}
                              type="button"
                              className="ar-btn ar-btn--primary rounded-circle"
                              onClick={() => {
                                setAddSectionIndex(itemIndex);
                                toggle();
                              }}
                            >
                              <FontAwesomeIcon icon={faPlus} />
                            </Button>
                            <UncontrolledTooltip
                              placement="bottom"
                              target={`add-after-button-${item.id}`}
                            >
                              Add Section Here
                            </UncontrolledTooltip>
                          </div>
                        </div>

                        {/* Moodboard references modal */}
                        {/* eslint-disable-next-line no-underscore-dangle */}
                        {selectedItem === (item.id || item._id) && (
                          <div>
                            <MoodboardPopup
                              item={item}
                              prototypeId={prototypeId}
                              setSelectedItem={setSelectedItem}
                              formatImageUrl={formatImageUrl}
                              presentationModeIsDesign={
                                presentationModeIsDesign
                              }
                              s3BucketUrl={s3BucketUrl}
                              defaultComponentCover={defaultComponentCover}
                            />
                          </div>
                        )}
                      </Fragment>
                    ))}
                  </ReactSortable>
                </div>
              )}
            </div>
          </Col>
        </Row>

        <Modal
          className="ar-edit-page__moodboard-popup ar-common-popup"
          isOpen={isOpen}
          toggle={toggle}
          size="xl"
        >
          <ModalBody className="ar-common-popup__body">
            <PrototypePreview
              addedComponentList={addedComponentList}
              setAddedComponentList={setAddedComponentList}
              setIsFormUpdated={setIsFormUpdated}
              scrollIndex={scrollIndex}
              setScrollIndex={setScrollIndex}
            />
            <AddSectionsPopup
              setItems={(e: any) => onItemSelect(e)}
              addedComponentList={addedComponentList}
              saveData={saveData}
              toggle={toggle}
              isFormUpdated={isFormUpdated}
            />
          </ModalBody>
        </Modal>
      </div>
      {items.length === 0 && emptyPage && (
        <div className="ar-common-page__empty">
          <div className="ar-edit-page--empty text-center">
            <h2 className="text-secondary">{capitalize(pageName)}</h2>
            <h3>Let’s start build the page!</h3>
            <p>
              Once you start adding new sections, you will see your page build
              here.
            </p>
            <button
              type="button"
              className="ar-btn ar-btn-outline--light rounded-pill mx-auto"
              onClick={toggle}
            >
              Add new sections
            </button>
          </div>
        </div>
      )}
      {/* eslint-disable @typescript-eslint/no-unused-vars, consistent-return */}
      {isLandingPageUpdated && (
        <NavigationPrompt
          when={(crntLocation, nextLocation, _action) =>
            !nextLocation ||
            !nextLocation.pathname.startsWith(crntLocation.pathname)
          }
        >
          {({ onCancel, onConfirm }) => {
            if (isLandingPageUpdated) {
              return (
                <Alert
                  onConfirm={() => {
                    setIsLandingPageUpdated(false);
                    onConfirm();
                  }}
                  onCancel={onCancel}
                  open
                />
              );
            }

            setIsLandingPageUpdated(false);
            onConfirm();
          }}
        </NavigationPrompt>
      )}
    </div>
  );
};
export default EditPage;
