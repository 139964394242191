import React, { useState, useEffect, useCallback, useRef } from 'react';
import { Row, Col, Input, NavLink, FormGroup, Label } from 'reactstrap';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import DatePicker from 'react-datepicker';
import moment, { Moment } from 'moment';
import { throttle, DebouncedFunc } from 'lodash';
import { toast } from 'react-toastify';
import 'react-datepicker/dist/react-datepicker.css';
import prototypeApi from '../../../api/prototypeApi';
import api from '../../../api/axiosApi';
import {
  PAGE_LIMIT,
  DOWNLOAD_TYPES,
  PAGE_LIMIT_4K,
  // eslint-disable-next-line @typescript-eslint/camelcase
  BREAK_POINT_4k,
} from '../../../constants';
import Pagination from '../../../components/common/Pagination';
import GridItem from '../components/prototypeGridItem';
import useWindowSize from '../../../helpers/useWindowSizeHook';

const SweetAlert = withReactContent(Swal);
const Prototype = (props: any) => {
  const { history } = props;
  let prototypeList: any = null;
  let totalCount = 1;
  const currentDate = moment();

  const isMounted = useRef(false);
  const getPrototypesThrottled = useRef<DebouncedFunc<any> | null>(null);
  const [requestDataComplete, setRequestDataComplete] = useState(false);
  const { deviceWidth } = useWindowSize();
  const [data, setData] = useState({ data: [{}], count: 1 });
  const [params, setParams] = useState<{
    dates: {
      from: Moment | null;
      to: Moment | null;
    };
    search: string | null;
    page: number | 0;
    limit: number | 0;
  }>({
    dates: {
      from: currentDate.clone().subtract(1, 'year'),
      to: currentDate,
    },
    search: '',
    page: 1,
    // eslint-disable-next-line @typescript-eslint/camelcase
    limit: deviceWidth > BREAK_POINT_4k ? PAGE_LIMIT_4K : PAGE_LIMIT,
  });

  const getPrototypes = (args: any) => {
    if (deviceWidth > 0) {
      if (getPrototypesThrottled.current)
        getPrototypesThrottled.current.cancel();
      getPrototypesThrottled.current = throttle(
        async (filterValues: any) => {
          try {
            const {
              search,
              page,
              dates: { from, to },
              limit,
            } = filterValues;
            const paramData: any = {
              // date filter should applied either when both from and to available
              // or both are empty
              dates: {
                from: from ? from.format('YYYY-MM-DD') : null,
                to: to ? to.format('YYYY-MM-DD') : null,
              },
              search,
              page,
              limit,
            };
            setRequestDataComplete(false);
            const results = await prototypeApi.requestPrototypes(paramData);
            setData(results.data);
            setRequestDataComplete(true);
          } catch (e) {
            if (e.response && e.response.status) {
              setRequestDataComplete(false);
            }
          }
        },
        600,
        { leading: false, trailing: true },
      );
      getPrototypesThrottled.current(args);
    }
  };

  useEffect(() => {
    getPrototypes(params);
    isMounted.current = true;
    return () => {
      isMounted.current = false;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params]);

  useEffect(() => {
    params.page = 1;
    getPrototypes(params);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params.dates, params.search]);

  const handleEdit = (id: number) => {
    history.push(`/prototype/create/${id}`);
  };

  /* eslint-disable @typescript-eslint/camelcase */
  const handlePageEdit = ({
    _id,
    name,
    created_at,
  }: {
    _id: number;
    name: string;
    created_at: Date;
  }) => {
    history.push(`/prototype/create-page/${_id}/${name}`, {
      created_at,
    });
  };
  /* eslint-enable */

  const handleDocumentGenerate = (id: string, type: string) => {
    // eslint-disable-next-line no-async-promise-executor
    return new Promise<void>(async (resolve) => {
      const generateFiles = async () => {
        try {
          const response = await prototypeApi.generateResources(id, type);
          if (response) {
            const updatedPrototype = response.data.data;

            let downloadLink = '';

            switch (type) {
              case DOWNLOAD_TYPES.WF_SKETCH_KEY:
                downloadLink = updatedPrototype.sketch_file_link;
                break;
              case DOWNLOAD_TYPES.FRD_KEY:
                downloadLink = updatedPrototype.frd_doc_link;
                break;
              case DOWNLOAD_TYPES.USER_GUIDE_KEY:
                downloadLink = updatedPrototype.user_guide_link;
                break;
              case DOWNLOAD_TYPES.TEST_CASE_DOC_KEY:
                downloadLink = updatedPrototype.test_case_link;
                break;

              default:
                break;
            }
            // eslint-disable-next-line no-unused-expressions
            type === DOWNLOAD_TYPES.WF_SKETCH
              ? (window.location.href = downloadLink)
              : window.open(downloadLink, '_blank');
            if (isMounted.current) {
              getPrototypes(params);
            }
          }
        } catch (error) {
          // eslint-disable-next-line no-console
          console.error(error);
          toast('Sorry, Error occurred while processing request.', {
            type: 'error',
          });
        } finally {
          resolve();
        }
      };

      const profile = localStorage.getItem('profile');

      const userEmail = profile ? JSON.parse(profile).email : '';

      if (type === DOWNLOAD_TYPES.WF_SKETCH_KEY) {
        await generateFiles();
        return;
      }

      const {
        data: { status, auth_url: authUrl },
      } = await api.post('check-google-auth', { email: userEmail });
      if (status === 'OK') {
        await generateFiles();
      } else {
        const googleAuthWindow = window.open(
          authUrl,
          'GOOGLE_AUTH',
          'width=575,height=650',
        );
        if (googleAuthWindow) {
          const googleAuthMessageHandler = async (event: any) => {
            if (event.data === 'GOOGLE_AUTH_SUCCESS') {
              googleAuthWindow.close();
              window.removeEventListener('message', googleAuthMessageHandler);
              await generateFiles();
            }
          };
          window.addEventListener('message', googleAuthMessageHandler, false);
        }
      }
    });
  };

  const handleDelete = async (prototypeId: string) => {
    SweetAlert.fire({
      title: 'Confirm Action',
      text: 'Are you sure you want to delete this Prototype?',
      icon: 'question',
      showConfirmButton: true,
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!',
    }).then(async (result) => {
      if (result.isConfirmed) {
        await prototypeApi.removePrototype({ prototypeId });
        const newParams = { ...params, page: 1 };
        setParams(newParams);
        getPrototypes(newParams);
      }
    });
  };

  if (requestDataComplete && data.data.length > 0) {
    totalCount = data.count;
    prototypeList = data.data.map((item: any) => {
      return (
        <GridItem
          itemKey={item._id} // eslint-disable-line no-underscore-dangle
          item={item}
          onDelete={handleDelete}
          onEdit={handleEdit}
          onPageEdit={handlePageEdit}
          onDocumentGenerate={handleDocumentGenerate}
        />
      );
    });
  } else if (
    ((params.search && params.search.length > 0) ||
      params.dates.to ||
      params.dates.from) &&
    requestDataComplete &&
    data.data.length === 0
  ) {
    prototypeList = (
      <span className="mx-auto py-3">
        Sorry, Your filter produced no results!
      </span>
    );
  }

  const pageCount = totalCount / params.limit;

  const onPageChange = useCallback(
    ({ selected }) => {
      const newParams = { ...params, page: selected + 1 };

      setParams(newParams);
      getPrototypes(newParams);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [params],
  );

  const onPageSelect = useCallback(
    ({ target }) => {
      const newParams = { ...params, page: 1, limit: target.value };
      setParams(newParams);
      getPrototypes(newParams);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [params],
  );

  const handleDatePicker = (
    value: [Date | null | undefined, Date | null | undefined],
  ) => {
    const [from, to] = value;
    setParams({
      ...params,
      dates: {
        from: from ? moment(from) : null,
        to: to ? moment(to) : null,
      },
    });
  };

  useEffect(() => {
    if (deviceWidth > 0) {
      setParams({
        ...params,
        // eslint-disable-next-line @typescript-eslint/camelcase
        limit: deviceWidth > BREAK_POINT_4k ? PAGE_LIMIT_4K : PAGE_LIMIT,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deviceWidth]);

  return (
    <div className="ar-common-content">
      <div className="ar-common-search-panel-wrapper">
        <div className="ar-common-component__button">
          <NavLink href="/prototype/create" className="ar-btn ar-btn--primary">
            Create Prototype
          </NavLink>
        </div>
        <Row className="ar-common-component__wrapper">
          <Col>
            <div className="form ar-common-form ar-common-search-panel">
              <div className="ar-common-form__group">
                <div className="mr-4">
                  <FormGroup className="ar-common-form-search">
                    <Input
                      type="text"
                      className="search form-control ar-common-form__input"
                      id="search"
                      placeholder="Search Prototype"
                      onChange={(
                        e: React.ChangeEvent<HTMLInputElement>,
                      ): void =>
                        setParams({
                          ...params,
                          page: 1,
                          search: e.target.value,
                        })
                      }
                    />
                  </FormGroup>
                </div>
              </div>
              <div className="mr-4">
                <FormGroup>
                  <Label>From</Label>
                  <DatePicker
                    className="form-control"
                    showYearDropdown
                    selected={params.dates.from?.toDate()}
                    maxDate={params.dates.to?.toDate()}
                    onKeyDown={(e) => e.preventDefault()}
                    dateFormat="yyyy-MM-dd"
                    onChange={(val) =>
                      handleDatePicker([val as Date, params.dates.to?.toDate()])
                    }
                    name="fromDatePicker"
                    isClearable
                    placeholderText="From date"
                    popperPlacement="bottom"
                  />
                </FormGroup>
              </div>
              <div className="mr-2">
                <FormGroup>
                  <Label>To</Label>
                  <DatePicker
                    className="form-control"
                    showYearDropdown
                    selected={params.dates.to?.toDate()}
                    minDate={params.dates.from?.toDate()}
                    onKeyDown={(e) => e.preventDefault()}
                    dateFormat="yyyy-MM-dd"
                    onChange={(val) =>
                      handleDatePicker([
                        params.dates.from?.toDate(),
                        val as Date,
                      ])
                    }
                    name="toDatePicker"
                    isClearable
                    placeholderText="To date"
                    popperPlacement="bottom"
                  />
                </FormGroup>
              </div>
            </div>
          </Col>
        </Row>
      </div>
      <Row>
        <Col>
          {requestDataComplete === false ? (
            <div style={{ minHeight: 250 }}>
              <div className="loading-indicator" />
            </div>
          ) : (
            <div className="ar-common-content__inner">
              <div className="ar-common-posts">
                <div
                  className={`ar-common-posts__list ${
                    data.data.length === 0 ? 'd-block' : ''
                  }`}
                >
                  {prototypeList}
                </div>
              </div>
            </div>
          )}
        </Col>
      </Row>
      <Row xs="1" sm="1" md="1" className="title-wrapper">
        <Col className="main-title">
          {requestDataComplete && deviceWidth > 0 && (
            <Pagination
              count={totalCount}
              pageCount={pageCount}
              onPageChange={onPageChange}
              onPageSelect={onPageSelect}
              pageLimit={params.limit}
              currentPage={params.page}
            />
          )}
        </Col>
      </Row>
    </div>
  );
};
export default Prototype;
