/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import { Col, Row, Button, UncontrolledTooltip } from 'reactstrap';
import moment from 'moment';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faEdit,
  faSpinner,
  faChevronRight,
} from '@fortawesome/free-solid-svg-icons';
import { faFigma } from '@fortawesome/free-brands-svg-icons';
import { toast } from 'react-toastify';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import prototypeApi from '../../../api/prototypeApi';
import PageLoading from '../../../components/common/pageLoad';
import {
  MOODBOARD_EMAIL_HEADING,
  MOODBOARD_EMAIL_MESSAGE,
} from '../../../constants';

const SweetAlert = withReactContent(Swal);
const CreatePageMoodboard = (props: {
  match: {
    params: { prototypeId: string; prototypeName: string };
  };
  history: {
    goBack: () => void;
    push: Function;
  };
  location: {
    state: {
      created_at: Date | null;
      prototypeName: string;
    };
  };
}) => {
  const [pages, setPages] = useState<
    { _id: string; name: string; page_component_count: number }[]
    // eslint-disable-next-line @typescript-eslint/camelcase
  >([{ _id: '0', name: 'home', page_component_count: 0 }]);
  const [requestDataComplete, setRequestDataComplete] =
    useState<boolean>(false);
  const [savedPages, setSavedPages] = useState<{ _id: string; name: string }[]>(
    [],
  );
  const [requestDataError, setRequestDataError] = useState({
    errCode: null,
    errMessage: '',
  });
  const {
    match: {
      params: { prototypeId, prototypeName },
    },
    history,
    location: { state: locationState },
  } = props;
  const [prototypeCreatedDate, setPrototypeCreatedDate] = useState(
    locationState?.created_at,
  );
  const [pageMoodboardIsGenerating, setPageMoodboardIsGenerating] = useState<
    string | null
  >(null);

  const getPages = async () => {
    try {
      setRequestDataComplete(false);
      const results = await prototypeApi.requestPages({
        prototypeId,
      });
      if (results.data.data && results.data.data.length > 0) {
        setSavedPages(results.data.data);
        setPages(results.data.data);
      }
      setRequestDataComplete(true);
    } catch (e) {
      if (e.response && e.response.status) {
        setRequestDataError({
          errCode: e.response,
          errMessage: 'Something went wrong when getting pages.',
        });
      }
    }
  };

  const getPrototype = async () => {
    const results = await prototypeApi.requestPrototypeById(prototypeId);
    if (results.data?.prototype?.createdAt) {
      setPrototypeCreatedDate(results.data.prototype.createdAt);
    }
  };

  useEffect(() => {
    getPages();
    if (!prototypeCreatedDate) {
      getPrototype();
    }
  }, []);

  const handleInputChange = (
    e: { target: { value: string; name: string; id: string } },
    index: number,
  ) => {
    const { value } = e.target;
    pages[index].name = value;
  };

  const handleEditClick = (
    prototype: string,
    page: string,
    name: string,
    // eslint-disable-next-line @typescript-eslint/camelcase
    page_component_count: number,
  ) => {
    // eslint-disable-next-line @typescript-eslint/camelcase
    if (page_component_count === 0) {
      toast('This page is empty. Please add sections to build the Moodboard', {
        type: 'error',
        autoClose: 4000,
      });
    } else {
      history.push({
        pathname: `/moodgen/edit-page/${prototype}/${page}`,
        state: {
          prototypeName,
          pageName: name,
        },
      });
    }
  };

  const handleMoodboardGenerate = async (pageId: string) => {
    setPageMoodboardIsGenerating(pageId);
    try {
      await prototypeApi.generatePageMoodboard(prototypeId, pageId);
      setPageMoodboardIsGenerating(null);
      SweetAlert.fire({
        title: MOODBOARD_EMAIL_HEADING,
        text: MOODBOARD_EMAIL_MESSAGE,
        icon: 'info',
        showConfirmButton: true,
        showCancelButton: false,
        confirmButtonText: 'Ok',
      });
    } catch (error) {
      if (process.env.NODE_ENV === 'development') {
        // eslint-disable-next-line no-console
        console.error(error);
      }
      toast(error.response.data.message, {
        type: 'error',
        autoClose: 5000,
      });
      setPageMoodboardIsGenerating(null);
    }
  };

  return (
    <div className="ar-common-page__content prototype">
      {pageMoodboardIsGenerating ? (
        <PageLoading loadingText="Generating moodboard. Please wait..." />
      ) : null}
      <Row>
        <Col>
          <div className="ar-common-breadcrumb">
            <button
              className="ar-common-breadcrumb__link"
              type="button"
              onClick={() => {
                history.push('/moodgen');
              }}
            >
              Project
            </button>
            <FontAwesomeIcon icon={faChevronRight} />
            <span className="ar-common-breadcrumb__page">
              View Pages({prototypeName || locationState.prototypeName})
            </span>
          </div>
        </Col>
      </Row>
      <Row>
        <Col>
          <div className="ar-common-title">Project: {prototypeName} </div>
        </Col>
      </Row>
      <div className="ar-common-content--view-pages">
        <Row>
          <Col lg="9">
            {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
            <label>Pages</label>
            {requestDataComplete && pages ? (
              pages.map((page, index) => {
                // eslint-disable-next-line @typescript-eslint/camelcase
                const { _id: id, name, page_component_count } = page;
                return (
                  <Row key={Math.random()}>
                    <Col xs="12" md="8">
                      <div className="form-group">
                        <input
                          readOnly
                          type="text"
                          className="form-control"
                          name={name}
                          id={id}
                          defaultValue={page.name}
                          onChange={(e) => handleInputChange(e, index)}
                        />
                      </div>
                    </Col>
                    <Col xs="3" md="4" className="mb-2">
                      {savedPages.some(
                        (singlePage: { _id: string; name: string }) =>
                          // eslint-disable-next-line no-underscore-dangle
                          singlePage._id === id,
                      ) && (
                        <>
                          <Button
                            id={`generate-moodboard-${index}`}
                            type="button"
                            className="ar-btn ar-btn--ternary ar-btn--icon-rounded mb-2"
                            onClick={() => handleMoodboardGenerate(id)}
                            disabled={pageMoodboardIsGenerating === id}
                          >
                            {pageMoodboardIsGenerating === id ? (
                              <FontAwesomeIcon icon={faSpinner} spin />
                            ) : (
                              <FontAwesomeIcon icon={faFigma} />
                            )}
                          </Button>
                          <UncontrolledTooltip
                            placement="bottom"
                            target={`generate-moodboard-${index}`}
                          >
                            Generate Moodboard
                          </UncontrolledTooltip>
                          <Button
                            id={`edit-button-${index}`}
                            type="button"
                            className="ar-btn ar-btn--ternary ar-btn--icon-rounded mb-2"
                            onClick={() =>
                              handleEditClick(
                                prototypeId,
                                id,
                                name,
                                page_component_count,
                              )
                            }
                          >
                            <FontAwesomeIcon icon={faEdit} />
                          </Button>
                          <UncontrolledTooltip
                            placement="bottom"
                            target={`edit-button-${index}`}
                          >
                            Edit
                          </UncontrolledTooltip>
                        </>
                      )}
                      {/* <div className="prototype__remove">
                        {getRemoveButton(id, index)}
                      </div> */}
                    </Col>
                    <Col xs="12" />
                  </Row>
                );
              })
            ) : (
              <div>
                {requestDataError.errCode
                  ? requestDataError.errMessage
                  : 'Loading...'}
              </div>
            )}
            <Row>
              <Col>
                <div className="form-group">
                  {/* <Button
                    type="button"
                    className="ar-btn ar-btn--ternary"
                    onClick={() => handleAddClick()}
                  >
                    + Add
                  </Button> */}
                </div>
              </Col>
              <Col />
            </Row>
          </Col>
        </Row>
        <Row className="mt-3">
          <Col>
            <span className="d-block my-3">
              <strong>Created on:</strong>&nbsp;
              {prototypeCreatedDate
                ? moment(prototypeCreatedDate).format('MM/DD/yyyy')
                : ''}
            </span>
            <div className="ar-common-page__button-group">
              <Button
                type="button"
                onClick={() => {
                  history.push(`/moodgen`);
                }}
                className="ar-btn ar-btn--ternary"
              >
                Back
              </Button>{' '}
            </div>
          </Col>
        </Row>{' '}
      </div>
    </div>
  );
};

export default CreatePageMoodboard;
