import React, { useEffect, useState } from 'react';
import Lightbox from 'react-image-lightbox';
import { getSanitizedS3BucketUrl } from '../../helpers';
import 'react-image-lightbox/style.css';
import defaultComponentCover from '../../assets/images/default-component.png';

const ImagePopup: React.FC<any> = (props) => {
  const {
    image,
    isInRealm,
    mobileImage,
    additionalImages = [],
    show = false,
    onClose = null,
  } = props;
  const [isOpen, setIsOpen] = useState(false);
  const [photoIndex, setPhotoIndex] = useState(0);
  const [mainImage, setMainImage] = useState(image);

  useEffect(() => {
    setIsOpen(show);
  }, [show]);

  const handleMouseOver = () => {
    if (mobileImage) {
      setMainImage(mobileImage);
    }
  };

  const handleMouseOut = () => {
    if (mobileImage) {
      setMainImage(image);
    }
  };

  const handleOnCloseLightbox = () => {
    setIsOpen(false);
    setPhotoIndex(0);
    if (typeof onClose === 'function') {
      onClose();
    }
  };

  const s3BucketUrl = getSanitizedS3BucketUrl();
  const formatImageUrl = (url: string) =>
    url && url[0] === '/' ? url.substring(1, url.length) : url;
  const imageList = [
    `${s3BucketUrl}/${formatImageUrl(image)}`,
    mobileImage ? `${s3BucketUrl}/${formatImageUrl(mobileImage)}` : '',
    ...additionalImages.map((imgPath: string) =>
      imgPath ? `${s3BucketUrl}/${formatImageUrl(imgPath)}` : '',
    ),
  ].filter((x: string) => x);

  return (
    <div className="image-container">
      {image ? (
        <img
          src={`${s3BucketUrl}/${formatImageUrl(mainImage)}`}
          alt="resource"
          className="cover image-link"
          onClick={() => setIsOpen(true)}
          onMouseOver={() => handleMouseOver()}
          onMouseOut={() => handleMouseOut()}
        />
      ) : (
        <img
          src={defaultComponentCover}
          alt="default component"
          className="cover"
        />
      )}

      {isInRealm ? (
        <span className="badge badge-pill badge-success realm-badge">
          Realm
        </span>
      ) : null}
      {isOpen && (
        <Lightbox
          mainSrc={imageList[photoIndex]}
          onCloseRequest={handleOnCloseLightbox}
          nextSrc={
            photoIndex < imageList.length - 1
              ? imageList[(photoIndex + 1) % imageList.length]
              : undefined
          }
          prevSrc={
            photoIndex !== 0
              ? imageList[
                  (photoIndex + imageList.length - 1) % imageList.length
                ]
              : undefined
          }
          onMovePrevRequest={() =>
            setPhotoIndex((photoIndex - 1) % imageList.length)
          }
          onMoveNextRequest={() =>
            setPhotoIndex((photoIndex + 1) % imageList.length)
          }
        />
      )}
    </div>
  );
};

export default ImagePopup;
