import React, { useState } from 'react';
import moment from 'moment';
import { Button, UncontrolledTooltip } from 'reactstrap';
import ImagePopup from '../../../components/common/imagePopup';
import VideoPopup from '../../../components/common/VideoPopup';

const GridItem = (props: {
  screen: {
    _id: string;
    image_path: string;
    video_path: string;
    video_poster_path: string;
    mediaType: string;
    url: string;
    note: string;
    created_at: string;
    user: {
      _id: string;
      email: string;
      first_name: string;
      last_name: string;
      picture: string;
    };
  };
  onDelete: Function;
  itemKey: string;
}) => {
  const { screen, onDelete: handleDelete, itemKey } = props;
  let note = screen.note || '-';
  const [displayShowMore] = useState(note.length > 50);
  const [showMore, setShowMore] = useState(false);

  const handleShowMore = (event: any) => {
    event.preventDefault();
    setShowMore(!showMore);
  };

  if (note.length > 50) {
    note = showMore ? note : note.substring(0, 50);
  }

  return (
    <div className="ar-common-post">
      {screen.mediaType === 'video' ? (
        <VideoPopup
          posterPath={screen.video_poster_path}
          videoPath={screen.video_path}
        />
      ) : (
        <ImagePopup image={screen.image_path} mobileImage={undefined} />
      )}
      <div className="ar-common-post__container">
        <div className="ar-common-post__cat">
          URL:{' '}
          <a
            className="ar-common-post__link"
            href={screen.url}
            target="_blank"
            rel="noopener noreferrer"
            title={screen.url}
          >
            {screen.url.length > 30
              ? `${screen.url.substring(0, 30)}...`
              : screen.url}
          </a>
        </div>
        <div className="ar-common-post__cat">
          Added By:
          <span>
            {`${screen.user.first_name} ${screen.user.last_name}`} on{' '}
            {moment(screen.created_at).format('MM/DD/YYYY')}
          </span>
        </div>
        <div className="ar-common-post__cat">
          Note:
          <p>
            {`${note} `}
            {displayShowMore && [
              /* eslint-disable-next-line jsx-a11y/anchor-is-valid */
              <a href="#" onClick={handleShowMore}>
                Show {showMore ? 'less' : 'more'}
              </a>,
            ]}
          </p>
        </div>
        <div className="ar-common-post__action">
          <Button
            id={`delete-${itemKey}`}
            className="ar-btn ar-btn--primary-delete"
            color="link"
            /* eslint-disable no-underscore-dangle */
            onKeyDown={() => handleDelete(screen._id)}
            onClick={() => handleDelete(screen._id)}
            /* eslint-enable */
          />
          <UncontrolledTooltip placement="bottom" target={`delete-${itemKey}`}>
            Delete
          </UncontrolledTooltip>
        </div>
      </div>
    </div>
  );
};

export default GridItem;
