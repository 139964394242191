import React, { createContext, useReducer, ReactElement } from 'react';
import commonReducer from '../reducer';

const DEFAULT_COMMON_CONTEXT = {};
// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface CommonContextInterface {}

export const CommonContext = createContext<CommonContextInterface>(
  DEFAULT_COMMON_CONTEXT,
);

export const CommonContextProvider = (props: {
  children: ReactElement;
}): ReactElement => {
  const { children } = props;
  const [commonState, dispatch] = useReducer(
    commonReducer,
    DEFAULT_COMMON_CONTEXT,
  );

  const dispatchAction = (action: any) => {
    dispatch({ type: action.type, payload: action.payload });
  };
  const mainContext = {
    dispatch: dispatchAction,
    commonState,
  };
  return (
    <CommonContext.Provider value={mainContext}>
      {children}
    </CommonContext.Provider>
  );
};
