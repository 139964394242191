/* eslint-disable eqeqeq */
import axios, { AxiosResponse } from 'axios';
import { toast } from 'react-toastify';
import config from '../config';

declare global {
  interface Window {
    isRefreshingToken: boolean;
  }
}

let authToken = '';
let loggedUserProfile = { authToken: null, refreshToken: null };
const requestsToRetryWithRefreshToken: Function[] = [];

const retrieveLoggedUserProfile = () => {
  const encodedProfile = localStorage.getItem('profile');
  if (encodedProfile) {
    try {
      loggedUserProfile = JSON.parse(encodedProfile);
    } catch (error) {
      //
    }
  }
};
retrieveLoggedUserProfile();

authToken = loggedUserProfile.authToken
  ? `Bearer ${loggedUserProfile.authToken}`
  : 'Bearer -';

const axiosApi = axios.create({
  baseURL: `${config.api.BASE_URL}${config.api.API_PREFIX}`,
  headers: {
    'Content-Type': 'application/json',
    Authorization: authToken,
  },
});

const resendRequestsWithRefreshedToken = (refreshedToken: string) => {
  requestsToRetryWithRefreshToken.forEach(
    (callback: Function, index: number) => {
      callback(refreshedToken, index);
    },
  );
};

let isReloading = false;
axiosApi.interceptors.response.use(
  (response: AxiosResponse) => response,
  (error: {
    response:
      | {
          data:
            | { code: number | undefined; message: string | undefined }
            | undefined;
        }
      | undefined;
    config: any;
  }) => {
    if (
      (error?.response?.data?.code == -1 &&
        error?.response?.data?.message == 'Unauthorized') ||
      (error?.response?.data?.code == -2 &&
        error?.response?.data?.message == 'Unauthorized' &&
        !loggedUserProfile.refreshToken)
    ) {
      const returnToLogin = () => {
        localStorage.removeItem('profile');
        if (window.location.pathname !== '/') {
          window.location.href = '/';
        }
      };
      if (!isReloading && window.location.pathname !== '/') {
        isReloading = true;
        // eslint-disable-next-line no-alert
        toast('You have been Logged out, Click here to reload immediately.', {
          type: 'error',
          autoClose: 10000,
          onClose: returnToLogin,
        });
      } else if (!isReloading) {
        returnToLogin();
      }
    } else if (
      error?.response?.data?.code == -2 &&
      error?.response?.data?.message == 'Unauthorized' &&
      loggedUserProfile.refreshToken
    ) {
      // Token expired
      retrieveLoggedUserProfile();
      const isTokenRefreshed =
        `Bearer ${loggedUserProfile.authToken}` !=
        error.config.headers.Authorization;
      if (!window.isRefreshingToken && !isTokenRefreshed) {
        // Token needs to be refreshed
        window.isRefreshingToken = true;
        axiosApi
          .post('/auth/refresh-token', undefined, {
            headers: {
              Authorization: `Bearer ${loggedUserProfile.refreshToken}`,
            },
          })
          .then(({ data: { token, refreshToken } }) => {
            localStorage.setItem(
              'profile',
              JSON.stringify({
                ...loggedUserProfile,
                authToken: token,
                refreshToken,
              }),
            );
            axiosApi.defaults.headers.Authorization = `Bearer ${token}`;
            resendRequestsWithRefreshedToken(token);
          })
          .finally(() => {
            window.isRefreshingToken = false;
          });
      } else if (!window.isRefreshingToken && isTokenRefreshed) {
        // Token refreshed by the previous request
        const axiosObjOfRequest = error.config;
        axiosObjOfRequest.headers.Authorization = `Bearer ${loggedUserProfile.authToken}`;
        return axios(axiosObjOfRequest);
      }
      // Queue request to retry after token refresh
      return new Promise((resolve) => {
        const axiosObjOfRequest = error.config;
        requestsToRetryWithRefreshToken.push(
          (refreshedToken: string, requestObjIndex: number) => {
            axiosObjOfRequest.headers.Authorization = `Bearer ${refreshedToken}`;
            resolve(axios(axiosObjOfRequest));
            delete requestsToRetryWithRefreshToken[requestObjIndex];
          },
        );
      });
    }
    return Promise.reject(error);
  },
);

export default axiosApi;
