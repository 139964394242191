/* eslint-disable camelcase */
import axiosApi from './axiosApi';

const requestProjectLists = async () => {
  return axiosApi.get('projects');
  // return {
  //   data: {
  //     projectLists: [
  //       {
  //         _id: '1',
  //         name: 'test project 001',
  //       },
  //       {
  //         _id: '2',
  //         name: 'test project 002',
  //       },
  //       {
  //         _id: '3',
  //         name: 'test project 003',
  //       },
  //       {
  //         _id: '4',
  //         name: 'test project 004',
  //       },
  //       {
  //         _id: '5',
  //         name: 'test project 005',
  //       },
  //       {
  //         _id: '6',
  //         name: 'test project 006',
  //       },
  //       {
  //         _id: '7',
  //         name: 'test project 008',
  //       },
  //       {
  //         _id: '8',
  //         name: 'test project 009',
  //       },
  //     ],
  //   },
  // };
  // return axiosApi.get(`${apiPrefix}/project`);
};

const getProjectComponents = async (projectId: string, pageId: string) => {
  return axiosApi.get(`projects/${projectId}/pages/${pageId}`);
};

const projectApi = {
  requestProjectLists,
  getProjectComponents,
};

export default projectApi;
