const DOWNLOAD_TYPES = {
  WF_SKETCH: 'WF Sketch',
  // COMPOSER_JSON: 'Composer JSON',
  FRD: 'FRD',
  USER_GUIDE: 'User Guide',
  TEST_CASE_DOC: 'Test Case Doc',
  // DEV_PLAN: 'Dev Plan',

  WF_SKETCH_KEY: 'sketch_file',
  FRD_KEY: 'frd',
  USER_GUIDE_KEY: 'user_guide',
  TEST_CASE_DOC_KEY: 'test_case',
};

// eslint-disable-next-line import/prefer-default-export
export { DOWNLOAD_TYPES };
