import React, { useEffect } from 'react';

const GoogleAuthSuccessPage: React.FC<any> = () => {
  useEffect(() => {
    window.opener.postMessage('GOOGLE_AUTH_SUCCESS', '*');
  });
  return (
    <>
      <div>Authorization successful. This will get closed automatically.</div>
    </>
  );
};

export default GoogleAuthSuccessPage;
