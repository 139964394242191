/* eslint-disable @typescript-eslint/no-explicit-any, @typescript-eslint/no-unused-vars, no-unused-expressions */
import React, { useState, useEffect, useCallback, useRef } from 'react';
import {
  Row,
  Col,
  Button,
  Input,
  NavLink,
  Nav,
  NavItem,
  Label,
  FormGroup,
  CustomInput,
  TabContent,
  TabPane,
} from 'reactstrap';
import NavigationPrompt from 'react-router-navigation-prompt';
import { throttle, DebouncedFunc } from 'lodash';
import Select from 'react-select';
import { toast } from 'react-toastify';
import { get as getLsValue } from '../../../helpers/local-storage';
import ImagePopup from '../../../components/common/imagePopup';
import componentApi from '../../../api/componentApi';
import pageApi from '../../../api/pageApi';
import projectApi from '../../../api/projectApi';
import Pagination from '../../../components/common/Pagination';
import defaultComponentCover from '../../../assets/images/default-component.png';
import { getSanitizedS3BucketUrl } from '../../../helpers';
import Alert from '../../../components/common/Alert';
// eslint-disable-next-line @typescript-eslint/camelcase
import { PAGE_LIMIT_4K, PAGE_LIMIT, BREAK_POINT_4k } from '../../../constants';
import useWindowSize from '../../../helpers/useWindowSizeHook';

export interface ItemType {
  _id: string;
  image_path: string;
}

const AddSectionsPopup = (props: any) => {
  const { saveData, setItems, addedComponentList, toggle, isFormUpdated } =
    props;

  let componentList: any = null;
  let pagesList: any = null;
  let pagesComponentList: any = null;
  let projectOptions: any = [];

  const { deviceWidth } = useWindowSize();

  const getComponentsThrottled = useRef<DebouncedFunc<any> | null>(null);
  const getPagesThrottled = useRef<DebouncedFunc<any> | null>(null);

  const [params, setParams] = useState<{
    search: string | null;
    page: number | 0;
    limit: number | 0;
    isInRealm: boolean;
    projects: string[] | null;
    types: string[] | null;
  }>({
    search: null,
    page: 1,
    limit: deviceWidth > 2500 ? PAGE_LIMIT_4K : PAGE_LIMIT,
    isInRealm: false,
    projects: [],
    types: [],
  });

  const [pageParams, setPageParams] = useState<{
    projects: string[] | null;
    search: string | null;
    page: number | 0;
    limit: number | 0;
  }>({
    projects: [],
    search: null,
    page: 1,
    // eslint-disable-next-line @typescript-eslint/camelcase
    limit: deviceWidth > BREAK_POINT_4k ? PAGE_LIMIT_4K : PAGE_LIMIT,
  });
  const [totalCount, setTotalCount] = useState(1);
  const componentCount = totalCount / params.limit;

  const [totalPageCount, setTotalPageCount] = useState(1);
  const pageCount = totalPageCount / pageParams.limit;

  const [componentDataComplete, setComponentDataComplete] = useState(false);
  const [pageDataComplete, setPageDataComplete] = useState(false);
  const [data, setData] = useState({ componentData: [{}], count: 0 });
  const [pageData, setPageData] = useState({ pages: [{}], count: 0 });
  const [pageComponents, setPageComponents] = useState([]);
  const [active, setActive] = useState<string>('1');
  const [showPageComponentList, setShowPageComponentList] = useState(false);
  const [leaveAlertOpen, setLeaveAlertOpen] = useState<boolean>(false);
  const [isPageLoading, setIsPageLoading] = useState<boolean>(true);
  const [projectLists, setProjectLists] = useState([]);
  const [componentTypeLists, setComponentTypeLists] = useState([]);
  const [selectedPageName, setSelectedPageName] = useState('');
  const [selectedProjectName, setSelectedProjectName] = useState('');
  const [requestProjectListsComplete, setRequestProjectListsComplete] =
    useState(false);
  const [
    requestComponentTypeListsComplete,
    setRequestComponentTypeListsComplete,
  ] = useState(false);

  const presentationModeIsDesign = getLsValue(
    'theme-setting-show-designs',
    false,
  );

  const s3BucketUrl = getSanitizedS3BucketUrl();
  const getFullImageUrl = (path: string | undefined) =>
    `${s3BucketUrl}/${
      path && path[0] === '/' ? path.substring(1, path.length) : path
    }`;

  const getComponents = (args: any) => {
    if (getComponentsThrottled.current) getComponentsThrottled.current.cancel();
    getComponentsThrottled.current = throttle(
      async (filterValues: any) => {
        const { types, projects, search, page, limit, isInRealm } =
          filterValues;
        const submitData = {
          projects,
          types,
          search,
          page,
          limit,
          isInRealm,
        };
        try {
          setComponentDataComplete(true);
          setIsPageLoading(true);
          const results = await componentApi.requestComponents(submitData);
          setData(results.data);
          setTotalCount(results.data.count);
          setIsPageLoading(false);
        } catch (e) {
          setComponentDataComplete(false);
          setIsPageLoading(false);
        }
      },
      600,
      { leading: false, trailing: true },
    );
    getComponentsThrottled.current(args);
  };

  const getPages = (args: any) => {
    if (getPagesThrottled.current) getPagesThrottled.current.cancel();
    getPagesThrottled.current = throttle(
      async (filterValues: any) => {
        const { projects, search, page, limit } = filterValues;
        const submitData = {
          projects: projects || null,
          search,
          page,
          limit,
        };

        try {
          setPageDataComplete(true);
          setIsPageLoading(true);
          const results = await pageApi.getPages(submitData);
          setPageData(results.data);
          setTotalPageCount(results.data.count);
          setIsPageLoading(false);
        } catch (e) {
          if (e.response && e.response.status) {
            setPageDataComplete(false);
            setIsPageLoading(false);
          }
        }
      },
      600,
      { leading: false, trailing: true },
    );
    getPagesThrottled.current(args);
  };

  const getPageComponentList = async (selectedPage: any) => {
    try {
      const results = await projectApi.getProjectComponents(
        selectedPage.project_id,
        selectedPage.page_id,
      );
      setPageComponents(results.data?.data || []);
      setShowPageComponentList(true);
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error(e);
      toast('Sorry. Error ocurred while processing request.', {
        type: 'error',
      });
    }
  };

  const getComponentTypeList = async (project: string | null | number) => {
    try {
      setRequestComponentTypeListsComplete(false);
      const results = await componentApi.requestComponentLists(project);
      setComponentTypeLists(results.data.componentData);
      setRequestComponentTypeListsComplete(true);
      getComponents(params);
    } catch (e) {
      if (e.response && e.response.status) {
        setRequestComponentTypeListsComplete(false);
      }
    }
  };

  const getProjectList = async () => {
    try {
      setRequestProjectListsComplete(false);
      const results = await projectApi.requestProjectLists();
      setProjectLists(results.data.projectData);
      setRequestProjectListsComplete(true);
    } catch (e) {
      if (e.response && e.response.status) {
        setRequestProjectListsComplete(false);
      }
    }
  };

  const handleSelectCheckbox = (event: any, item: any) => {
    const selectedComponent = {
      // eslint-disable-next-line no-underscore-dangle
      _id: item._id,
      // eslint-disable-next-line no-underscore-dangle
      id: item._id,
      // eslint-disable-next-line @typescript-eslint/camelcase
      wireFrameImage: item.wire_frame_image,
      // eslint-disable-next-line @typescript-eslint/camelcase
      wire_frame_image: item.wire_frame_image,
      designImage: item.design_image,
      // eslint-disable-next-line @typescript-eslint/camelcase
      design_image: item.design_image,
      page: item.page,
      project: item.project,
      isInRealm: item.is_in_realm,
    };
    setItems({ selectedComponent, event });
  };

  if (
    componentDataComplete &&
    data.componentData &&
    data.componentData.length > 0
  ) {
    componentList = data.componentData.map((item: any) => {
      const {
        _id: id,
        wire_frame_image: wireFrameImage,
        mobile_wire_frame_image: mobileWireFrameImage,
        mobile_design_image: mobileDesignImage,
        page,
        project,
        componentTypes: types,
        is_in_realm: isInRealm,
        design_image: designImage,
      } = item;

      const isChecked = addedComponentList.filter((listItem: { _id: any }) => {
        // eslint-disable-next-line no-underscore-dangle
        return listItem._id === id;
      });

      return (
        <div key={Math.random()} className="ar-common-post">
          <ImagePopup
            image={presentationModeIsDesign ? designImage : wireFrameImage}
            isInRealm={isInRealm}
            mobileImage={
              presentationModeIsDesign
                ? mobileDesignImage
                : mobileWireFrameImage
            }
            additionalImages={
              presentationModeIsDesign
                ? [wireFrameImage, mobileWireFrameImage]
                : [designImage, mobileDesignImage]
            }
          />
          <div className="ar-common-post__container">
            <div className="ar-common-post__title">{project}</div>
            <div className="ar-common-post__cat">
              Page : <span>{page}</span>
            </div>
            <div className="ar-common-post__type">
              Component Type(s):{' '}
              {types && types.length ? (
                <span>
                  {types.map((x: { name: string }) => x.name).join(', ')}
                </span>
              ) : (
                <span> - </span>
              )}
            </div>
            <div className="ar-common-post__checkbox-wrapper">
              <div className="ar-common-checkbox">
                <Input
                  type="checkbox"
                  className="form-control select-check"
                  onChange={(e) => handleSelectCheckbox(e, item)}
                  checked={!!isChecked.length}
                />
                <span />
              </div>
            </div>
          </div>
        </div>
      );
    });
  }

  if (pageDataComplete && pageData.pages && pageData.count > 0) {
    pagesList = pageData.pages.map((item: any) => {
      const {
        project,
        page,
        wire_frame_image: wireFrameImage,
        mobile_wire_frame_image: mobileWireFrameImage,
        mobile_design_image: mobileDesignImage,
        design_image: designImage,
        // eslint-disable-next-line no-underscore-dangle
      } = item._id;
      return (
        <div
          key={Math.random()}
          className="ar-common-post"
          // eslint-disable-next-line no-underscore-dangle
          onClick={() => {
            setSelectedPageName(page);
            setSelectedProjectName(project);
            // eslint-disable-next-line no-underscore-dangle
            getPageComponentList(item._id);
          }}
        >
          <ImagePopup
            image={presentationModeIsDesign ? designImage : wireFrameImage}
            mobileImage={
              presentationModeIsDesign
                ? mobileDesignImage
                : mobileWireFrameImage
            }
            additionalImages={
              presentationModeIsDesign
                ? [wireFrameImage, mobileWireFrameImage]
                : [designImage, mobileDesignImage]
            }
          />
          <div className="ar-common-post__container">
            <div className="ar-common-post__title">{project}</div>
            <div className="ar-common-post__cat">
              Project : <span>{project}</span>
            </div>
            <div className="ar-common-post__type">
              page: <span>{page}</span>
            </div>
          </div>
        </div>
      );
    });
  }
  if (pageComponents && pageComponents.length) {
    pagesComponentList = pageComponents.map((item: any) => {
      const {
        _id: id,
        wire_frame_image: wireFrameImage,
        design_image: designImage,
        componentTypes: types,
      } = item;
      const isChecked = addedComponentList.filter((listItem: { _id: any }) => {
        // eslint-disable-next-line no-underscore-dangle
        return listItem._id === id;
      });

      return (
        <div
          className="ar-edit-page__single-sort-wrapper single"
          key={Math.random()}
        >
          <div className="ar-edit-page__checkbox-wrapper">
            <div className="ar-edit-page__details">
              <div className="ar-edit-page__project">
                {types.map((x: { name: string }) => x.name).join(', ')}
              </div>
            </div>
            <div className="ar-common-checkbox">
              <Input
                type="checkbox"
                className="form-control select-check"
                onChange={(e) => handleSelectCheckbox(e, item)}
                checked={!!isChecked.length}
              />
              <span />
            </div>
          </div>
          {(presentationModeIsDesign && designImage) ||
          (!presentationModeIsDesign && wireFrameImage) ? (
            <img
              src={
                presentationModeIsDesign
                  ? getFullImageUrl(designImage)
                  : getFullImageUrl(wireFrameImage)
              }
              alt="default component"
              className="cover w-100"
            />
          ) : (
            <img
              src={defaultComponentCover}
              alt="default component"
              className="cover w-100"
            />
          )}
        </div>
      );
    });
  }

  useEffect(() => {
    getComponents(params);
    getPages(pageParams);
    getProjectList();
    getComponentTypeList(null);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    getPages(pageParams);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageParams]);

  useEffect(() => {
    getComponents(params);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params]);

  // Page Pagination
  const onPageChange = useCallback(
    ({ selected }) => {
      const newParams = { ...pageParams, page: selected + 1 };

      setPageParams(newParams);
      getPages(newParams);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [pageParams],
  );

  const onPageSelect = useCallback(
    ({ target }) => {
      const newParams = { ...pageParams, page: 1, limit: target.value };
      setPageParams(newParams);
      getPages(newParams);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [pageParams],
  );

  // Component Pagination
  const onComponentPageChange = useCallback(
    ({ selected }) => {
      const newParams = { ...params, page: selected + 1 };

      setParams(newParams);
      getComponents(newParams);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [params],
  );

  const onComponentPageSelect = useCallback(
    ({ target }) => {
      const newParams = { ...params, page: 1, limit: Number(target.value) };
      setParams(newParams);
      getComponents(newParams);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [params],
  );

  if (requestProjectListsComplete && projectLists && projectLists.length > 0) {
    projectOptions = [
      ...projectOptions,
      ...projectLists.map(
        ({ _id: id, name }: { _id: string; name: string }) => ({
          value: id,
          label: name,
        }),
      ),
    ];
  }

  const typeOptionsSelected: { value: string | null; label: string }[] = [
    ...componentTypeLists.map(
      ({ _id: id, name }: { _id: string; name: string }) => ({
        // eslint-disable-next-line no-underscore-dangle
        value: id,
        label: name,
      }),
    ),
  ];

  return (
    <>
      <div className="ar-common-popup__content">
        <Row>
          <div className="row m-0 w-100 sticky-top bg-primary pt-4">
            <Col xs="12" md="8" className="sticky-top">
              <Nav tabs className="ar-common-tab">
                <NavItem className="ar-common-tab__item">
                  <NavLink
                    className={active === '1' ? 'active' : ''}
                    onClick={() => {
                      setActive('1');
                      setShowPageComponentList(false);
                    }}
                    href="#"
                    role="button"
                  >
                    Select Component
                  </NavLink>
                </NavItem>
                <NavItem className="ar-common-tab__item">
                  <NavLink
                    className={active === '2' ? 'active' : ''}
                    onClick={() => {
                      setActive('2');
                      setShowPageComponentList(false);
                    }}
                    href="#"
                    role="button"
                  >
                    Select Page
                  </NavLink>
                </NavItem>
              </Nav>
            </Col>
            <Col xs="12" md="4" className="text-right">
              <p className="ar-common-popup__selected mt-2">
                {addedComponentList.length} Item
                {addedComponentList.length > 1 && <span>s </span>} Selected
              </p>
            </Col>
            {!showPageComponentList && (
              <Col xs="12" md="12">
                <TabContent
                  className="ar-common-tab__content"
                  activeTab={active}
                >
                  <TabPane className="ar-common-tab__pane" tabId="1">
                    <div className="form ar-common-form ar-common-search-panel pl-0 pt-0 w-100">
                      <div className="ar-common-form__group">
                        <FormGroup className="ar-common-form-search">
                          {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
                          <Input
                            type="text"
                            className="search ar-common-form__input"
                            id="componentSearch"
                            placeholder="Search by page name"
                            onChange={(
                              e: React.ChangeEvent<HTMLInputElement>,
                            ): void =>
                              setParams({
                                ...params,
                                page: 1,
                                search: e.target.value,
                              })
                            }
                          />
                        </FormGroup>
                      </div>
                      <div className="ar-common-form__group">
                        <FormGroup>
                          <Label>Projects</Label>
                          <Select
                            isMulti
                            className="ar-common-form__select"
                            classNamePrefix="rctslct"
                            id="project"
                            options={projectOptions}
                            onChange={(selectedProject) => {
                              const selectedOptions: any = [];
                              // eslint-disable-next-line no-unused-expressions
                              selectedProject?.forEach((selectedTagitem) => {
                                selectedOptions.push(selectedTagitem.value);
                              });
                              setParams({
                                ...params,
                                page: 1,
                                projects: selectedOptions || null,
                              });
                            }}
                            isLoading={!requestProjectListsComplete}
                            isDisabled={!requestProjectListsComplete}
                          />
                        </FormGroup>
                      </div>
                      <div className="ar-common-form__group">
                        <FormGroup>
                          <Label>Types</Label>
                          <Select
                            isMulti
                            className="ar-common-form__select"
                            classNamePrefix="rctslct"
                            id="componentType"
                            options={typeOptionsSelected}
                            onChange={(selectedTag) => {
                              const selectedOptions: any = [];
                              // eslint-disable-next-line no-unused-expressions
                              selectedTag?.forEach((selectedTagitem) => {
                                selectedOptions.push(selectedTagitem.value);
                              });
                              setParams({
                                ...params,
                                page: 1,
                                types: selectedOptions || null,
                              });
                            }}
                            isLoading={!requestComponentTypeListsComplete}
                            isDisabled={!requestComponentTypeListsComplete}
                          />
                        </FormGroup>
                      </div>

                      <div className="ar-common-form__group ar-common-form-custom-switch">
                        <FormGroup>
                          <Label>Realm</Label>
                          <CustomInput
                            type="switch"
                            id="isInRealm"
                            onChange={(
                              e: React.ChangeEvent<HTMLInputElement>,
                            ): void =>
                              setParams({
                                ...params,
                                page: 1,
                                isInRealm: e.target.checked,
                              })
                            }
                          />
                        </FormGroup>
                      </div>
                    </div>
                  </TabPane>
                  <TabPane className="ar-common-tab__pane" tabId="2">
                    <div className="form ar-common-form ar-common-search-panel pl-0 pt-0 w-100">
                      <div className="ar-common-form__group">
                        <FormGroup className="ar-common-form-search">
                          {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
                          <Input
                            type="text"
                            className="search ar-common-form__input"
                            id="pageSearch"
                            placeholder="Search by page name"
                            onChange={(
                              e: React.ChangeEvent<HTMLInputElement>,
                            ): void =>
                              setPageParams({
                                ...pageParams,
                                page: 1,
                                search: e.target.value,
                              })
                            }
                          />
                        </FormGroup>
                      </div>
                      <div className="ar-common-form__group">
                        <FormGroup>
                          <Label>Projects</Label>
                          <Select
                            isMulti
                            className="ar-common-form__select"
                            classNamePrefix="rctslct"
                            id="project"
                            options={projectOptions}
                            onChange={(selectedProject) => {
                              const selectedOptions: any = [];
                              // eslint-disable-next-line no-unused-expressions
                              selectedProject?.forEach((selectedTagitem) => {
                                selectedOptions.push(selectedTagitem.value);
                              });
                              setPageParams({
                                ...pageParams,
                                page: 1,
                                projects: selectedOptions || null,
                              });
                            }}
                            isLoading={!requestProjectListsComplete}
                            isDisabled={!requestProjectListsComplete}
                          />
                        </FormGroup>
                      </div>
                    </div>
                  </TabPane>
                </TabContent>
              </Col>
            )}
          </div>
          <Col xs="12" md="12">
            {showPageComponentList && (
              <Row>
                <div className="col-12 ar-common-tab__titles">
                  <span>
                    <strong>Project:</strong> {selectedProjectName}
                  </span>{' '}
                  <span>
                    <strong>Page:</strong> {selectedPageName}
                  </span>
                </div>
                <div className="col-md-8">{pagesComponentList}</div>
              </Row>
            )}
            {!showPageComponentList && (
              <TabContent className="ar-common-tab__content" activeTab={active}>
                <TabPane className="ar-common-tab__pane" tabId="2">
                  {isPageLoading ? (
                    <div style={{ minHeight: 250 }}>
                      <div className="loading-indicator" />
                    </div>
                  ) : (
                    <div className="ar-common-popup__grid">{pagesList}</div>
                  )}
                </TabPane>
                <TabPane className="ar-common-tab__pane" tabId="1">
                  {isPageLoading ? (
                    <div style={{ minHeight: 250 }}>
                      <div className="loading-indicator" />
                    </div>
                  ) : (
                    <div className="ar-common-popup__grid ">
                      {componentList}
                    </div>
                  )}
                </TabPane>
              </TabContent>
            )}
          </Col>
        </Row>
      </div>
      <div className="ar-common-popup__footer">
        <Row>
          {active === '1' ? (
            <Col xs="6" className="d-flex">
              {!isPageLoading && (
                <Pagination
                  count={totalCount}
                  pageCount={componentCount}
                  onPageChange={onComponentPageChange}
                  onPageSelect={onComponentPageSelect}
                  currentPage={params.page}
                  pageLimit={params.limit}
                />
              )}
            </Col>
          ) : (
            <Col xs="6" className="d-flex">
              {!isPageLoading && !showPageComponentList && (
                <Pagination
                  count={totalPageCount}
                  pageCount={pageCount}
                  onPageChange={onPageChange}
                  onPageSelect={onPageSelect}
                  currentPage={pageParams.page}
                  pageLimit={params.limit}
                />
              )}
            </Col>
          )}
          <Col xs="6" className="d-flex justify-content-end align-items-center">
            <Button
              type="button"
              className="ar-btn ar-btn--light ml-3"
              onClick={() => {
                if (showPageComponentList) {
                  setShowPageComponentList(false);
                } else {
                  isFormUpdated ? setLeaveAlertOpen(true) : toggle();
                }
              }}
            >
              Go Back
            </Button>

            <Button
              type="submit"
              className="ar-btn ar-btn--ternary ml-3"
              onClick={() => {
                toggle();
                saveData();
              }}
              disabled={!isFormUpdated}
            >
              Save
            </Button>
          </Col>
        </Row>
      </div>
      {leaveAlertOpen && (
        <Alert
          onConfirm={() => {
            setLeaveAlertOpen(false);
            toggle();
          }}
          onCancel={() => setLeaveAlertOpen(false)}
          open
        />
      )}
      {isFormUpdated && (
        <NavigationPrompt when={isFormUpdated}>
          {() => {
            return (
              <Alert
                onConfirm={() => {
                  setLeaveAlertOpen(false);
                  toggle();
                }}
                open
              />
            );
          }}
        </NavigationPrompt>
      )}
    </>
  );
};

export default AddSectionsPopup;
