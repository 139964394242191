/* eslint-disable react/jsx-no-comment-textnodes */
import React, { useState } from 'react';
import { Link, NavLink as RouterNavLink } from 'react-router-dom';
import { googleLogout } from '@react-oauth/google';
import {
  Collapse,
  Navbar,
  NavbarToggler,
  Nav,
  NavItem,
  NavLink,
} from 'reactstrap';
import profileDefault from '../../assets/images/no-image.png';
import componentIcon from '../../assets/images/icons/component.png';
import prototypeIcon from '../../assets/images/icons/prototype.png';
import moodgenIcon from '../../assets/images/icons/moodgen.png';
import refScreensIcon from '../../assets/images/icons/reference-screen.png';
import moodgenRefIcon from '../../assets/images/icons/moodgen-ref.png';
import settingsIcon from '../../assets/images/icons/settings.png';
import logoutIcon from '../../assets/images/icons/logout.png';
import changeRequestIcon from '../../assets/images/icons/change-req-icon.png';

const Sidebar = ({ toggleMenu }: { toggleMenu: any }) => {
  const [isOpen, setIsOpen] = useState(false);

  const profile = localStorage.getItem('profile');

  const profileImage = profile ? JSON.parse(profile).imageUrl : profileDefault;

  const name = profile ? JSON.parse(profile).name : '';

  const toggle = () => setIsOpen(!isOpen);

  const testPathIsActive = (pathname: string, pathPattern: RegExp) =>
    pathPattern.test(pathname.split('/')[1]);

  const logout = () => {
    googleLogout();
    localStorage.removeItem('profile');
  };

  return (
    <div className="ar-common-sidebar">
      <Navbar expand="ar-common-sidebar__inner">
        {/* eslint-disable-next-line jsx-a11y/control-has-associated-label */}
        <button
          className="ar-common-sidebar__toggle"
          type="button"
          onClick={toggleMenu}
        />
        <NavbarToggler onClick={toggle} />
        <Collapse isOpen={isOpen} navbar>
          <Nav className="ar-common-sidebar__nav" navbar>
            <div className="ar-common-sidebar__profile">
              <img
                src={profileImage}
                alt="profile"
                className="ar-common-sidebar__image"
              />
              <div className="ar-common-sidebar__name">{name}</div>
            </div>
            <div className="divider" />
            <NavItem>
              <RouterNavLink
                to={{
                  pathname: '/components',
                  state: {},
                }}
                className="ar-common-sidebar__nav-link"
                activeClassName="ar-common-sidebar__link-active"
                isActive={(_match, { pathname }) =>
                  testPathIsActive(pathname, /^.*component/i)
                }
              >
                <img src={componentIcon} alt="components" />
                <span>Components</span>
              </RouterNavLink>
            </NavItem>
            <NavItem>
              <RouterNavLink
                to="/prototypes"
                className="ar-common-sidebar__nav-link"
                activeClassName="ar-common-sidebar__link-active"
                isActive={(_match, { pathname }) =>
                  testPathIsActive(pathname, /^.*prototype/i)
                }
              >
                <img src={prototypeIcon} alt="Prototypes" />
                <span>Prototypes</span>
              </RouterNavLink>
            </NavItem>
            {!process.env.REACT_APP_MOODGEN_HIDE && (
              <NavItem>
                <RouterNavLink
                  to="/moodgen"
                  className="ar-common-sidebar__nav-link"
                  activeClassName="ar-common-sidebar__link-active"
                >
                  <img src={moodgenIcon} alt="Moodgen" />
                  <span>Moodgen</span>
                </RouterNavLink>
              </NavItem>
            )}
            <NavItem>
              <RouterNavLink
                to="/reference-screens"
                className="ar-common-sidebar__nav-link"
                activeClassName="ar-common-sidebar__link-active"
                isActive={(_match, { pathname }) =>
                  testPathIsActive(pathname, /^.*reference-screens/i)
                }
              >
                <img src={refScreensIcon} alt="Reference Screens" />
                <span>Reference Screens</span>
              </RouterNavLink>
            </NavItem>
            {!process.env.REACT_APP_MOODGEN_HIDE && (
              <NavItem>
                <RouterNavLink
                  to="/moodgen-references"
                  className="ar-common-sidebar__nav-link"
                  activeClassName="ar-common-sidebar__link-active"
                  isActive={(_match, { pathname }) =>
                    testPathIsActive(pathname, /^.*moodgen-references/i)
                  }
                >
                  <img src={moodgenRefIcon} alt="Moodgen Reference" />
                  <span>Moodgen References</span>
                </RouterNavLink>
              </NavItem>
            )}
            <NavItem>
              <RouterNavLink
                to="/settings"
                className="ar-common-sidebar__nav-link"
                activeClassName="ar-common-sidebar__link-active"
                isActive={(_match, { pathname }) =>
                  testPathIsActive(pathname, /^.*settings/i)
                }
              >
                <img src={settingsIcon} alt="Settings" />
                <span>Settings</span>
              </RouterNavLink>
            </NavItem>
            <NavItem>
              <Link
                to={{
                  pathname:
                    'https://forms.clickup.com/2401051/f/298rv-89344/A7HNK0W1LG153LZSXV',
                }}
                className="ar-common-sidebar__nav-link"
                target="_blank"
              >
                <img src={changeRequestIcon} alt="Change Requests" />
                <span>Support</span>
              </Link>
            </NavItem>
            <NavItem>
              <NavLink href="/" onClick={logout}>
                <img src={logoutIcon} alt="Logout" />
                <span>Logout</span>
              </NavLink>
            </NavItem>
          </Nav>
        </Collapse>
      </Navbar>
    </div>
  );
};

export default Sidebar;
