/* eslint-disable @typescript-eslint/no-explicit-any, @typescript-eslint/no-unused-vars */
import React, { useCallback, useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { Row, Col, Label, FormGroup } from 'reactstrap';
import Select from 'react-select';
import { throttle, DebouncedFunc } from 'lodash';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import userApi from '../../../api/userApi';
import refScreensApi from '../../../api/refScreensApi';
import GridItem from '../components/GridItem';
import Pagination from '../../../components/common/Pagination';
// eslint-disable-next-line @typescript-eslint/camelcase
import { PAGE_LIMIT, PAGE_LIMIT_4K, BREAK_POINT_4k } from '../../../constants';
import useWindowSize from '../../../helpers/useWindowSizeHook';

const SweetAlert = withReactContent(Swal);
let filterThrottle: DebouncedFunc<any>;
const List = () => {
  const { deviceWidth } = useWindowSize();
  const [dataQuery, setDataQuery] = useState<{
    limit: number;
    page: number;
    userId: string | null;
    type: string | null;
  }>({
    // eslint-disable-next-line @typescript-eslint/camelcase
    limit: deviceWidth > BREAK_POINT_4k ? PAGE_LIMIT_4K : PAGE_LIMIT,
    page: 1,
    userId: null,
    type: null,
  });
  const [users, setUsers] = useState<
    {
      _id: string;
      email: string;
      first_name: string;
      last_name: string;
      picture: string;
    }[]
  >([]);
  const [screens, setScreens] = useState<
    {
      _id: string;
      image_path: string;
      video_path: string;
      video_poster_path: string;
      mediaType: string;
      created_at: string;
      url: string;
      note: string;
      user: {
        _id: string;
        email: string;
        first_name: string;
        last_name: string;
        picture: string;
      };
    }[]
  >([]);
  const [totalNumberOfScreens, setTotalNumberOfScreens] = useState(0);
  const [userRequestIsInProgress, setUserRequestIsInProgress] = useState(false);
  const [dataRequestIsInProgress, setDataRequestIsInProgress] = useState(true);

  const getData = useCallback(async () => {
    if (deviceWidth > 0) {
      if (filterThrottle) filterThrottle.cancel();
      filterThrottle = throttle(
        async () => {
          try {
            setDataRequestIsInProgress(true);
            const response = (
              await refScreensApi.getReferenceScreensList(
                dataQuery.limit,
                (dataQuery.page - 1) * dataQuery.limit,
                dataQuery.userId,
                dataQuery.type,
              )
            )?.data;
            const { data, count } = response;
            setScreens(data);
            setTotalNumberOfScreens(count);
            setDataRequestIsInProgress(false);
          } catch (error) {
            toast(
              'Error occurred while fetching data. Please reload to try again.',
              {
                type: 'error',
                autoClose: 10000,
              },
            );
          }
        },
        600,
        { leading: false, trailing: true },
      );
      filterThrottle();
    }
  }, [dataQuery, deviceWidth]);

  const getUsers = async () => {
    setUserRequestIsInProgress(true);
    const userData: {
      _id: string;
      email: string;
      first_name: string;
      last_name: string;
      picture: string;
    }[] = (await userApi.getUsersWithReferenceScreens())?.data?.data || [];
    setUsers(userData);
    setUserRequestIsInProgress(false);
  };

  useEffect(() => {
    getUsers();
    getData();
  }, [getData]);

  useEffect(() => {
    getData();
  }, [dataQuery, getData]);

  const onPageChange = ({ selected: selectedPage }: { selected: number }) => {
    setDataQuery({ ...dataQuery, page: selectedPage + 1 });
  };

  const onLimitChange = ({ target }: { target: HTMLSelectElement }) => {
    setDataQuery({ ...dataQuery, page: 1, limit: parseInt(target.value, 10) });
  };

  const handleItemDelete = async (id: string) => {
    SweetAlert.fire({
      title: 'Confirm Action',
      text: 'Are you sure you want to delete this item? This action is irreversible.',
      icon: 'question',
      showConfirmButton: true,
      showCancelButton: true,
      confirmButtonText: 'Yes, Delete it!',
    }).then(async (result) => {
      if (result.isConfirmed) {
        await refScreensApi.deleteById(id);
        toast('Successfully deleted.', {
          type: 'success',
        });
        getData();
      }
    });
  };

  const items =
    screens.length > 0 ? (
      screens.map((screen) => (
        <GridItem
          // eslint-disable-next-line no-underscore-dangle
          itemKey={screen._id}
          screen={screen}
          onDelete={handleItemDelete}
        />
      ))
    ) : (
      <span className="mx-auto py-3">
        Sorry, Your filter produced no results!
      </span>
    );

  const userOptions: { value: string | null; label: string }[] = [
    { value: null, label: 'All' },
    ...users.map((user) => ({
      // eslint-disable-next-line no-underscore-dangle
      value: user._id,
      label: `${user.first_name} ${user.last_name}`,
    })),
  ];
  const selectedUser = userOptions.find(
    ({ value }) => value === dataQuery.userId,
  );

  const typeOptions: { value: string | null; label: string }[] = [
    { value: null, label: 'All' },
    { value: 'image', label: 'Snapshots' },
    { value: 'video', label: 'Videos' },
  ];
  const selectedType = typeOptions.find(
    ({ value }) => value === dataQuery.type,
  );

  useEffect(() => {
    if (deviceWidth > 0) {
      setDataQuery({
        ...dataQuery,
        // eslint-disable-next-line @typescript-eslint/camelcase
        limit: deviceWidth > BREAK_POINT_4k ? PAGE_LIMIT_4K : PAGE_LIMIT,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deviceWidth]);

  return (
    <div className="ar-common-content">
      <div className="ar-common-search-panel-wrapper">
        <Row>
          <Col>
            <div className="form ar-common-form ar-common-search-panel">
              <div className="ar-common-form__group">
                <FormGroup className="d-block">
                  <Label className="mb-2">Filter by submitted user</Label>
                  <Select
                    classNamePrefix="rctslct"
                    value={selectedUser}
                    options={userOptions}
                    onChange={(selectedOption) =>
                      setDataQuery({
                        ...dataQuery,
                        page: 1,
                        userId: selectedOption?.value || null,
                      })
                    }
                    isLoading={userRequestIsInProgress}
                    isDisabled={userRequestIsInProgress}
                  />
                </FormGroup>
              </div>
              <div className="ar-common-form__group">
                <FormGroup className="d-block">
                  <Label className="mb-2">Filter by type</Label>
                  <Select
                    classNamePrefix="rctslct"
                    value={selectedType}
                    options={typeOptions}
                    onChange={(selectedOption) =>
                      setDataQuery({
                        ...dataQuery,
                        page: 1,
                        type: selectedOption?.value || null,
                      })
                    }
                  />
                </FormGroup>
              </div>
            </div>
          </Col>
        </Row>
      </div>
      <Row>
        <Col>
          {dataRequestIsInProgress ? (
            <div style={{ minHeight: 250 }}>
              <div className="loading-indicator" />
            </div>
          ) : (
            // eslint-disable prettier/prettier
            <div className="ar-common-content__inner">
              <div className="ar-common-posts">
                <div
                  className={`ar-common-posts__list ${
                    screens.length === 0 ? 'd-block' : ''
                  }`}
                >
                  {items}
                </div>
              </div>
            </div>
          )}
          {screens.length > 0 && deviceWidth > 0 && (
            <Pagination
              count={totalNumberOfScreens}
              pageCount={Math.ceil(totalNumberOfScreens / dataQuery.limit)}
              onPageChange={onPageChange}
              onPageSelect={onLimitChange}
              currentPage={dataQuery.page}
              pageLimit={dataQuery.limit}
            />
          )}
        </Col>
      </Row>
    </div>
  );
};

export default List;
