/* eslint-disable @typescript-eslint/no-explicit-any, @typescript-eslint/no-unused-vars */
import React, { useState, useEffect, useCallback, useRef } from 'react';
import {
  Row,
  Col,
  Button,
  Input,
  NavLink,
  Nav,
  NavItem,
  Label,
  FormGroup,
  CustomInput,
  UncontrolledTooltip,
} from 'reactstrap';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import Select from 'react-select';
import { capitalize, DebouncedFunc, throttle } from 'lodash';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronRight } from '@fortawesome/free-solid-svg-icons';
import componentApi from '../../../api/componentApi';
import projectApi from '../../../api/projectApi';
// eslint-disable-next-line @typescript-eslint/camelcase
import { PAGE_LIMIT, PAGE_LIMIT_4K, BREAK_POINT_4k } from '../../../constants';
import Pagination from '../../../components/common/Pagination';
import prototypeApi from '../../../api/prototypeApi';
import ImagePopup from '../../../components/common/imagePopup';
import { get as getLsValue } from '../../../helpers/local-storage';
import FloatBtnGroup from '../../../components/common/FloatBtnGroup';
import FloatBtn from '../../../components/common/FloatBtn';
import useWindowSize from '../../../helpers/useWindowSizeHook';

const SweetAlert = withReactContent(Swal);
const Component = (props: { history: any; match: any; location: any }) => {
  let componentList: any = null;
  let projectOptions: any = [{ value: 'all', label: 'All' }];
  let componentTypeOptions: any = [{ value: 'all', label: 'All' }];
  let totalCount = 1;

  const {
    match: {
      params: { prototypeId },
    },
    location,
    history,
  } = props;

  const getComponentsThrottled = useRef<DebouncedFunc<any> | null>(null);
  const [requestDataComplete, setRequestDataComplete] = useState(false);
  const [isSelectionMode, setIsSelectionMode] = useState(false);
  const [state, setState] = useState<{
    prototypeId: string;
    checkedValue: {
      id: string;
      _id: string;
      url: string;
      page: string;
      project: string;
    }[];
    prototypeName: string;
    prototypePageId: string;
    prototypePageName: string;
  }>({
    prototypeId,
    checkedValue: [{ id: '', _id: '', url: '', page: '', project: '' }],
    prototypeName: '',
    prototypePageId: '',
    prototypePageName: '',
  });
  const { deviceWidth, deviceHeight } = useWindowSize();

  const [data, setData] = useState({ componentData: [{}], count: 1 });
  const [
    requestComponentTypeListsComplete,
    setRequestComponentTypeListsComplete,
  ] = useState(false);
  const [componentTypeLists, setComponentTypeLists] = useState([]);
  const [requestProjectListsComplete, setRequestProjectListsComplete] =
    useState(false);
  const [projectLists, setProjectLists] = useState([]);
  const [params, setParams] = useState<{
    search: string | null;
    page: number | 0;
    limit: number | 0;
    isInRealm: boolean;
    projects: string[] | null;
    types: string[] | null;
  }>(
    location.state?.componentFilters || {
      search: null,
      page: 1,
      // eslint-disable-next-line @typescript-eslint/camelcase
      limit: deviceWidth > BREAK_POINT_4k ? PAGE_LIMIT_4K : PAGE_LIMIT,
      isInRealm: false,
      projects: [],
      types: [],
    },
  );

  const getComponents = (args: any) => {
    if (deviceWidth > 0) {
      if (getComponentsThrottled.current)
        getComponentsThrottled.current.cancel();
      getComponentsThrottled.current = throttle(
        async (filterValues: any) => {
          const { types, projects, search, page, limit, isInRealm } =
            filterValues;
          const submitData = {
            projects,
            types,
            search,
            page,
            limit,
            isInRealm,
          };
          try {
            setRequestDataComplete(false);
            const results = await componentApi.requestComponents(submitData);
            setData(results.data);
            setRequestDataComplete(true);
          } catch (e) {
            if (e.response && e.response.status) {
              setRequestDataComplete(false);
            }
          }
        },
        600,
        { leading: false, trailing: true },
      );
      getComponentsThrottled.current(args);
    }
  };

  const getComponentTypeList = async (project: string | null | number) => {
    try {
      setRequestComponentTypeListsComplete(false);
      const results = await componentApi.requestComponentLists(project);
      setComponentTypeLists(results.data.componentData);
      setRequestComponentTypeListsComplete(true);
      getComponents(params);
    } catch (e) {
      if (e.response && e.response.status) {
        setRequestComponentTypeListsComplete(false);
      }
    }
  };

  const getProjectList = async () => {
    try {
      setRequestProjectListsComplete(false);
      const results = await projectApi.requestProjectLists();
      setProjectLists(results.data.projectData);
      setRequestProjectListsComplete(true);
    } catch (e) {
      if (e.response && e.response.status) {
        setRequestProjectListsComplete(false);
      }
    }
  };

  useEffect(() => {
    getComponents(params);
    getComponentTypeList(null);
    getProjectList();
    const unsubscribe = history.listen((loc: any) => {
      if (loc.pathname !== location.pathname) {
        unsubscribe();
        if (loc.pathname === '/components') {
          window.location.reload();
        }
      }
    });
    if (prototypeId) {
      setIsSelectionMode(true);
      if (location && location.state) {
        setState(location.state);
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    getComponents(params);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params]);

  const handleEdit = (id: string) => {
    history.push(
      {
        pathname: `/component/create/${id}`,
      },
      {
        componentFilters: params,
      },
    );
  };

  const handleDelete = async (componentId: string) => {
    SweetAlert.fire({
      title: 'Confirm Action',
      text: 'Are you sure you want to delete this Component?',
      icon: 'question',
      showConfirmButton: true,
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!',
    }).then(async (result) => {
      if (result.isConfirmed) {
        await prototypeApi.removeComponent({ componentId });
        const newParams = { ...params, page: 1 };

        setParams(newParams);
        getComponents(newParams);
      }
    });
  };

  const handleSelectCheckbox = (event: any, item: any) => {
    const list = [...state.checkedValue];
    const stateList = { ...state };
    if (event.target.checked) {
      list.push(item);
      stateList.checkedValue = list;
      setState(stateList);
    } else {
      const newList = list.filter(({ _id, id }) => {
        // eslint-disable-next-line no-underscore-dangle
        return id !== item._id && _id !== item._id;
      });
      stateList.checkedValue = newList;
      setState(stateList);
    }
  };

  if (
    requestDataComplete &&
    data.componentData &&
    data.componentData.length > 0
  ) {
    totalCount = data.count;
    const presentationModeIsDesign = getLsValue(
      'theme-setting-show-designs',
      false,
    );
    componentList = data.componentData.map((item: any, index: number) => {
      const {
        _id: id,
        wire_frame_image: wireFrameImage,
        mobile_wire_frame_image: mobileWireFrameImage,
        mobile_design_image: mobileDesignImage,
        page,
        project,
        componentTypes: types,
        is_in_realm: isInRealm,
        design_image: designImage,
      } = item;
      return (
        <div key={Math.random()} className="ar-common-post">
          <ImagePopup
            image={presentationModeIsDesign ? designImage : wireFrameImage}
            isInRealm={isInRealm}
            mobileImage={
              presentationModeIsDesign
                ? mobileDesignImage
                : mobileWireFrameImage
            }
            additionalImages={
              presentationModeIsDesign
                ? [wireFrameImage, mobileWireFrameImage]
                : [designImage, mobileDesignImage]
            }
          />
          <div className="ar-common-post__container">
            <div className="ar-common-post__title">{project}</div>
            <div className="ar-common-post__cat">
              Page : <span>{page}</span>
            </div>
            <div className="ar-common-post__type">
              Component Type(s):{' '}
              <span>
                {types.map((x: { name: string }) => x.name).join(', ')}
              </span>
            </div>
            {isSelectionMode ? (
              <div className="ar-common-post__checkbox-wrapper">
                <div className="ar-common-checkbox">
                  <Input
                    type="checkbox"
                    className="form-control select-check"
                    defaultChecked={
                      !!state.checkedValue.some(
                        ({
                          _id,
                          id: valId,
                        }: {
                          _id: string;
                          id: string;
                          url: string;
                        }) =>
                          // eslint-disable-next-line no-underscore-dangle
                          _id === item._id || valId === item._id,
                      )
                    }
                    onChange={(e) => handleSelectCheckbox(e, item)}
                  />
                  <span />
                </div>
              </div>
            ) : (
              <div className="ar-common-post__action">
                <Button
                  id={`edit-button-${index}`}
                  className="ar-btn ar-btn--primary-edit"
                  color="link"
                  onKeyDown={() => handleEdit(id)}
                  onClick={() => handleEdit(id)}
                />
                <UncontrolledTooltip
                  placement="bottom"
                  target={`edit-button-${index}`}
                >
                  Edit
                </UncontrolledTooltip>
                <Button
                  id={`delete-button-${index}`}
                  className="ar-btn ar-btn--primary-delete"
                  color="link"
                  onKeyDown={() => handleDelete(id)}
                  onClick={() => handleDelete(id)}
                />
                <UncontrolledTooltip
                  placement="bottom"
                  target={`delete-button-${index}`}
                >
                  Delete
                </UncontrolledTooltip>
              </div>
            )}
          </div>
        </div>
      );
    });
  } else if (
    requestDataComplete &&
    data.componentData &&
    data.componentData.length === 0
  ) {
    componentList = (
      <span className="mx-auto py-3">
        Sorry, Your filter produced no results!
      </span>
    );
  }

  if (
    requestComponentTypeListsComplete &&
    componentTypeLists &&
    componentTypeLists.length > 0
  ) {
    componentTypeOptions = [
      ...componentTypeOptions,
      ...componentTypeLists.map(
        ({ _id: id, name }: { _id: string; name: string }) => ({
          value: id,
          label: name,
        }),
      ),
    ];
  }

  if (requestProjectListsComplete && projectLists && projectLists.length > 0) {
    projectOptions = [
      ...projectOptions,
      ...projectLists.map(
        ({ _id: id, name }: { _id: string; name: string }) => ({
          value: id,
          label: name,
        }),
      ),
    ];
  }

  const pageCount = totalCount / params.limit;

  const onPageChange = useCallback(
    ({ selected }) => {
      const newParams = { ...params, page: selected + 1 };
      setParams(newParams);
      getComponents(newParams);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [params],
  );

  const onPageSelect = useCallback(
    ({ target }) => {
      const newParams = { ...params, page: 1, limit: target.value };
      setParams(newParams);
      getComponents(newParams);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [params],
  );

  const handlePageLoad = () => {
    // eslint-disable-next-line no-shadow
    const { prototypeId, prototypePageId } = state;
    history.push({
      pathname: `/prototype/page/search/${prototypeId}/${prototypePageId}`,
      state: {
        prototypeId,
        checkedValue: state.checkedValue,
        prototypeName: state.prototypeName,
        prototypePageId,
        prototypePageName: state.prototypePageName,
      },
    });
  };

  const handleBack = () => {
    history.goBack();
  };

  const handleSave = () => {
    // eslint-disable-next-line no-shadow
    const { prototypeId, prototypePageId } = state;

    history.push({
      pathname: `/prototype/edit-page/${prototypeId}/${prototypePageId}`,
      state: {
        prototypeId,
        checkedValue: state.checkedValue,
        prototypeName: state.prototypeName,
        prototypePageId,
        prototypePageName: state.prototypePageName,
      },
    });
  };

  const projectOptionsSelected: { value: string | null; label: string }[] = [
    ...projectLists.map(({ _id: id, name }: { _id: string; name: string }) => ({
      // eslint-disable-next-line no-underscore-dangle
      value: id,
      label: name,
    })),
  ];

  const typeOptionsSelected: { value: string | null; label: string }[] = [
    ...componentTypeLists.map(
      ({ _id: id, name }: { _id: string; name: string }) => ({
        // eslint-disable-next-line no-underscore-dangle
        value: id,
        label: name,
      }),
    ),
  ];

  useEffect(() => {
    if (deviceWidth > 0) {
      setParams({
        ...params,
        // eslint-disable-next-line @typescript-eslint/camelcase
        limit: deviceWidth > BREAK_POINT_4k ? PAGE_LIMIT_4K : PAGE_LIMIT,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deviceWidth]);

  return (
    <div className="ar-common-page__content">
      <div className="ar-common-search-panel-wrapper">
        {isSelectionMode ? (
          <>
            <Row xs="1" sm="1" md="1">
              <Col>
                <div className="ar-common-breadcrumb">
                  <Link
                    to={{
                      pathname: '/prototypes',
                    }}
                  >
                    <button
                      type="button"
                      className="ar-common-breadcrumb__link"
                    >
                      Prototype
                    </button>
                  </Link>
                  <FontAwesomeIcon icon={faChevronRight} />
                  <Link
                    to={{
                      pathname: `/prototype/create-page/${prototypeId}/${location.state.prototypeName}`,
                    }}
                  >
                    <button
                      type="button"
                      className="ar-common-breadcrumb__link"
                    >
                      Edit pages({location.state.prototypeName})
                    </button>
                  </Link>
                  <FontAwesomeIcon icon={faChevronRight} />
                  <Link
                    to={{
                      pathname: `/prototype/edit-page/${prototypeId}/${location.state.prototypePageId}`,
                      state: {
                        prototypeName: location.state.prototypeName,
                        pageName: location.state.prototypePageName,
                        checkedValue: location.state.checkedValue,
                      },
                    }}
                  >
                    <button
                      type="button"
                      className="ar-common-breadcrumb__link"
                    >
                      Edit page({capitalize(location.state.prototypePageName)})
                    </button>
                  </Link>
                  <FontAwesomeIcon icon={faChevronRight} />
                  <span className="ar-common-breadcrumb__page">
                    Select component
                  </span>
                </div>
              </Col>
            </Row>
            <Row>
              <Nav tabs className="ar-common-tab">
                <NavItem className="ar-common-tab__item">
                  <NavLink
                    className="ar-common-tab__link"
                    href="#"
                    role="button"
                    onClick={() => handlePageLoad()}
                  >
                    Select Page
                  </NavLink>
                </NavItem>
                <NavItem className="ar-common-tab__item">
                  <NavLink
                    className="ar-common-tab__link"
                    href="#"
                    role="button"
                    active
                  >
                    Select Component
                  </NavLink>
                </NavItem>
              </Nav>
            </Row>
          </>
        ) : (
          <>
            <div className="ar-common-component__button">
              {!isSelectionMode ? (
                <NavLink
                  href="/component/create"
                  className="ar-btn ar-btn--primary"
                >
                  Create Component
                </NavLink>
              ) : null}
            </div>
          </>
        )}

        <Row className="ar-common-component__wrapper">
          <Col>
            <div className="form ar-common-form ar-common-search-panel ar-common-search-panel-wrapper-component">
              <div className="ar-common-form__group">
                <FormGroup className="ar-common-form-search">
                  {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
                  <Input
                    type="text"
                    className="search ar-common-form__input"
                    id="search"
                    placeholder="Search by page name"
                    onChange={(e: React.ChangeEvent<HTMLInputElement>): void =>
                      setParams({ ...params, page: 1, search: e.target.value })
                    }
                  />
                </FormGroup>
              </div>
              <div className="ar-common-form__group ar-common-form__group-choices">
                <FormGroup>
                  <Label>Projects</Label>
                  <Select
                    isMulti
                    className="ar-common-form__select"
                    classNamePrefix="rctslct"
                    id="project"
                    options={projectOptionsSelected}
                    onChange={(selectedTag) => {
                      const selectedOptions: any = [];
                      // eslint-disable-next-line no-unused-expressions
                      selectedTag?.forEach((selectedTagitem) => {
                        selectedOptions.push(selectedTagitem.value);
                      });
                      setParams({
                        ...params,
                        page: 1,
                        projects: selectedOptions || null,
                      });
                    }}
                    isLoading={!requestProjectListsComplete}
                    isDisabled={!requestProjectListsComplete}
                  />
                </FormGroup>
              </div>
              <div className="ar-common-form__group ar-common-form__group-choices">
                <FormGroup>
                  <Label>Types</Label>
                  <Select
                    isMulti
                    className="ar-common-form__select"
                    classNamePrefix="rctslct"
                    id="componentType"
                    options={typeOptionsSelected}
                    onChange={(selectedTag) => {
                      const selectedOptions: any = [];
                      // eslint-disable-next-line no-unused-expressions
                      selectedTag?.forEach((selectedTagitem) => {
                        selectedOptions.push(selectedTagitem.value);
                      });
                      setParams({
                        ...params,
                        page: 1,
                        types: selectedOptions || null,
                      });
                    }}
                    isLoading={!requestComponentTypeListsComplete}
                    isDisabled={!requestComponentTypeListsComplete}
                  />
                </FormGroup>
              </div>

              <div className="ar-common-form__group ar-common-form-custom-switch">
                <FormGroup>
                  <Label>Realm</Label>
                  <CustomInput
                    type="switch"
                    id="isInRealm"
                    onChange={(e: React.ChangeEvent<HTMLInputElement>): void =>
                      setParams({
                        ...params,
                        page: 1,
                        isInRealm: e.target.checked,
                      })
                    }
                  />
                </FormGroup>
              </div>
            </div>
          </Col>
        </Row>
      </div>
      <Row>
        <Col xs="12">
          {requestDataComplete === false ? (
            <Col xs="12" sm="12" md="12" style={{ minHeight: 250 }}>
              <div className="loading-indicator" />
            </Col>
          ) : (
            <div className="ar-common-content__inner">
              <div className="ar-common-posts ar-common-posts--component">
                {isSelectionMode ? (
                  <FloatBtnGroup>
                    <FloatBtn
                      id="cancel-btn"
                      type="submit"
                      variant="back"
                      onClickHandler={handleBack}
                      title="Back"
                    />
                    <FloatBtn
                      type="button"
                      name="save"
                      variant="add"
                      onClickHandler={handleSave}
                      title="Add"
                    />
                  </FloatBtnGroup>
                ) : null}

                <div
                  className={`ar-common-posts__list ar-common-posts--component ${
                    data.componentData.length === 0 ? 'd-block' : ''
                  }`}
                >
                  {componentList}
                </div>
              </div>
            </div>
          )}
        </Col>
        <Col xs="12">
          {requestDataComplete && deviceWidth > 0 && (
            <Pagination
              count={totalCount}
              pageCount={pageCount}
              onPageChange={onPageChange}
              onPageSelect={onPageSelect}
              currentPage={params.page}
              pageLimit={params.limit}
            />
          )}
        </Col>
      </Row>
    </div>
  );
};
export default Component;
