/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable no-nested-ternary */
import React, { useEffect, useState } from 'react';
import { Route } from 'react-router-dom';
import Layout from '../components/common/Layout';
import Forbidden from '../modules/common/pages/Forbidden';
import authApi from '../api/authApi';

const AuthRoute = ({ ...args }) => {
  const [isLoading, setIsLoading] = useState(true);
  const [isForbidden, setIsForbidden] = useState(false);

  useEffect(() => {
    authApi
      .authCheck()
      .catch(() => {
        setIsForbidden(true);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, []);

  return isLoading ? (
    <div className="loading-indicator" />
  ) : isForbidden ? (
    <Forbidden />
  ) : (
    <Layout>
      <Route {...args} />
    </Layout>
  );
};

export default AuthRoute;
