/* eslint-disable camelcase */
import axiosApi from './axiosApi';

const getMoodboardOptionKeywords = async () => {
  return axiosApi.get('moodboard-keywords');
};

const moodboardOptionKeywordApi = {
  getMoodboardOptionKeywords,
};

export default moodboardOptionKeywordApi;
