/* eslint-disable no-underscore-dangle */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react-hooks/rules-of-hooks */
/* eslint-disable @typescript-eslint/no-explicit-any, no-console, @typescript-eslint/no-unused-vars, react/no-unescaped-entities */
/* eslint-disable consistent-return */
import React, { useState, useCallback, useEffect } from 'react';
import { Col, Row } from 'reactstrap';
import { Formik, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import { toast } from 'react-toastify';
import CreatableSelect from 'react-select/creatable';
import Select from 'react-select';
import NavigationPrompt from 'react-router-navigation-prompt';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronRight } from '@fortawesome/free-solid-svg-icons';
import componentApi from '../../../api/componentApi';
import projectApi from '../../../api/projectApi';
import pageApi from '../../../api/pageApi';
import FormikControl from '../../../components/common/FormikControl';
import DragAndDropField from '../../../components/FormFields/DragAndDropField';
import TextError from '../../../components/common/TextError';
import FloatBtnGroup from '../../../components/common/FloatBtnGroup';
import FloatBtn from '../../../components/common/FloatBtn';
import Alert from '../../../components/common/Alert';

const AddComponent: React.FC<any> = (props: {
  match: {
    params: { componentId: string };
  };
  location: any;
  history: any;
}) => {
  let projectLists: any = [];
  let pageLists: any = [];

  const {
    match: {
      params: { componentId },
    },
    location,
    history,
  } = props;

  const [isFileUploading, setIsFileUploading] = useState<{
    wireFrameSketch: boolean;
    mobileWireFrameSketch: boolean;
    wireframeImage: boolean;
    mobileWireFrameImage: boolean;
    designImage: boolean;
    mobileDesignImage: boolean;
  }>({
    wireFrameSketch: true,
    mobileWireFrameSketch: false,
    wireframeImage: true,
    mobileWireFrameImage: false,
    designImage: true,
    mobileDesignImage: false,
  });
  const [componentTypes, setComponentTypes] = useState<
    { _id: string | null; name: string }[]
  >([]);
  const [projectsComplete, setProjectsComplete] = useState<boolean>(false);
  const [projects, setProjects] = useState<{}[]>([{}]);
  const [pagesComplete, setPagesComplete] = useState<boolean>(false);
  const [pages, setPages] = useState<{}[]>([{}]);
  const [reinitialize, setReinitialize] = useState(true);
  const [isFormUpdated, setIsFormUpdated] = useState(false);

  const [initialValues, setInitialValues] = useState<{
    project: string | any;
    page: string | any;
    types: Array<string>;
    wireFrameSketch: string;
    wireFrameImage: string;
    order: number;
    isInRealm: boolean;
    designImage: string;
    skuID: string;
    sectionFRD: string;
    sectionUserGuide: string;
    sectionTestCaseDoc: string;
    sectionDevPlanDoc: string;
    mobileWireFrameSketch: string;
    mobileWireFrameImage: string;
    mobileDesignImage: string;
  }>({
    project: '',
    page: '',
    types: [],
    wireFrameSketch: '',
    wireFrameImage: '',
    order: 0,
    isInRealm: false,
    designImage: '',
    skuID: '',
    sectionFRD: '',
    sectionUserGuide: '',
    sectionTestCaseDoc: '',
    sectionDevPlanDoc: '',
    mobileWireFrameSketch: '',
    mobileWireFrameImage: '',
    mobileDesignImage: '',
  });

  const getComponentTypes = async () => {
    try {
      const results = await componentApi.requestComponentLists(null);
      const componentTypesArray = results.data.componentData;
      setComponentTypes(componentTypesArray);
    } catch (e) {
      if (e.response && e.response.status) {
        // eslint-disable-next-line no-console
        console.error('Something went wrong!');
      }
    }
  };

  const getProjects = async () => {
    try {
      setProjectsComplete(false);
      const results = await projectApi.requestProjectLists();
      setProjects(results.data.projectData);
      setProjectsComplete(true);
    } catch (e) {
      if (e.response && e.response.status) {
        setProjectsComplete(false);
      }
    }
  };

  const getPages = async () => {
    try {
      setPagesComplete(false);
      const results = await pageApi.requestPageLists(null);
      setPages(results.data.pageData);
      setPagesComplete(true);
    } catch (e) {
      if (e.response && e.response.status) {
        setPagesComplete(false);
      }
    }
  };

  const getComponent = async (id: string) => {
    try {
      const results = await componentApi.requestComponentById(id);
      const componentData = {
        ...results.data.component,
        types: results.data.component.componentTypes || [
          results.data.component.componentType,
        ],
      };
      [
        'wireFrameSketch',
        'wireFrameImage',
        'designImage',
        'mobileWireFrameSketch',
        'mobileWireFrameImage',
        'mobileDesignImage',
        'skuID',
      ].forEach((fieldName: string) => {
        if (
          typeof componentData[fieldName] === 'undefined' ||
          (typeof componentData[fieldName] !== 'undefined' &&
            componentData[fieldName] === null)
        ) {
          componentData[fieldName] = '';
        }
      });
      setInitialValues(componentData);
    } catch (e) {
      if (e.response && e.response.status) {
        // eslint-disable-next-line no-console
        console.error('Something went wrong!');
      }
    }
  };

  useEffect(() => {
    setReinitialize(false);
    getComponentTypes();
    getProjects();
    getPages();
    if (componentId) {
      getComponent(componentId);
    }
  }, [reinitialize, componentId]);

  if (projectsComplete && projects && projects.length > 0) {
    projectLists = projects.map((project: any) => {
      const { _id: id, name } = project;
      return { value: id, label: name };
    });
  }

  if (pagesComplete && pages && pages.length > 0) {
    pageLists = pages.map((page: any) => {
      const { _id: id, name } = page;
      return { value: id, label: name };
    });
  }

  const onSubmitFunc = async (values: any, submitProps: any) => {
    const {
      project,
      page,
      types,
      wireFrameSketch,
      wireFrameImage,
      order,
      isInRealm,
      designImage,
      skuID,
      sectionFRD,
      sectionUserGuide,
      sectionTestCaseDoc,
      sectionDevPlanDoc,
      mobileWireFrameSketch,
      mobileWireFrameImage,
      mobileDesignImage,
    } = values;
    const submitData: any = {
      project: typeof project === 'object' ? project.value : project,
      page: typeof page === 'object' ? page.value : page,
      componentTypes: types,
      wireFrameSketch,
      wireFrameImage,
      order,
      isInRealm,
      designImage,
      skuID: isInRealm ? skuID : '',
      sectionFRD,
      sectionUserGuide,
      sectionTestCaseDoc,
      sectionDevPlanDoc,
      mobileWireFrameSketch,
      mobileWireFrameImage,
      mobileDesignImage,
    };
    if (componentId) {
      submitData.componentId = componentId;
    }
    let saveResponse;
    try {
      saveResponse = await componentApi.save(submitData);
    } catch (error) {
      toast('Sorry. Error ocurred while processing request.', {
        type: 'error',
      });
      return;
    }
    if (saveResponse) {
      const savedComponent = saveResponse.data.data;
      submitProps.setSubmitting(false);
      submitProps.resetForm();
      const newProps = {
        project: '',
        page: '',
        types: [],
        wireFrameSketch: '',
        wireFrameImage: '',
        order: 1,
        isInRealm: false,
        designImage: '',
        skuID: '',
        sectionFRD: '',
        sectionUserGuide: '',
        sectionTestCaseDoc: '',
        sectionDevPlanDoc: '',
        mobileWireFrameSketch: '',
        mobileWireFrameImage: '',
        mobileDesignImage: '',
      };
      setInitialValues({
        ...newProps,
      });
      toast(
        `Component has been ${componentId ? 'updated' : 'added'} successfully`,
        {
          type: 'success',
        },
      );
      setIsFormUpdated(false);
      if (componentId) {
        history.push(`/components`, {
          ...(location.state || {}),
        });
      } else {
        // eslint-disable-next-line no-underscore-dangle
        history.push(`/component/create/${savedComponent._id}`, {
          ...(location.state || {}),
        });
      }
      setReinitialize(true);
    }
  };

  const validationSchema = Yup.object({
    project: Yup.string().required('Project Required'),
    page: Yup.string().required('Page Required'),
    types: Yup.array()
      .of(Yup.string())
      .min(1, 'At least a one component type required'),
    order: Yup.string().required('Order Required'),
    isInRealm: Yup.boolean(),
    skuID: Yup.string().when('isInRealm', {
      is: true,
      then: (schema: Yup.StringSchema) =>
        schema
          .required('SKU ID Required')
          .matches(
            /^r2?-[a-z\d-]+-l\d{1,3}f?-[a-z]{1,6}\d{1,3}$/,
            'Invalid SKU ID',
          ),
      otherwise: (schema: Yup.StringSchema) => schema.nullable(),
    }),
    sectionFRD: Yup.string().nullable().url().label('FRD'),
    sectionUserGuide: Yup.string().nullable().url().label('User guide'),
    sectionTestCaseDoc: Yup.string()
      .nullable()
      .url()
      .label('Test case document'),
    sectionDevPlanDoc: Yup.string().nullable().url().label('Dev plan document'),
    designImage: Yup.string().when(
      '$nonAccessibleKey',
      (_: string | null, schema: Yup.StringSchema) =>
        componentId
          ? schema.required('Design Image Required')
          : schema.nullable(),
    ),
    wireFrameSketch: Yup.string().when(
      '$nonAccessibleKey',
      (_: string | null, schema: Yup.StringSchema) => {
        return componentId
          ? schema.required('Wire Frame Sketch Required')
          : schema.nullable();
      },
    ),
    wireFrameImage: Yup.string().when(
      '$nonAccessibleKey',
      (_: string | null, schema: Yup.StringSchema) =>
        componentId
          ? schema.required('Wire Frame Image Required')
          : schema.nullable(),
    ),
    mobileWireFrameSketch: Yup.string()
      .nullable()
      .label('Mobile wireframe sketch'),
    mobileWireFrameImage: Yup.string()
      .nullable()
      .label('Mobile wireframe image'),
    mobileDesignImage: Yup.string().nullable().label('Mobile design image'),
    designSketch: Yup.string().nullable().label('Design sketch'),
  });

  const handleUploadFile = useCallback(
    async (file, name, values) => {
      const {
        wireFrameSketch,
        wireFrameImage,
        designImage,
        mobileWireFrameSketch,
        mobileWireFrameImage,
        mobileDesignImage,
      } = values;

      if (!file) return;
      setIsFormUpdated(true);

      setIsFileUploading({
        ...isFileUploading,
        [name]: true,
      });

      let fileType = '';
      const form = new FormData();
      form.append('image', file);

      switch (name) {
        case 'wireFrameSketch':
          fileType = 'sketch';
          break;
        case 'mobileWireFrameSketch':
          fileType = 'mobile-sketch';
          break;
        case 'wireframeImage':
          fileType = 'wireframe';
          break;
        case 'mobileWireFrameImage':
          fileType = 'mobile-wireframe';
          break;
        case 'designImage':
          fileType = 'design';
          break;
        case 'mobileDesignImage':
          fileType = 'mobile-design';
          break;
        default:
          fileType = 'wireframe';
          break;
      }
      const uploadName = await componentApi.upload(form, fileType, componentId);
      setIsFileUploading({
        ...isFileUploading,
        [name]: false,
      });
      const newProps = {
        ...values,
        componentId: uploadName.data.componentId
          ? uploadName.data.componentId
          : componentId,
        wireFrameSketch:
          name === 'wireFrameSketch'
            ? uploadName.data.fileKey
            : wireFrameSketch,
        wireFrameImage:
          name === 'wireFrameImage' ? uploadName.data.fileKey : wireFrameImage,
        designImage:
          name === 'designImage' ? uploadName.data.fileKey : designImage,
        mobileWireFrameSketch:
          name === 'mobileWireFrameSketch'
            ? uploadName.data.fileKey
            : mobileWireFrameSketch,
        mobileWireFrameImage:
          name === 'mobileWireFrameImage'
            ? uploadName.data.fileKey
            : mobileWireFrameImage,
        mobileDesignImage:
          name === 'mobileDesignImage'
            ? uploadName.data.fileKey
            : mobileDesignImage,
      };

      setInitialValues({
        ...initialValues,
        ...newProps,
      });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [initialValues, setInitialValues],
  );

  const onPageSelect = useCallback(
    (options, values) => {
      const {
        project,
        types,
        wireFrameSketch,
        wireFrameImage,
        order,
        designImage,
        skuID,
        sectionFRD,
        sectionUserGuide,
        sectionTestCaseDoc,
        sectionDevPlanDoc,
      } = values;

      if (options) {
        const pageExisting = {
          project,
          page: options,
          types,
          wireFrameSketch,
          wireFrameImage,
          order,
          designImage,
          skuID,
          sectionFRD,
          sectionUserGuide,
          sectionTestCaseDoc,
          sectionDevPlanDoc,
        };

        setInitialValues({ ...initialValues, ...pageExisting });
      } else {
        const newPages = {
          project,
          page: '',
          types,
          wireFrameSketch,
          wireFrameImage,
          order,
          designImage,
          skuID,
          sectionFRD,
          sectionUserGuide,
          sectionTestCaseDoc,
          sectionDevPlanDoc,
        };

        setInitialValues({ ...initialValues, ...newPages });
      }
    },
    [initialValues, setInitialValues],
  );

  const onProjectSelect = useCallback(
    (options, values) => {
      const {
        page,
        types,
        wireFrameSketch,
        wireFrameImage,
        order,
        designImage,
        skuID,
        sectionFRD,
        sectionUserGuide,
        sectionTestCaseDoc,
        sectionDevPlanDoc,
      } = values;

      if (options) {
        const projectExisting = {
          project: options,
          page,
          types,
          wireFrameSketch,
          wireFrameImage,
          order,
          designImage,
          skuID,
          sectionFRD,
          sectionUserGuide,
          sectionTestCaseDoc,
          sectionDevPlanDoc,
        };

        setInitialValues({ ...initialValues, ...projectExisting });
      } else {
        const newProjects = {
          project: '',
          page,
          types,
          wireFrameSketch,
          wireFrameImage,
          order,
          designImage,
          skuID,
          sectionFRD,
          sectionUserGuide,
          sectionTestCaseDoc,
          sectionDevPlanDoc,
        };

        setInitialValues({ ...initialValues, ...newProjects });
      }
    },
    [initialValues, setInitialValues],
  );

  const onTypeSelect = useCallback(
    (options, values) => {
      const {
        project,
        page,
        wireFrameSketch,
        wireFrameImage,
        order,
        designImage,
        skuID,
        sectionFRD,
        sectionUserGuide,
        sectionTestCaseDoc,
        sectionDevPlanDoc,
      } = values;

      if (options) {
        const pageExisting = {
          project,
          page,
          types: options,
          wireFrameSketch,
          wireFrameImage,
          order,
          designImage,
          skuID,
          sectionFRD,
          sectionUserGuide,
          sectionTestCaseDoc,
          sectionDevPlanDoc,
        };

        setInitialValues({ ...initialValues, ...pageExisting });
      } else {
        const newPages = {
          project,
          page,
          types: [],
          wireFrameSketch,
          wireFrameImage,
          order,
          designImage,
          skuID,
          sectionFRD,
          sectionUserGuide,
          sectionTestCaseDoc,
          sectionDevPlanDoc,
        };

        setInitialValues({ ...initialValues, ...newPages });
      }
    },
    [initialValues, setInitialValues],
  );

  const cancelEdit = () => {
    history.push(`/components`, {
      componentFilters: location.state?.componentFilters,
    });
  };

  return (
    <div className="ar-common-page__content">
      <Row xs="1" sm="1" md="1">
        <Col className="sticky-top">
          <div className="ar-common-breadcrumb">
            <button
              type="button"
              onClick={() => {
                history.goBack();
              }}
              className="ar-common-breadcrumb__link"
            >
              Component
            </button>
            <FontAwesomeIcon icon={faChevronRight} />
            <span className="ar-common-breadcrumb__page">
              {componentId ? 'Edit Component' : 'Create Component'}
            </span>
          </div>
        </Col>
        <Col md="12">
          <div className="ar-common-form ar-common-form--fixed-max-width">
            <Formik
              enableReinitialize
              initialValues={initialValues}
              onSubmit={(values, actions) => {
                onSubmitFunc(values, actions);
              }}
              validationSchema={validationSchema}
            >
              {(formik) => {
                const {
                  values,
                  handleChange,
                  handleBlur,
                  handleSubmit,
                  setFieldValue,
                } = formik;

                const onChange = (e: any) => {
                  setIsFormUpdated(true);

                  return handleChange(e);
                };

                return (
                  <form
                    onSubmit={(e) => {
                      e.preventDefault();
                      handleSubmit();
                    }}
                  >
                    <FloatBtnGroup>
                      <FloatBtn
                        type="button"
                        variant="cancel"
                        title="Back"
                        onClickHandler={cancelEdit}
                      />
                      <FloatBtn
                        type="submit"
                        variant="update"
                        title={componentId ? 'Update' : 'Next'}
                        disabled={
                          !isFormUpdated &&
                          Object.values(isFileUploading).includes(true)
                        }
                      />
                    </FloatBtnGroup>
                    <Row>
                      <Col xs="12" md="6">
                        <div className="ar-common-form__group form-group">
                          <label htmlFor="project">Project*</label>
                          <CreatableSelect
                            className="ar-common-form__select"
                            isClearable
                            classNamePrefix="rctslct"
                            name="project"
                            inputId="project"
                            options={projectLists}
                            placeholder="Project"
                            onChange={(option) => {
                              onProjectSelect(option, values);
                              setIsFormUpdated(true);
                            }}
                            onBlur={(e) => {
                              handleBlur(e);
                            }}
                            backspaceRemovesValue={false}
                            value={values.project}
                            noOptionsMessage={() => 'No options available'}
                          />
                          <ErrorMessage name="project" component={TextError} />
                        </div>
                      </Col>
                      <Col xs="12" md="6">
                        <div className="ar-common-form__group form-group">
                          <label htmlFor="page">Page*</label>
                          <CreatableSelect
                            className="ar-common-form__select"
                            isClearable
                            classNamePrefix="rctslct"
                            name="page"
                            inputId="page"
                            options={pageLists}
                            placeholder="Page"
                            onChange={(option) => {
                              onPageSelect(option, values);
                              setIsFormUpdated(true);
                            }}
                            onBlur={(e) => {
                              handleBlur(e);
                            }}
                            backspaceRemovesValue={false}
                            value={values.page}
                            noOptionsMessage={() => 'No options available'}
                          />
                          <ErrorMessage name="page" component={TextError} />
                        </div>
                      </Col>
                      <Col xs="12" md="6">
                        <div className="ar-common-form__group form-group">
                          <label htmlFor="types" className="required">
                            Component types*
                          </label>
                          <Select
                            className="ar-common-form__select"
                            isClearable
                            classNamePrefix="rctslct"
                            name="types"
                            inputId="types"
                            isMulti
                            options={componentTypes.map((componentType) => ({
                              value: componentType._id || null,
                              label: componentType.name,
                            }))}
                            placeholder="Component types"
                            onChange={(options) => {
                              onTypeSelect(
                                options?.map((x) => x.value),
                                values,
                              );
                              setIsFormUpdated(true);
                            }}
                            onBlur={(e) => {
                              handleBlur(e);
                            }}
                            backspaceRemovesValue={false}
                            value={(() => {
                              const selectedOptions: Array<{
                                value: string | null;
                                label: string;
                              }> = [];
                              values.types.forEach((type) => {
                                const componentType = componentTypes.find(
                                  (x) => x._id === type,
                                );
                                if (componentType) {
                                  selectedOptions.push({
                                    value: componentType._id,
                                    label: componentType.name,
                                  });
                                }
                              });
                              return selectedOptions;
                            })()}
                            noOptionsMessage={() => 'No options available'}
                          />
                          <ErrorMessage name="types" component={TextError} />
                        </div>
                      </Col>
                      <Col xs="12" md="6">
                        <div className="ar-common-form__group">
                          <FormikControl
                            control="Input"
                            type="number"
                            setFieldValue={setFieldValue}
                            name="order"
                            label="Component order*"
                            onChange={(e: any) => onChange(e)}
                            onBlur={handleBlur}
                            min="1"
                          />
                        </div>
                      </Col>
                      <Col xs="12" md="6">
                        <div className="ar-common-form__group">
                          <FormikControl
                            control="Input"
                            type="url"
                            setFieldValue={setFieldValue}
                            name="sectionFRD"
                            label="Section FRD"
                            url={values.sectionFRD}
                            onChange={(e: any) => onChange(e)}
                            onBlur={handleBlur}
                          />
                        </div>
                      </Col>
                      {/* Commented below user guide as per chathura's request ARGO-362 */}
                      {/* <Col xs="12" md='6'>
                    <div className="ar-common-form__group">
                      <FormikControl
                        control="Input"
                        type="text"
                        setFieldValue={formik.setFieldValue}
                        name="sectionUserGuide"
                        label="Section User Guide"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                      />
                    </div>
                  </Col> */}
                      <Col xs="12" md="6">
                        <div className="ar-common-form__group">
                          <FormikControl
                            control="Input"
                            type="text"
                            setFieldValue={setFieldValue}
                            name="sectionTestCaseDoc"
                            label="Section Test Case Doc"
                            onChange={(e: any) => onChange(e)}
                            onBlur={handleBlur}
                          />
                        </div>
                      </Col>
                      {/* Commented below user guide as per chathura's request ARGO-362 */}
                      {/* <Col xs="12" md='6'>
                    <div className="ar-common-form__group">
                      <FormikControl
                        control="Input"
                        type="text"
                        setFieldValue={formik.setFieldValue}
                        name="sectionDevPlanDoc"
                        label="Section Dev Plan Doc"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                      />
                    </div>
                  </Col> */}
                    </Row>
                    <Row>
                      <Col xs="12">
                        <div className="ar-common-form__group">
                          <FormikControl
                            control="ToggleButton"
                            type="checkbox"
                            setFieldValue={setFieldValue}
                            name="isInRealm"
                            label="Is available in Realm"
                            onChange={(e: any) => onChange(e)}
                            onBlur={handleBlur}
                          />
                        </div>
                      </Col>
                    </Row>
                    {values.isInRealm && (
                      <Row>
                        <Col xs="12" md="6">
                          <div className="ar-common-form__group">
                            <FormikControl
                              control="Input"
                              type="text"
                              setFieldValue={setFieldValue}
                              name="skuID"
                              label="SKU ID*"
                              onChange={(e: any) => onChange(e)}
                              onBlur={handleBlur}
                            />
                          </div>
                        </Col>
                      </Row>
                    )}
                    {componentId && (
                      <>
                        <Row>
                          <Col xs="12">
                            <div className="ar-common-form__group">
                              <h4>Component Assets</h4>
                              <p className="text-sm text-muted font-italic">
                                Please make sure to click &quot;Save&quot;
                                button after uploading files
                              </p>
                            </div>
                          </Col>
                        </Row>
                        <Row className="ar-common-assets">
                          <Col xs="12" md="6">
                            <DragAndDropField
                              uploading={isFileUploading.wireFrameSketch}
                              currentFileName={
                                values.wireFrameSketch
                                  ? values.wireFrameSketch
                                  : ''
                              }
                              name="wireFrameSketch"
                              label="Wire-frame Sketch*"
                              validFileTypes={['sketch']}
                              fileType="wireFrameSketch"
                              handleUploadFile={(event: any) =>
                                handleUploadFile(
                                  event,
                                  'wireFrameSketch',
                                  values,
                                )
                              }
                            />
                            <ErrorMessage name="wireFrameSketch">
                              {(msg) => (
                                <div
                                  className="error"
                                  style={{ marginTop: -25 }}
                                >
                                  {msg}
                                </div>
                              )}
                            </ErrorMessage>
                          </Col>
                          <Col xs="12" md="6">
                            <DragAndDropField
                              uploading={isFileUploading.mobileWireFrameSketch}
                              currentFileName={
                                values.mobileWireFrameSketch
                                  ? values.mobileWireFrameSketch
                                  : ''
                              }
                              name="mobileWireFrameSketch"
                              label="Mobile Wire-frame Sketch"
                              validFileTypes={['sketch']}
                              fileType="mobileWireFrameSketch"
                              handleUploadFile={(event: any) =>
                                handleUploadFile(
                                  event,
                                  'mobileWireFrameSketch',
                                  values,
                                )
                              }
                            />
                            <ErrorMessage name="mobileWireFrameSketch">
                              {(msg) => (
                                <div
                                  className="error"
                                  style={{ marginTop: -25 }}
                                >
                                  {msg}
                                </div>
                              )}
                            </ErrorMessage>
                          </Col>
                          <Col xs="12" md="6">
                            <DragAndDropField
                              uploading={isFileUploading.wireframeImage}
                              currentFileName={
                                values.wireFrameImage
                                  ? values.wireFrameImage
                                  : ''
                              }
                              name="wireFrameImage"
                              label="Wire-frame Image*"
                              validFileTypes={['png', 'jpeg', 'jpg']}
                              fileType="wireFrameImage"
                              handleUploadFile={(event: any) =>
                                handleUploadFile(
                                  event,
                                  'wireFrameImage',
                                  values,
                                )
                              }
                            />
                            <ErrorMessage name="wireFrameImage">
                              {(msg) => (
                                <div
                                  className="error"
                                  style={{ marginTop: -25 }}
                                >
                                  {msg}
                                </div>
                              )}
                            </ErrorMessage>
                          </Col>
                          <Col xs="12" md="6">
                            <DragAndDropField
                              uploading={isFileUploading.mobileWireFrameImage}
                              currentFileName={
                                values.mobileWireFrameImage
                                  ? values.mobileWireFrameImage
                                  : ''
                              }
                              name="mobileWireFrameImage"
                              label="Mobile Wire-frame Image"
                              validFileTypes={['png', 'jpeg', 'jpg']}
                              fileType="mobileWireFrameImage"
                              handleUploadFile={(event: any) =>
                                handleUploadFile(
                                  event,
                                  'mobileWireFrameImage',
                                  values,
                                )
                              }
                            />
                            <ErrorMessage name="mobileWireFrameImage">
                              {(msg) => (
                                <div
                                  className="error"
                                  style={{ marginTop: -25 }}
                                >
                                  {msg}
                                </div>
                              )}
                            </ErrorMessage>
                          </Col>
                          <Col xs="12" md="6">
                            <DragAndDropField
                              uploading={isFileUploading.designImage}
                              currentFileName={
                                values.designImage ? values.designImage : ''
                              }
                              name="designImage"
                              label="Design Image*"
                              validFileTypes={['png', 'jpeg', 'jpg']}
                              fileType="designImage"
                              handleUploadFile={(event: any) =>
                                handleUploadFile(event, 'designImage', values)
                              }
                            />
                            <ErrorMessage name="designImage">
                              {(msg) => (
                                <div
                                  className="error"
                                  style={{ marginTop: -25 }}
                                >
                                  {msg}
                                </div>
                              )}
                            </ErrorMessage>
                          </Col>
                          <Col xs="12" md="6">
                            <DragAndDropField
                              uploading={isFileUploading.mobileDesignImage}
                              currentFileName={
                                values.mobileDesignImage
                                  ? values.mobileDesignImage
                                  : ''
                              }
                              name="mobileDesignImage"
                              label="Mobile Design Image"
                              validFileTypes={['png', 'jpeg', 'jpg']}
                              fileType="mobileDesignImage"
                              handleUploadFile={(event: any) =>
                                handleUploadFile(
                                  event,
                                  'mobileDesignImage',
                                  values,
                                )
                              }
                            />
                            <ErrorMessage name="mobileDesignImage">
                              {(msg) => (
                                <div
                                  className="error"
                                  style={{ marginTop: -25 }}
                                >
                                  {msg}
                                </div>
                              )}
                            </ErrorMessage>
                          </Col>
                        </Row>
                      </>
                    )}
                  </form>
                );
              }}
            </Formik>
          </div>
        </Col>
      </Row>
      {isFormUpdated && (
        <NavigationPrompt
          when={(crntLocation, nextLocation, _action) =>
            !nextLocation ||
            !nextLocation.pathname.startsWith(crntLocation.pathname)
          }
        >
          {({ onCancel, onConfirm }) => {
            if (isFormUpdated) {
              return (
                <Alert
                  onConfirm={() => {
                    setIsFormUpdated(false);
                    onConfirm();
                  }}
                  onCancel={onCancel}
                  open
                />
              );
            }

            setIsFormUpdated(false);
            onConfirm();
          }}
        </NavigationPrompt>
      )}
    </div>
  );
};
export default AddComponent;
