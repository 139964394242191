/* eslint-disable import/prefer-default-export */
import config from '../config';
import { get as getLsValue } from './local-storage';

export function getSanitizedS3BucketUrl() {
  return config.s3.ENDPOINT &&
    config.s3.ENDPOINT[config.s3.ENDPOINT.length - 1] === '/'
    ? config.s3.ENDPOINT?.substring(0, config.s3.ENDPOINT.length - 1)
    : config.s3.ENDPOINT;
}

export function setPreferredTheme(
  themeSetting: string | undefined = undefined,
) {
  const setThemeVariables = (theme: string) => {
    const rootElement = document.querySelector(':root') as HTMLElement | null;
    const themeVariables =
      theme === 'dark' ? config.themes.dark : config.themes.light;
    if (rootElement && themeVariables) {
      type ThemeVariable = keyof typeof themeVariables;
      Object.keys(themeVariables).forEach((themeVariable: string) => {
        rootElement.style.setProperty(
          themeVariable,
          themeVariables[themeVariable as ThemeVariable],
        );
      });
    }
  };
  const handleSystemThemeChange = ({ matches }: { matches: boolean }) => {
    setThemeVariables(matches === true ? 'dark' : 'light');
  };
  const preferredTheme = themeSetting || getLsValue('preferred-theme', 'auto');
  let colorSchemeMatchMediaDark;
  if (window.matchMedia) {
    colorSchemeMatchMediaDark = window.matchMedia(
      '(prefers-color-scheme: dark)',
    );
  }
  if (colorSchemeMatchMediaDark) {
    if (preferredTheme === 'auto') {
      colorSchemeMatchMediaDark.addEventListener(
        'change',
        handleSystemThemeChange,
        false,
      );
    } else {
      colorSchemeMatchMediaDark.removeEventListener(
        'change',
        handleSystemThemeChange,
        false,
      );
    }
  }

  setThemeVariables(
    preferredTheme === 'dark' ||
      (preferredTheme === 'auto' && colorSchemeMatchMediaDark?.matches === true)
      ? 'dark'
      : 'light',
  );
}
