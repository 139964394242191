import React, { useEffect } from 'react';
import { BrowserRouter } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import { GoogleOAuthProvider } from '@react-oauth/google';
import Router from './router';
import { CommonContextProvider } from './store/common/context';
import 'react-toastify/dist/ReactToastify.css';
import './assets/scss/style.scss';
import { setPreferredTheme } from './helpers';

function App() {
  useEffect(() => {
    setPreferredTheme();
  });

  return (
    <CommonContextProvider>
      <GoogleOAuthProvider
        clientId={`${process.env.REACT_APP_GOOGLE_CLIENT_ID}`}
      >
        <BrowserRouter>
          <div className="App">
            <ToastContainer position="top-right" theme="colored" limit={1} />
            <Router />
          </div>
        </BrowserRouter>
      </GoogleOAuthProvider>
    </CommonContextProvider>
  );
}

export default App;
