/* eslint-disable camelcase */
import axiosApi from './axiosApi';

const requestPageLists = async (project: string | number | null) => {
  if (project) {
    return axiosApi.get(`pages?project=${project}`);
  }
  return axiosApi.get('pages');
};

const getPages = async (params: {}) => {
  return axiosApi.post('pages', { ...params });
};

const pageApi = {
  requestPageLists,
  getPages,
};

export default pageApi;
